export * from './card-1/card-1.component';
import {Card1Component} from './card-1/card-1.component';

export * from './bit-actions/bit-actions.component';
import {BitActionsComponent} from './bit-actions/bit-actions.component';

export * from './bit-feedback/bit-feedback.component';
import {BitFeedbackComponent} from './bit-feedback/bit-feedback.component';

export * from './bit-book-summary/bit-book-summary.component';
import {BitBookSummaryComponent} from './bit-book-summary/bit-book-summary.component';

export * from './bit-book-ending/bit-book-ending.component';
import {BitBookEndingComponent} from './bit-book-ending/bit-book-ending.component';

export * from './bot-action-send/bot-action-send.component';
import {BotActionSendComponent} from './bot-action-send/bot-action-send.component';

export * from './bot-action-response/bot-action-response.component';
import {BotActionResponseComponent} from './bot-action-response/bot-action-response.component';

export * from './bit-book-summary/reader-cover/reader-cover.component';
import {ReaderCoverComponent} from './bit-book-summary/reader-cover/reader-cover.component';

export * from '../shared/notebook-cover/notebook-learning-path-cover/notebook-learning-path-cover.component';
import {
  NotebookLearningPathCoverComponent
} from '../shared/notebook-cover/notebook-learning-path-cover/notebook-learning-path-cover.component';

export * from './bit.component';
import {BitComponent} from './bit.component';

export * from './bit-item-instruction/bit-item-instruction.component';
import {BitItemInstructionComponent} from './bit-item-instruction/bit-item-instruction.component';

export * from './bitmark-text/bitmark-text.component';
import {BitmarkTextComponent} from './bitmark-text/bitmark-text.component';

export * from './bitmark-editable-text/bitmark-editable-text.component';
import {BitmarkEditableTextComponent} from './bitmark-editable-text/bitmark-editable-text.component';

export * from './resource/resource.component';
import {ResourceComponent} from './resource/resource.component';

export * from './resource/resource-article/resource-article.component';
import {ResourceArticleComponent} from './resource/resource-article/resource-article.component';

export * from './resource/resource-image/resource-image.component';
import {ResourceImageComponent} from './resource/resource-image/resource-image.component';

export * from './resource/resource-audio/resource-audio.component';
import {ResourceAudioComponent} from './resource/resource-audio/resource-audio.component';

export * from './resource/resource-video/resource-video.component';
import {ResourceVideoComponent} from './resource/resource-video/resource-video.component';

export * from './resource/resource-website-link/resource-website-link.component';
import {ResourceWebsiteLinkComponent} from './resource/resource-website-link/resource-website-link.component';

export * from './resource/resource-image-link/resource-image-link.component';
import {ResourceImageLinkComponent} from './resource/resource-image-link/resource-image-link.component';

export * from './resource/resource-video-link/resource-video-link.component';
import {ResourceVideoLinkComponent} from './resource/resource-video-link/resource-video-link.component';

export * from './resource/resource-document-link/resource-document-link.component';
import {ResourceDocumentLinkComponent} from './resource/resource-document-link/resource-document-link.component';

export * from './resource/edit/resource-video-edit/resource-video-link-edit.component';
import {ResourceVideoLinkEditComponent} from './resource/edit/resource-video-edit/resource-video-link-edit.component';

export * from './resource/edit/resource-image-link-edit/resource-image-link-edit.component';
import {
  ResourceImageLinkEditComponent
} from './resource/edit/resource-image-link-edit/resource-image-link-edit.component';

export * from './resource/edit/resource-website-link-edit/resource-website-link-edit.component';
import {
  ResourceWebsiteLinkEditComponent
} from './resource/edit/resource-website-link-edit/resource-website-link-edit.component';

export * from './resource/edit/resource-document-link-edit/resource-document-link-edit.component';
import {
  ResourceDocumentLinkEditComponent
} from './resource/edit/resource-document-link-edit/resource-document-link-edit.component';

export * from './resource/edit/resource-audio-recording-edit/resource-audio-recording-edit.component';
import {
  ResourceAudioRecordingEditComponent
} from './resource/edit/resource-audio-recording-edit/resource-audio-recording-edit.component';

export * from './resource/edit/resource-video-recording-edit/resource-video-recording-edit.component';
import {
  ResourceVideoRecordingEditComponent
} from './resource/edit/resource-video-recording-edit/resource-video-recording-edit.component';

export * from './resource/edit/resource-camera-photo-edit/resource-camera-photo-edit.component';
import {
  ResourceCameraPhotoEditComponent
} from './resource/edit/resource-camera-photo-edit/resource-camera-photo-edit.component';

export * from '../shared/recording/video-recording-base/resource-base-camera-edit.component';
import {
  ResourceBaseCameraEditComponent
} from '../shared/recording/video-recording-base/resource-base-camera-edit.component';

export * from './cloze/cloze.component';
import {ClozeComponent} from './cloze/cloze.component';

export * from './code/code.component';
import {CodeComponent} from './code/code.component';

export * from './conversation/conversation-left-1/conversation-left-1.component';
import {ConversationLeft1Component} from './conversation/conversation-left-1/conversation-left-1.component';

export * from './conversation/conversation-left-1-scream/conversation-left-1-scream.component';
import {
  ConversationLeft1ScreamComponent
} from './conversation/conversation-left-1-scream/conversation-left-1-scream.component';

export * from './conversation/conversation-left-1-thought/conversation-left-1-thought.component';
import {
  ConversationLeft1ThoughtComponent
} from './conversation/conversation-left-1-thought/conversation-left-1-thought.component';

export * from './conversation/conversation-right-1/conversation-right-1.component';
import {ConversationRight1Component} from './conversation/conversation-right-1/conversation-right-1.component';

export * from './conversation/conversation-right-1-scream/conversation-right-1-scream.component';
import {
  ConversationRight1ScreamComponent
} from './conversation/conversation-right-1-scream/conversation-right-1-scream.component';

export * from './conversation/conversation-right-1-thought/conversation-right-1-thought.component';
import {
  ConversationRight1ThoughtComponent
} from './conversation/conversation-right-1-thought/conversation-right-1-thought.component';

export * from './cloze/gap-placeholder/gap-placeholder.component';
import {GapPlaceholderComponent} from './cloze/gap-placeholder/gap-placeholder.component';

export * from './cloze/select-placeholder/select-placeholder.component';
import {SelectPlaceholderComponent} from './cloze/select-placeholder/select-placeholder.component';

export * from './multiple-choice/multiple-choice.component';
import {MultipleChoiceComponent} from './multiple-choice/multiple-choice.component';

export * from './multiple-choice-1/multiple-choice-1.component';
import {MultipleChoice1Component} from './multiple-choice-1/multiple-choice-1.component';

export * from './multiple-response/multiple-response.component';
import {MultipleResponseComponent} from './multiple-response/multiple-response.component';

export * from './multiple-response-1/multiple-response-1.component';
import {MultipleResponse1Component} from './multiple-response-1/multiple-response-1.component';

export * from './page/page.component';
import {PageComponent} from './page/page.component';

export * from './page-person/page-person.component';
import {PagePersonComponent} from './page-person/page-person.component';

export * from './page-subscribe/page-subscribe.component';
import {PageSubscribeComponent} from './page-subscribe/page-subscribe.component';

export * from './app/app-ai-prompt/app-ai-prompt.component';
import {AppAiPromptComponent} from './app/app-ai-prompt/app-ai-prompt.component';

export * from './app/app-code-editor/app-code-editor.component';
import {AppCodeEditorComponent} from './app/app-code-editor/app-code-editor.component';

export * from './app/app-flashcards/app-flashcards.component';
import {AppFlashcardsComponent} from './app/app-flashcards/app-flashcards.component';

export * from './app/app-flashcards/app-flashcards-card/app-flashcards-card.component';
import {AppFlashcardsCardComponent} from './app/app-flashcards/app-flashcards-card/app-flashcards-card.component';

export * from './app/app-flashcards/leitner-2/app-flashcards-leitner.component';
import {AppFlashcardsLeitnerComponent} from './app/app-flashcards/leitner-2/app-flashcards-leitner.component';

export * from './app/app-flashcards/normal-2/app-flashcards-normal.component';
import {AppFlashcardsNormalComponent} from './app/app-flashcards/normal-2/app-flashcards-normal.component';

export * from './article/article.component';
import {ArticleComponent} from './article/article.component';

export * from './article-alt/article-alt.component';
import {ArticleAltComponent} from './article-alt/article-alt.component';

export * from './module/module.component';
import {ModuleComponent} from './module/module.component';

export * from './module/module-fields/module-fields.component';
import {ModuleFieldsComponent} from './module/module-fields/module-fields.component';

export * from './virtual-mark-book-as-read/virtual-mark-book-as-read.component'
import {VirtualMarkBookAsReadComponent} from "./virtual-mark-book-as-read/virtual-mark-book-as-read.component";

export * from './virtual-hand-in/virtual-hand-in.component'
import {VirtualHandInComponent} from "./virtual-hand-in/virtual-hand-in.component";

export * from './virtual-hand-in-review/virtual-hand-in-review.component'
import {VirtualHandInReviewComponent} from "./virtual-hand-in-review/virtual-hand-in-review.component";

export * from './virtual-hand-in-result/virtual-hand-in-result.component'
import {VirtualHandInResultComponent} from "./virtual-hand-in-result/virtual-hand-in-result.component";

export * from './button-copy-text/button-copy-text.component';
import {ButtonCopyTextComponent} from './button-copy-text/button-copy-text.component';

export * from './page-buy-button/page-buy-button.component';
import {PageBuyButtonComponent} from './page-buy-button/page-buy-button.component';

export * from './page-buy-button-promotion/page-buy-button-promotion.component';
import {PageBuyButtonPromotionComponent} from './page-buy-button-promotion/page-buy-button-promotion.component';

export * from './page-footer/page-footer.component';
import {PageFooterComponent} from './page-footer/page-footer.component';

export * from './page-open-book/page-open-book.component';
import {PageOpenBookComponent} from './page-open-book/page-open-book.component';

export * from './page-product/page-product.component';
import {PageProductComponent} from './page-product/page-product.component';

export * from './page-product-list/page-product-list.component';
import {PageProductListComponent} from './page-product-list/page-product-list.component';

export * from './page-product-video/page-product-video.component';
import {PageProductVideoComponent} from './page-product-video/page-product-video.component';

export * from './page-product-video-list/page-product-video-list.component';
import {PageProductVideoListComponent} from './page-product-video-list/page-product-video-list.component';

export * from './page-section-folder/page-section-folder.component';
import {PageSectionFolderComponent} from './page-section-folder/page-section-folder.component';

export * from './chapter/chapter.component';
import {ChapterComponent} from './chapter/chapter.component';

export * from './internal-link/internal-link.component';
import {InternalLinkComponent} from './internal-link/internal-link.component';

export * from './cook-ingredients/cook-ingredients.component';
import {CookIngredientsComponent} from './cook-ingredients/cook-ingredients.component';

export * from './essay/essay.component';
import {EssayComponent} from './essay/essay.component';

export * from './extractor-block/extractor-block.component';
import {ExtractorBlockComponent} from './extractor-block/extractor-block.component';

export * from './flashcard-1/flashcard-1.component';
import {Flashcard1Component} from './flashcard-1/flashcard-1.component';

export * from './image/image.component';
import {ImageComponent} from './image/image.component';

export * from './images-logo-grave/images-logo-grave.component';
import {ImagesLogoGraveComponent} from './images-logo-grave/images-logo-grave.component';

export * from './image-responsive/image-responsive.component';
import {ImageResponsiveComponent} from './image-responsive/image-responsive.component';

export * from './interview/interview.component';
import {InterviewComponent} from './interview/interview.component';

export * from './assignment/assignment.component';
import {AssignmentComponent} from './assignment/assignment.component';

export * from './match/match-gap-placeholder/match-gap-placeholder.component';
import {MatchGapPlaceholderComponent} from './match/match-gap-placeholder/match-gap-placeholder.component';

export * from './match/match.component';
import {MatchComponent} from './match/match.component';

export * from './true-false/true-false.component';
import {TrueFalseComponent} from './true-false/true-false.component';

export * from './true-false-1/true-false-1.component';
import {TrueFalse1Component} from './true-false-1/true-false-1.component';

export * from './vendor/iframely/iframely-embed/iframely-embed.component';
import {IframelyEmbedComponent} from './vendor/iframely/iframely-embed/iframely-embed.component';

export * from './vendor/highcharts/highcharts-chart.component';
import {HighchartsChartComponent} from './vendor/highcharts/highcharts-chart.component';

export * from './self-assessment/self-assessment.component';
import {SelfAssessmentComponent} from './self-assessment/self-assessment.component';

export * from './statement/statement.component';
import {StatementComponent} from './statement/statement.component';

export * from './details-1/details-1.component';
import {Details1Component} from './details-1/details-1.component';

export * from './about/about.component';
import {AboutComponent} from './about/about.component';

export * from './details-image/details-image.component';
import {DetailsImageComponent} from './details-image/details-image.component';

export * from './match-matrix/match-matrix.component';
import {MatchMatrixComponent} from './match-matrix/match-matrix.component';

export * from './match-solution-grouped/match-solution-grouped.component';
import {MatchSolutionGroupedComponent} from './match-solution-grouped/match-solution-grouped.component';

export * from './match-solution-grouped/match-select-placeholder/match-select-placeholder.component';
import {
  MatchSelectPlaceholderComponent
} from './match-solution-grouped/match-select-placeholder/match-select-placeholder.component';

export * from './learning-events/learning-event.common';
import {LearningEventCommon} from './learning-events/learning-event.common';

export * from './learning-events/learning-event-classroom-event/learning-event-classroom-event.component';
import {LearningEventClassroomEventComponent} from './learning-events/learning-event-classroom-event/learning-event-classroom-event.component';

export * from './learning-events/learning-event-completion/learning-event-completion.component';
import {LearningEventCompletionComponent} from './learning-events/learning-event-completion/learning-event-completion.component';

export * from './learning-events/learning-event-external-link/learning-event-external-link.component';
import {LearningEventExternalLinkComponent} from './learning-events/learning-event-external-link/learning-event-external-link.component';

export * from './learning-events/learning-event-learning-objectives/learning-event-learning-objectives.component';
import {LearningEventLearningObjectivesComponent} from './learning-events/learning-event-learning-objectives/learning-event-learning-objectives.component';

export * from './learning-events/learning-event-learning-step/learning-event-learning-step.component';
import {LearningEventLearningStepComponent} from './learning-events/learning-event-learning-step/learning-event-learning-step.component';

export * from './learning-events/learning-event-preparation-task/learning-event-preparation-task.component';
import {LearningEventPreparationTaskComponent} from './learning-events/learning-event-preparation-task/learning-event-preparation-task.component';

export * from './learning-events/learning-event-read-book/learning-event-read-book.component';
import {LearningEventReadBookComponent} from './learning-events/learning-event-read-book/learning-event-read-book.component';

export * from './learning-events/learning-event-video-call/learning-event-video-call.component';
import {LearningEventVideoCallComponent} from './learning-events/learning-event-video-call/learning-event-video-call.component';

export * from './learning-path/learning-path-closing/learning-path-closing.component';
import {LearningPathClosingComponent} from './learning-path/learning-path-closing/learning-path-closing.component';

export * from './learning-path/learning-path-video-call/learning-path-video-call.component';
import {
  LearningPathVideoCallComponent
} from './learning-path/learning-path-video-call/learning-path-video-call.component';

export * from './learning-path/learning-path-book/learning-path-book.component';
import {LearningPathBookComponent} from './learning-path/learning-path-book/learning-path-book.component';

export * from './learning-path/learning-path-classroom-event/learning-path-classroom-event.component';
import {
  LearningPathClassroomEventComponent
} from './learning-path/learning-path-classroom-event/learning-path-classroom-event.component';

export * from './learning-path/learning-path-external-link/learning-path-external-link.component';
import {
  LearningPathExternalLinkComponent
} from './learning-path/learning-path-external-link/learning-path-external-link.component';

export * from './learning-path/learning-path-step/learning-path-step.component';
import {LearningPathStepComponent} from './learning-path/learning-path-step/learning-path-step.component';

export * from './learning-path/learning-path-lti/learning-path-lti.component';
import {LearningPathLtiComponent} from './learning-path/learning-path-lti/learning-path-lti.component';

export * from './learning-path/learning-path-learning-goal/learning-path-learning-goal.component';
import {
  LearningPathLearningGoalComponent
} from './learning-path/learning-path-learning-goal/learning-path-learning-goal.component';

export * from './padlet-embed/padlet-embed.component';
import {PadletEmbedComponent} from './padlet-embed/padlet-embed.component';

export * from './mark/mark.component';
import {MarkComponent} from './mark/mark.component';

export * from './message/message.component';
import {MessageComponent} from './message/message.component';

export * from './toc/toc.component';
import {TocComponent} from './toc/toc.component';

export * from './correction/correction.component';
import {CorrectionComponent} from './correction/correction.component';

export * from './table/table.component';
import {TableComponent} from './table/table.component';

export * from './table-image/table-image.component';
import {TableImageComponent} from './table-image/table-image.component';

export * from './take-picture/take-picture.component';
import {TakePictureComponent} from './take-picture/take-picture.component';

export * from './record-audio/record-audio.component';
import {RecordAudioComponent} from './record-audio/record-audio.component';

export * from './record-audio/record-edit/record-audio-edit.component';
import {RecordAudioEditComponent} from './record-audio/record-edit/record-audio-edit.component';

export * from './record-video/record-edit/record-video-edit.component';
import {RecordVideoEditComponent} from './record-video/record-edit/record-video-edit.component';

export * from './record-video/record-video.component';
import {RecordVideoComponent} from './record-video/record-video.component';

export * from './release-note/release-note.component';
import {ReleaseNoteComponent} from './release-note/release-note.component';

export * from './survey/survey.component';
import {SurveyComponent} from './survey/survey.component';

export * from './survey-matrix/survey-matrix.component';
import {SurveyMatrixComponent} from './survey-matrix/survey-matrix.component';

export * from './book-sources/book-sources.component';
import {BookSourcesComponent} from './book-sources/book-sources.component';

export * from './book-link/book-link.component';
import {BookLinkComponent} from './book-link/book-link.component';

export * from './book-close/book-close.component';
import {BookCloseComponent} from './book-close/book-close.component';

export * from './resource/safe-html.pipe';
import {SafeHtmlPipe} from './resource/safe-html.pipe';

export * from './resource/safe-style.pipe';
import {SafeStylePipe} from './resource/safe-style.pipe';

export * from './summary/summary.component';
import {SummaryComponent} from './summary/summary.component';

export * from './scorm/scorm.component';
import {ScormComponent} from './scorm/scorm.component';

export * from './step-image-screenshot-with-pointer/step-image-screenshot-with-pointer.component';
import {
  StepImageScreenshotWithPointerComponent
} from './step-image-screenshot-with-pointer/step-image-screenshot-with-pointer.component';

export * from './preparation-note/preparation-note.component';
import {PreparationNoteComponent} from './preparation-note/preparation-note.component';

export * from './prototype-images/prototype-images.component';
import {PrototypeImagesComponent} from './prototype-images/prototype-images.component';

export * from './quote/quote.component';
import {QuoteComponent} from './quote/quote.component';

export * from './rating/survey-rating/survey-rating.component';
import {SurveyRatingComponent} from './rating/survey-rating/survey-rating.component';

export * from './prosemirror/prosemirror.component';
import {ProsemirrorBitComponent} from './prosemirror/prosemirror.component';

export * from './annotations/annotation-note/annotation-note.component';
import {AnnotationNoteComponent} from './annotations/annotation-note/annotation-note.component';

export * from './annotations/annotation-bookmark/annotation-bookmark.component';
import {AnnotationBookmarkComponent} from './annotations/annotation-bookmark/annotation-bookmark.component';

export * from './annotations/annotation-favorite/annotation-favorite.component';
import {AnnotationFavoriteComponent} from './annotations/annotation-favorite/annotation-favorite.component';

export * from './annotations/annotation-handwritten/annotation-handwritten.component';
import {AnnotationHandwrittenComponent} from './annotations/annotation-handwritten/annotation-handwritten.component';

export * from './learning-path/learning-path-book/choose-book/choose-book.component';
import {ChooseBookComponent} from './learning-path/learning-path-book/choose-book/choose-book.component';

export * from '../shared/book-cover/book-cover.component';
import {BookCoverComponent} from '../shared/book-cover/book-cover.component';

export * from '../shared/book-cover/book-default-cover/book-default-cover.component';
import {BookDefaultCoverComponent} from '../shared/book-cover/book-default-cover/book-default-cover.component';

export * from './learning-path/learning-path-book/choose-book-anchor/choose-book-anchor.component';
import {
  ChooseBookAnchorComponent
} from './learning-path/learning-path-book/choose-book-anchor/choose-book-anchor.component';

export * from './bitmark.pipe';
import {BitmarkPipe} from './bitmark.pipe';

export * from './bitmark-reverse.pipe';
import {BitmarkReversePipe} from './bitmark-reverse.pipe';
import {NodeviewGapComponent} from '../reader/tiptap/nodes/gap/gap.component';
import {NodeviewMultipleChoiceComponent} from '../reader/tiptap/nodes/multiple-choice/multiple-choice.component';
import {NodeviewImageComponent} from '../reader/tiptap/nodes/image/image.component';
import {ResizableComponent} from '../reader/tiptap/nodes/resizable/resizable.component';
import {NodeviewVideoComponent} from '../reader/tiptap/nodes/video/video.component';
import {NodeviewAudioComponent} from '../reader/tiptap/nodes/audio/audio.component';
import {NodeviewMathComponent} from '../reader/tiptap/nodes/math/math.component';
import {
  ContextualMenuTextComponent
} from '../reader/reader-editor/contextual-menus/contextual-menu-text/contextual-menu-text.component';
import {
  ContextualMenuImageComponent
} from '../reader/reader-editor/contextual-menus/contextual-menu-image/contextual-menu-image.component';
import {
  ContextualMenuHeaderComponent
} from '../reader/reader-editor/contextual-menus/contextual-menu-header/contextual-menu-header.component';
import {
  ContextualMenuCodeComponent
} from '../reader/reader-editor/contextual-menus/contextual-menu-code/contextual-menu-code.component';
import {
  ContextualMenuQuoteComponent
} from '../reader/reader-editor/contextual-menus/contextual-menu-quote/contextual-menu-quote.component';
import {
  ContextualMenuListComponent
} from '../reader/reader-editor/contextual-menus/contextual-menu-list/contextual-menu-list.component';

export const components = [
  BitBookSummaryComponent,
  BitBookEndingComponent,
  ReaderCoverComponent,
  NotebookLearningPathCoverComponent,
  BitActionsComponent,
  BitFeedbackComponent,
  BitComponent,
  BitmarkTextComponent,
  BitmarkEditableTextComponent,
  ButtonCopyTextComponent,
  PageBuyButtonComponent,
  PageBuyButtonPromotionComponent,
  PageFooterComponent,
  PageOpenBookComponent,
  PageProductComponent,
  PageProductListComponent,
  PageProductVideoComponent,
  PageProductVideoListComponent,
  PageSectionFolderComponent,
  AppAiPromptComponent,
  AppCodeEditorComponent,
  AppFlashcardsComponent,
  AppFlashcardsCardComponent,
  AppFlashcardsLeitnerComponent,
  AppFlashcardsNormalComponent,
  ArticleComponent,
  ArticleAltComponent,
  ModuleComponent,
  ModuleFieldsComponent,
  VirtualMarkBookAsReadComponent,
  VirtualHandInComponent,
  VirtualHandInReviewComponent,
  VirtualHandInResultComponent,
  Card1Component,
  PrototypeImagesComponent,
  QuoteComponent,
  SurveyRatingComponent,
  AssignmentComponent,
  ChapterComponent,
  ClozeComponent,
  CodeComponent,
  ConversationLeft1Component,
  ConversationLeft1ScreamComponent,
  ConversationLeft1ThoughtComponent,
  ConversationRight1Component,
  ConversationRight1ScreamComponent,
  ConversationRight1ThoughtComponent,
  GapPlaceholderComponent,
  SelectPlaceholderComponent,
  CorrectionComponent,
  CookIngredientsComponent,
  EssayComponent,
  ExtractorBlockComponent,
  Flashcard1Component,
  InternalLinkComponent,
  ImageComponent,
  ImagesLogoGraveComponent,
  ImageResponsiveComponent,
  InterviewComponent,
  BitItemInstructionComponent,
  BotActionSendComponent,
  BotActionResponseComponent,
  LearningEventCommon,
  LearningEventClassroomEventComponent,
  LearningEventCompletionComponent,
  LearningEventExternalLinkComponent,
  LearningEventLearningObjectivesComponent,
  LearningEventLearningStepComponent,
  LearningEventPreparationTaskComponent,
  LearningEventReadBookComponent,
  LearningEventVideoCallComponent,
  LearningPathClosingComponent,
  LearningPathVideoCallComponent,
  LearningPathBookComponent,
  LearningPathClassroomEventComponent,
  LearningPathExternalLinkComponent,
  LearningPathStepComponent,
  LearningPathLearningGoalComponent,
  LearningPathLtiComponent,
  BookSourcesComponent,
  BookLinkComponent,
  BookCloseComponent,
  PadletEmbedComponent,
  MarkComponent,
  MatchComponent,
  MatchGapPlaceholderComponent,
  MatchMatrixComponent,
  MatchSolutionGroupedComponent,
  MatchSelectPlaceholderComponent,
  MessageComponent,
  MultipleChoiceComponent,
  MultipleChoice1Component,
  MultipleResponseComponent,
  MultipleResponse1Component,
  PageComponent,
  PagePersonComponent,
  PageSubscribeComponent,
  PreparationNoteComponent,
  ResourceComponent,
  ResourceArticleComponent,
  ResourceImageComponent,
  ResourceAudioComponent,
  ResourceVideoComponent,
  ResourceWebsiteLinkComponent,
  ResourceImageLinkComponent,
  ResourceVideoLinkComponent,
  ResourceDocumentLinkComponent,
  ResourceVideoLinkEditComponent,
  ResourceImageLinkEditComponent,
  ResourceWebsiteLinkEditComponent,
  ResourceDocumentLinkEditComponent,
  ResourceAudioRecordingEditComponent,
  ResourceVideoRecordingEditComponent,
  ResourceCameraPhotoEditComponent,
  ResourceBaseCameraEditComponent,
  SelfAssessmentComponent,
  StatementComponent,
  Details1Component,
  AboutComponent,
  DetailsImageComponent,
  RecordAudioComponent,
  RecordAudioEditComponent,
  RecordVideoEditComponent,
  RecordVideoComponent,
  ReleaseNoteComponent,
  SummaryComponent,
  SurveyComponent,
  SurveyMatrixComponent,
  ScormComponent,
  StepImageScreenshotWithPointerComponent,
  TableComponent,
  TableImageComponent,
  TakePictureComponent,
  TocComponent,
  TrueFalseComponent,
  TrueFalse1Component,
  IframelyEmbedComponent,
  HighchartsChartComponent,
  AnnotationNoteComponent,
  AnnotationBookmarkComponent,
  AnnotationFavoriteComponent,
  AnnotationHandwrittenComponent,
  ProsemirrorBitComponent,
  NodeviewGapComponent,
  NodeviewMultipleChoiceComponent,
  NodeviewImageComponent,
  NodeviewVideoComponent,
  NodeviewAudioComponent,
  NodeviewMathComponent,
  ResizableComponent,
  ContextualMenuTextComponent,
  ContextualMenuImageComponent,
  ContextualMenuCodeComponent,
  ContextualMenuHeaderComponent,
  ContextualMenuListComponent,
  ContextualMenuQuoteComponent,
  ChooseBookComponent,
  BookCoverComponent,
  BookDefaultCoverComponent,
  ChooseBookAnchorComponent
];

export const componentsExport = [
  BitFeedbackComponent,
  BitComponent,
  ButtonCopyTextComponent,
  PageBuyButtonComponent,
  PageBuyButtonPromotionComponent,
  PageFooterComponent,
  PageOpenBookComponent,
  PageProductComponent,
  PageProductListComponent,
  PageProductVideoComponent,
  PageProductVideoListComponent,
  PageSectionFolderComponent,
  BitmarkTextComponent,
  BitmarkEditableTextComponent,
  AppAiPromptComponent,
  AppCodeEditorComponent,
  AppFlashcardsComponent,
  AppFlashcardsLeitnerComponent,
  AppFlashcardsNormalComponent,
  ArticleComponent,
  ArticleAltComponent,
  ModuleComponent,
  ModuleFieldsComponent,
  VirtualMarkBookAsReadComponent,
  VirtualHandInComponent,
  VirtualHandInReviewComponent,
  VirtualHandInResultComponent,
  Card1Component,
  AssignmentComponent,
  ChapterComponent,
  ClozeComponent,
  CodeComponent,
  ConversationLeft1Component,
  ConversationLeft1ScreamComponent,
  ConversationLeft1ThoughtComponent,
  ConversationRight1Component,
  ConversationRight1ScreamComponent,
  ConversationRight1ThoughtComponent,
  GapPlaceholderComponent,
  SelectPlaceholderComponent,
  CorrectionComponent,
  CookIngredientsComponent,
  EssayComponent,
  ExtractorBlockComponent,
  Flashcard1Component,
  InternalLinkComponent,
  ImageComponent,
  ImagesLogoGraveComponent,
  ImageResponsiveComponent,
  InterviewComponent,
  BitItemInstructionComponent,
  BotActionSendComponent,
  BotActionResponseComponent,
  LearningEventCommon,
  LearningEventClassroomEventComponent,
  LearningEventCompletionComponent,
  LearningEventExternalLinkComponent,
  LearningEventLearningObjectivesComponent,
  LearningEventLearningStepComponent,
  LearningEventPreparationTaskComponent,
  LearningEventReadBookComponent,
  LearningEventVideoCallComponent,
  LearningPathClosingComponent,
  LearningPathVideoCallComponent,
  LearningPathBookComponent,
  LearningPathClassroomEventComponent,
  LearningPathExternalLinkComponent,
  LearningPathStepComponent,
  LearningPathLearningGoalComponent,
  LearningPathLtiComponent,
  MarkComponent,
  MatchComponent,
  MatchGapPlaceholderComponent,
  MatchMatrixComponent,
  MessageComponent,
  MultipleChoiceComponent,
  MultipleResponseComponent,
  PreparationNoteComponent,
  ResourceComponent,
  SelfAssessmentComponent,
  StatementComponent,
  Details1Component,
  AboutComponent,
  DetailsImageComponent,
  SummaryComponent,
  SurveyComponent,
  SurveyMatrixComponent,
  TableComponent,
  TableImageComponent,
  TakePictureComponent,
  TocComponent,
  TrueFalseComponent,
  RecordAudioComponent,
  RecordAudioEditComponent,
  RecordVideoEditComponent,
  RecordVideoComponent,
  ReleaseNoteComponent,
  PrototypeImagesComponent,
  QuoteComponent,
  SurveyRatingComponent,
  AnnotationNoteComponent,
  AnnotationBookmarkComponent,
  AnnotationFavoriteComponent,
  AnnotationHandwrittenComponent,
  ChooseBookComponent,
  BookLinkComponent,
  BookCloseComponent,
  BookCoverComponent,
  BookDefaultCoverComponent,
  NotebookLearningPathCoverComponent,
  ChooseBookAnchorComponent
];

export const pipes = [
  SafeHtmlPipe,
  SafeStylePipe,
  BitmarkPipe,
  BitmarkReversePipe
];

