import {Inject, Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import {concatMap, Observable, of} from 'rxjs';
import {BitmarkPagination, BookCoverEntity, BookEntity} from './reader.models';
import {ReaderLocalContentService} from './reader-local-content.service';
import {ApiService} from '../shared/api/api.service';
import {BaseBit, BitApiAnnotation, BitApiWrapper, BitFeedbackRes, BitType} from '../bits/bits.models';
import {BitmarkFormat, BookType, SupportedTranslationLanguage} from '../shared/models';
import {ChapterBit} from '../bits/chapter/chapter.models';
import {QuoteBit} from '../bits/quote/quote.models';
import {ProsemirrorBit} from '../bits/prosemirror/prosemirror.models';
import {BitmarkConfig} from '../bitmark.module';
import {TocItem} from './reader-book/reader-toc-sidebar/reader-toc-sidebar.component';
import {map} from 'rxjs/operators';
import {Pagination} from '../shared';

export interface BitBookApiFilter {
  location?: string;
  q?: string;
  bookId?: string;
  space?: string;
  page?: number;
}

export interface BitBookSearchResults {
  results: Array<BitApiWrapper>;
  count: number;
  searchId: string;
}

export interface UploadPdfJob {
  details: {
    ETA: number,
    preview: any
  },
  status: string,
  link?: string,
  outcome?: {
    width?: number,
    height?: number,
    preview?: any,
    link?: string
  }
}

@Injectable()
export class BitbookApiService {
  constructor(@Inject('BitmarkConfig') private bitmarkConfig: BitmarkConfig,
              private apiService: ApiService,
              private readerLocalContentService: ReaderLocalContentService) {
  }

  getNotebooks(pagination: Pagination): Observable<{ allCollections: Array<BookEntity>, visited: Array<BookEntity> }> {
    const qry = ApiService.buildQuery([{page: pagination?.page, take: pagination.pageSize}], true);
    return this.apiService.get(`client/gmb/workspace?${qry}`, null);
  }

  getTrashedNotebooks(): Observable<Array<BookEntity>> {
    return this.apiService.get('client/books/trash/collections', null);
  }

  getLastViewedNotebooks(): Observable<Array<BookEntity>> {
    return this.apiService.get('client/books/collections?sort=lastViewed', null);
  }

  getMyBoughtBooks(): Observable<Array<BookEntity>> {
    return this.apiService.get('client/books/bought', null);
  }

  getCoachSessions(spaceId): Observable<Array<{
    bitmarkTrainingSessions: Array<BookEntity>,
    space: {
      id: number,
      code: string
    }
  }>> {
    let url = 'client/books/coach-sessions';
    if (spaceId) {
      url += `?spaceId=${spaceId}`;
    }
    return this.apiService.get(url, null);
  }

  getMyBorrowedBooks(spaceId?: string): Observable<Array<BookEntity>> {
    let url = 'client/books/borrowed';
    if (spaceId) {
      url += `?spaceId=${spaceId}`;
    }
    return this.apiService.get(url, null);
  }

  getBooksCovers(): Observable<Array<BookCoverEntity>> {
    return this.apiService.get('client/books/space', null);
  }

  getBooksExistStatus(bookIds: Array<string>): Observable<Array<any>> {
    let qry = '';
    qry = ApiService.buildQuery([{bookIds}], true);
    return this.apiService.get(`client/books/exists?${qry}`, null);
  }

  getBooksSpacePermissionsStatus(bookIds: Array<string>): Observable<Array<any>> {
    let qry = '';
    qry = ApiService.buildQuery([{bookIds}], true);
    return this.apiService.get(`client/books/space-permissions?${qry}`, null);
  }

  getBookById(bookExternalId: string, queryParams?: any): Observable<BookEntity> {
    if (!(queryParams?.length > 0 && queryParams.some((q) => q.space || q.spaceId))) {
      queryParams = queryParams ? queryParams.concat([{spaceId: this.bitmarkConfig.space}]) : [{spaceId: this.bitmarkConfig.space}];
    }
    let qry = '';
    if (queryParams) {
      qry = ApiService.buildQuery(queryParams, true);
    } else {
      qry = '';
    }
    return this.apiService.get(`client/books/{bookExternalId}?${qry}`, {bookExternalId});
  }

  getBasicBookById(bookExternalId: string): Observable<BookEntity> {
    return this.apiService.get(`client/books/{bookExternalId}/basic`, {bookExternalId});
  }

  getPublicBookById(bookExternalId: string, queryParams?: any): Observable<BookEntity> {
    if (!(queryParams?.length > 0 && queryParams.some((q) => q.space || q.spaceId))) {
      queryParams = queryParams ? queryParams.concat([{spaceId: this.bitmarkConfig.space}]) : [{spaceId: this.bitmarkConfig.space}];
    }
    let qry = '';
    if (queryParams) {
      qry = ApiService.buildQuery(queryParams, true);
    } else {
      qry = '';
    }
    return this.apiService.get(`public/gmb/books/{bookExternalId}?${qry}`, {bookExternalId});
  }

  getOpenBookById(bookExternalId: string, queryParams?: any): Observable<BookEntity> {
    if (!(queryParams?.length > 0 && queryParams.some((q) => q.space || q.spaceId))) {
      queryParams = queryParams ? queryParams.concat([{spaceId: this.bitmarkConfig.space}]) : [{spaceId: this.bitmarkConfig.space}];
    }
    let qry = '';
    if (queryParams) {
      qry = ApiService.buildQuery(queryParams, true);
    } else {
      qry = '';
    }
    return this.apiService.get(`client/books/open-content/{bookExternalId}?${qry}`, {bookExternalId});
  }

  getBookByIdAndCourseId(bookExternalId: string, courseId: string, queryParams?: any): Observable<BookEntity> {
    if (!(queryParams?.length > 0 && queryParams.some((q) => q.space || q.spaceId))) {
      queryParams = queryParams ? queryParams.concat([{spaceId: this.bitmarkConfig.space}]) : [{spaceId: this.bitmarkConfig.space}];
    }
    let qry = '';
    if (queryParams) {
      qry = ApiService.buildQuery(queryParams, true);
    } else {
      qry = '';
    }
    return this.apiService.get(`client/books/{bookExternalId}/courseId?${qry}`, {bookExternalId}, {courseId});
  }

  getLeSessionBook(sessionId: string): Observable<BookEntity> {
    return this.apiService.get(`client/sessions/{sessionId}/book`, {sessionId});
  }

  getNotebookById(bookExternalId: string, queryParams?: any): Observable<BookEntity> {
    if (!(queryParams?.length > 0 && queryParams.some((q) => q.space || q.spaceId))) {
      queryParams = queryParams ? queryParams.concat([{spaceId: this.bitmarkConfig.space}]) : [{spaceId: this.bitmarkConfig.space}];
    }
    let qry = '';
    if (queryParams) {
      qry = ApiService.buildQuery(queryParams, true);
    } else {
      qry = '';
    }
    return this.apiService.get(`client/collections/{bookExternalId}?${qry}`, {bookExternalId});
  }

  getXModules(moduleIds: Array<string>, queryParams?: any): Observable<Array<BookEntity>> {
    if (!(queryParams?.length > 0 && queryParams.some((q) => q.space || q.spaceId))) {
      queryParams = queryParams ? queryParams.concat([{spaceId: this.bitmarkConfig.space}]) : [{spaceId: this.bitmarkConfig.space}];
    }
    queryParams = queryParams.concat([{ids: moduleIds}]);
    let qry = '';
    if (queryParams) {
      qry = ApiService.buildQuery(queryParams, true);
    } else {
      qry = '';
    }
    return this.apiService.get(`client/modules?${qry}`, null, null);
  }

  getXModuleById(xModuleId: string, queryParams?: any): Observable<BookEntity> {
    if (!(queryParams?.length > 0 && queryParams.some((q) => q.space || q.spaceId))) {
      queryParams = queryParams ? queryParams.concat([{spaceId: this.bitmarkConfig.space}]) : [{spaceId: this.bitmarkConfig.space}];
    }
    let qry = '';
    if (queryParams) {
      qry = ApiService.buildQuery(queryParams, true);
    } else {
      qry = '';
    }
    return this.apiService.get(`client/modules/{xModuleId}?${qry}`, {xModuleId}, {xModuleId});
  }

  updateXModule(loggedInSpace: string, xModuleId: string, updates: any) {
    let spaceId;
    if (loggedInSpace !== 'personal') {
      spaceId = loggedInSpace;
    }
    const qry = ApiService.buildQuery([{spaceId, includeRelatedTraining: true}]);
    return this.apiService.put(`client/modules/{xModuleId}?${qry}`, {xModuleId}, updates);
  }

  createXModule(loggedInSpace: string, xModuleId: string) {
    let spaceId;
    const title = 'New XModule';
    const subtitle = 'This is the new xModule';
    if (loggedInSpace !== 'personal') {
      spaceId = loggedInSpace;
    }
    const qry = ApiService.buildQuery([{spaceId, includeRelatedTraining: true}]);
    // return this.apiService.post(`client/modules/{xModuleId}?${qry}`, {xModuleId}, null);
    return this.apiService.post(`client/modules?${qry}`, null, {externalId: xModuleId, title, subtitle});
  }

  updateXModuleCover(imageFile: File, xModuleId: string) {
    return this.apiService.upload('client/modules/{xModuleId}/image', {xModuleId}, imageFile, 'file', null, null, true);
  }

  getBookBitmark(bookExternalId: string): Observable<string> {
    return this.apiService.get('client/books/{bookExternalId}/bitmark-bits', {bookExternalId}, null, {
      headers: {'Content-Type': 'text/plain'},
      responseType: 'text',
    });
  }

  saveBookBitmark(bookExternalId: string, bitmark: string) {
    return this.apiService.put('client/books/{bookExternalId}/content/ids', {bookExternalId}, bitmark, {
      headers: {'Content-Type': 'text/plain'},
      responseType: 'text'
    });
  }

  grantUserBookPermissions(bookExternalId: string, userEmails: Array<string>) {
    return this.apiService.put('client/books/{bookExternalId}/permission', {bookExternalId}, {userIds: userEmails});
  }

  getFiltersForBook(bookExternalId: string): Observable<BookEntity> {
    return this.apiService.get('client/filters/{bookExternalId}', {bookExternalId});
  }

  searchBook(activeFilters: any, searchId: string): Observable<BitBookSearchResults> {
    Object.keys(activeFilters)?.forEach((a) => {
      if (activeFilters[a]?.length === 0) {
        delete activeFilters[a];
      }
    });
    const appliedFilters = [activeFilters].concat([
      {take: 20},
      this.bitmarkConfig.space ? {space: this.bitmarkConfig.space} : null
    ]);

    const qry = ApiService.buildQuery(appliedFilters, true);
    return this.apiService.get(`client/search?${qry}`, null)
      .pipe(map((res: any) => Array.isArray(res)
        ? {results: this.mockPatchBits(res), count: res.length, searchId: searchId}
        : {...res, results: this.mockPatchBits(res.results), searchId}
      ));
  }

  searchAllBooks(activeFilters: BitBookApiFilter): Observable<BitBookSearchResults> {
    const appliedFilters = [activeFilters].concat([this.bitmarkConfig.space ? {space: this.bitmarkConfig.space} : null]);
    const qry = ApiService.buildQuery(appliedFilters, true);
    return this.apiService.get(`client/search?${qry}`, null)
      .pipe(map((res: any) => Array.isArray(res)
        ? {results: this.mockPatchBits(res), count: res.length}
        : {results: this.mockPatchBits(res.results), ...res}
      ));
  }

  getBookContentPublic(bookExternalId: string, pagination?: BitmarkPagination, queryParams: any = {}): Observable<Array<BitApiWrapper>> {
    if (queryParams && typeof queryParams === 'object' && !Array.isArray(queryParams)) {
      // Convert object to an array with one item, then concatenate
      queryParams = [queryParams].concat([{spaceId: this.bitmarkConfig.space}]);
    } else if (!queryParams) {
      queryParams = [{spaceId: this.bitmarkConfig.space}];
    } else {
      queryParams = queryParams.concat([{spaceId: this.bitmarkConfig.space}]);
    }
    const appliedFilters = [{skip: (pagination.pageNumber - 1) * pagination.pageSize}, {take: pagination.pageSize}]
      .concat(queryParams);
    const qry = ApiService.buildQuery(appliedFilters);

    const url = `public/gmb/books/{bookExternalId}/content?${qry}`;
    return this.apiService.get(url, {bookExternalId})
      .pipe(map((res: {
        content: Array<any>,
        skip: number,
        take: number
      }) => {
        const ret = {content: res.content};
        for (let i = 0; i < ret.content.length; i++) {
          ret.content[i].index = i;
        }
        return this.mockPatchBits(res.content);
      }));
  }

  getBookContentPage(bookExternalId: string, pagination: BitmarkPagination, queryParams: any = {}, isOpenContent = false): Observable<Array<BitApiWrapper>> {
    if (queryParams && typeof queryParams === 'object' && !Array.isArray(queryParams)) {
      // Convert object to an array with one item, then concatenate
      queryParams = [queryParams].concat([{spaceId: this.bitmarkConfig.space}]);
    } else if (!queryParams) {
      queryParams = [{spaceId: this.bitmarkConfig.space}];
    } else {
      queryParams = queryParams.concat([{spaceId: this.bitmarkConfig.space}]);
    }
    const appliedFilters = [{skip: (pagination.pageNumber - 1) * pagination.pageSize}, {take: pagination.pageSize}]
      .concat(queryParams);
    const qry = ApiService.buildQuery(appliedFilters);

    const url = `client/books/{bookExternalId}/${isOpenContent ? 'open-' : ''}content?skip=0&${qry}`;
    return this.apiService.get(url, {bookExternalId})
      .pipe(map((res: {
        content: Array<any>,
        skip: number,
        take: number
      }) => {
        const ret = {content: res.content};
        for (let i = 0; i < ret.content.length; i++) {
          ret.content[i].index = i;
        }
        return this.mockPatchBits(res.content);
      }));
  }

  getLeBookContentPage(bookExternalId: string, pagination: BitmarkPagination, queryParams: any = {}): Observable<Array<BitApiWrapper>> {
    if (queryParams && typeof queryParams === 'object' && !Array.isArray(queryParams)) {
      // Convert object to an array with one item, then concatenate
      queryParams = [queryParams].concat([{spaceId: this.bitmarkConfig.space}]);
    } else if (!queryParams) {
      queryParams = [{spaceId: this.bitmarkConfig.space}];
    } else {
      queryParams = queryParams.concat([{spaceId: this.bitmarkConfig.space}]);
    }
    const appliedFilters = [{skip: (pagination.pageNumber - 1) * pagination.pageSize}, {take: pagination.pageSize}]
      .concat(queryParams);
    const qry = ApiService.buildQuery(appliedFilters);

    const url = `client/sessions/{bookExternalId}/content?skip=0&${qry}`;
    return this.apiService.get(url, {bookExternalId})
      .pipe(map((res: {
        content: Array<any>,
        skip: number,
        take: number
      }) => {
        const ret = {content: res.content};
        for (let i = 0; i < ret.content.length; i++) {
          ret.content[i].index = i;
        }
        return this.mockPatchBits(res.content);
      }));
  }

  getBookContentStartingWithBit(bookExternalId: string, bitId: string, bitIndex: number, onlyNetworkData?: boolean, queryParams?: any, pagination?: BitmarkPagination): Observable<Array<BitApiWrapper>> {
    if (queryParams && typeof queryParams === 'object' && !Array.isArray(queryParams)) {
      // Convert object to an array with one item, then concatenate
      queryParams = [queryParams].concat([{spaceId: this.bitmarkConfig.space}]);
    } else if (!queryParams) {
      queryParams = [{spaceId: this.bitmarkConfig.space}];
    } else {
      queryParams = queryParams.concat([{spaceId: this.bitmarkConfig.space}]);
    }
    if (pagination?.pageSize) {
      queryParams = [{take: pagination.pageSize}].concat(queryParams);
    }
    let qry = ApiService.buildQuery(queryParams);
    if (qry) {
      qry = `?${qry}`;
    }

    const q = onlyNetworkData
      ? of(null)
      : this.readerLocalContentService.getBookContentStartingWithBit(bookExternalId, bitIndex);
    return q
      .pipe(concatMap((localData: Array<BitApiWrapper>) => localData
        ? of(localData)
        : this.apiService.get(`client/books/{bookExternalId}/content/start/{bitId}${qry}`, {
          bookExternalId,
          bitId,
        }, queryParams)))
      .pipe(concatMap((res: {
        content: Array<BitApiWrapper>,
        index: number
      }) => {
        let i = 0;
        res.content?.forEach((b) => {
          b.index = res.index + i;
          i++;
        });
        return of(this.mockPatchBits(res.content));
      }));
  }

  getBookContentEndingWithBit(bookExternalId: string, bitId: string, bitIndex: number, onlyNetworkData?: boolean, queryParams?: any, pagination?: BitmarkPagination): Observable<Array<BitApiWrapper>> {
    if (queryParams && typeof queryParams === 'object' && !Array.isArray(queryParams)) {
      // Convert object to an array with one item, then concatenate
      queryParams = [queryParams].concat([{spaceId: this.bitmarkConfig.space}]);
    } else if (!queryParams) {
      queryParams = [{spaceId: this.bitmarkConfig.space}];
    } else {
      queryParams = queryParams.concat([{spaceId: this.bitmarkConfig.space}]);
    }
    if (pagination?.pageSize) {
      queryParams = [{take: pagination.pageSize}].concat(queryParams);
    }
    let qry = ApiService.buildQuery(queryParams);
    if (qry) {
      qry = `?${qry}`;
    }

    const q = onlyNetworkData
      ? of(null)
      : this.readerLocalContentService.getBookContentEndingWithBit(bookExternalId, bitIndex);
    return q
      .pipe(concatMap((localData: Array<BitApiWrapper>) => localData
        ? of(localData)
        : this.apiService.get(`client/books/{bookExternalId}/content/end/{bitId}${qry}`, {
          bookExternalId,
          bitId,
        }, queryParams)))
      .pipe(concatMap((res: {
        content: Array<BitApiWrapper>,
        index: number
      }) => {
        let i = 0;
        res.content.reverse().forEach((b) => {
          b.index = res.index - i;
          i++;
        });
        res.content.reverse();
        return of(this.mockPatchBits(res.content));
      }));
  }

  getBookContentWithBitInMiddle(bookExternalId: string, bitId: string, bitIndex: number, onlyNetworkData?: boolean, queryParams?: any, pagination?: BitmarkPagination): Observable<Array<BitApiWrapper>> {
    if (queryParams && typeof queryParams === 'object' && !Array.isArray(queryParams)) {
      // Convert object to an array with one item, then concatenate
      queryParams = [queryParams].concat([{spaceId: this.bitmarkConfig.space}]);
    } else if (!queryParams) {
      queryParams = [{spaceId: this.bitmarkConfig.space}];
    } else {
      queryParams = queryParams.concat([{spaceId: this.bitmarkConfig.space}]);
    }
    queryParams = queryParams ? queryParams.concat([{spaceId: this.bitmarkConfig.space}]) : [{spaceId: this.bitmarkConfig.space}];
    if (pagination?.pageSize) {
      queryParams = [{take: pagination.pageSize}].concat(queryParams);
    }
    let qry = ApiService.buildQuery(queryParams);
    if (qry) {
      qry = `?${qry}`;
    }

    const q = onlyNetworkData
      ? of(null)
      : this.readerLocalContentService.getBookContentEndingWithBit(bookExternalId, bitIndex);
    return q
      .pipe(concatMap((localData: Array<BitApiWrapper>) => localData
        ? of(localData)
        : this.apiService.get(`client/books/{bookExternalId}/content/middle/{bitId}${qry}`, {
          bookExternalId,
          bitId,
        }, queryParams)))
      .pipe(concatMap((res: {
        content: Array<BitApiWrapper>,
        index: number
      }) => {
        const bIdx = res.content.findIndex((b) => b.id == bitId);
        if (res.content && res.content.length) {
          let i = 1, j = 0;
          res.content.slice(0, bIdx)?.reverse().forEach((b) => {
            b.index = res.index - i;
            i++;
          });
          res.content.slice(bIdx)?.forEach((b) => {
            b.index = res.index + j;
            j++;
          });
        }
        return of(this.mockPatchBits(res.content));
      }));
  }

  getMyLibraryBooks(queryParams?: any): Observable<{ visitedBooks: Array<any>, bought: Array<any>, borrowed: Array<any>, modules: Array<any>, borrowedModules: Array<any>, trainingSessions: Array<any>, xcourses: Array<any> }> {
    if (!(queryParams?.length > 0 && queryParams.some((q) => q.space || q.spaceId))) {
      queryParams = queryParams ? queryParams.concat([{spaceId: this.bitmarkConfig.space}]) : [{spaceId: this.bitmarkConfig.space}];
    }
    let qry = '';
    if (queryParams) {
      qry = ApiService.buildQuery(queryParams, true);
    } else {
      qry = '';
    }
    return this.apiService.get(`client/gmb/my-library?${qry}`, null, null);
  }

  getMyLibraryBooksStreamed(): Promise<any> {
    const qry = ApiService.buildQuery([{mode: 'streaming'}], true);
    return this.streamRequestJson(this.bitmarkConfig.bitbookClientApiUrl + `/gmb/my-library?${qry}`);
  }

  getLastViewedBooks(data: {
    type: 'book' | 'collection'
  }): Observable<Array<{
    id: number,
    type: string,
    when: string,
    bookMetaType: number
  }>> {
    const qry = ApiService.buildQuery([data], true);
    return this.apiService.get(`client/books/visited?${qry}`, null);
  }

  removeBookFromLastViewed(bookExternalId: string): Observable<boolean> {
    return this.apiService.delete('client/books/{bookExternalId}/visited', {bookExternalId});
  }

  markBookAsVisited(bookExternalId: string): Observable<boolean> {
    return this.apiService.post('client/books/{bookExternalId}/visited', {bookExternalId}, null);
  }

  uploadPdf(pdfFile: File): Observable<{ url: string }> {
    return this.apiService.upload('client/books/content/file', null, pdfFile, 'file', null, null);
  }

  uploadBitmark(bitmarkFile: File, notebook: any): Observable<{
    details: {
      ETA: number
    },
    status: string
  }> {
    return this.apiService.upload('client/books/content/file/bitmark', null, bitmarkFile, 'file', notebook, {
      headers: {'x-type': 'collection'},
      responseType: 'text',
    });
  }

  convertToBitmark(bitId: string): Observable<BitApiWrapper> {
    return this.apiService.put('client/bits/{bitId}/bitmark', {bitId}, null);
  }

  uploadPdfContent(pdfFile: File, bookExternalId: string, afterBitId: string) {
    return this.apiService.upload('client/books/{bookExternalId}/content/file', {bookExternalId}, pdfFile, 'file', {afterBitId}, null);
  }

  uploadNotebookCover(imageFile: File, bookExternalId: string) {
    return this.apiService.upload('client/books/{bookExternalId}/cover', {bookExternalId}, imageFile, 'file', null, null, true);
  }

  removeNotebookCover(bookExternalId: string) {
    return this.apiService.delete('client/books/{bookExternalId}/cover', {bookExternalId}, null);
  }

  getResourceJobStatus(jobId: string): Observable<UploadPdfJob> {
    return this.apiService.get('client/books/jobs/{id}', {id: jobId});
  }

  addBitsToNotebook(bookExternalId: any, bits: Array<{
    id: string
  } | {
    bitmark: string
  } | BaseBit>): Observable<any> {
    return this.apiService.post('client/books/{bookExternalId}/content/bit', {bookExternalId}, {bit: bits});
  }

  getBookTrashedContent(notebookId: string): Observable<{
    content: Array<BitApiWrapper>
  }> {
    return this.apiService.get('client/books/{bookExternalId}/content/trashed', {bookExternalId: notebookId});
  }

  getBookTrashedContentCount(notebookId: string): Observable<{
    count: number
  }> {
    return this.apiService.get('client/books/trash/{bookExternalId}/count', {bookExternalId: notebookId});
  }

  moveBitToBin(bitId: string, courseId?: string, queryParams?: any): Observable<{
    count: number
  }> {
    let query = '';
    if (courseId || queryParams) {
      const params = [];
      if (courseId) {
        params.push({courseId: courseId});
      }
      if (queryParams) {
        params.push(queryParams);
      }
      query = `?${ApiService.buildQuery(params, true)}`;
    }
    return this.apiService.delete(`client/bits/trash/{bitId}${query}`, {bitId});
  }

  restoreBitFromBin(bitId: string, courseId?: string, queryParams?: any): Observable<{
    count: number
  }> {
    let query = '';
    if (courseId || queryParams) {
      const params = [];
      if (courseId) {
        params.push({courseId: courseId});
      }
      if (queryParams) {
        params.push(queryParams);
      }
      query = `?${ApiService.buildQuery(params, true)}`;
    }
    return this.apiService.put(`client/bits/trash/{bitId}/restore${query}`, {bitId}, null);
  }

  deleteBitsFromBin(bookExternalId: string): Observable<void> {
    return this.apiService.delete('client/books/trash/{bookExternalId}/bits', {bookExternalId}, null);
  }

  restoreBitsFromBin(bitIds: Array<string>): Observable<void> {
    return this.apiService.put('client/bits/trash/restore', null, {bitIds});
  }

  removeBitFromNotebook(bitId: string, courseId?: string, queryParams?: any): Observable<any> {
    let query = '';
    if (courseId || queryParams) {
      const params = [];
      if (courseId) {
        params.push({courseId: courseId});
      }
      if (queryParams) {
        params.push(queryParams);
      }
      query = `?${ApiService.buildQuery(params, true)}`;
    }
    return this.apiService.delete(`client/bits/{bitId}${query}`, {bitId}, null);
  }

  getBasketBits(): Observable<Array<any>> {
    return this.apiService.get('client/books/basket', null);
  }

  getBasketCount(): Observable<{
    count: number
  }> {
    return this.apiService.get('client/books/basket/count', null);
  }

  addBitToBasket(payload: BaseBit | {
    id: string | number
  }): Observable<any> {
    return this.apiService.post('client/books/basket', null, {bit: [payload]});
  }

  addBookBitsToBasket(bookId: string): Observable<any> {
    return this.apiService.post(`client/books/basket`, null, {sourceBookId: bookId});
  }

  clearBasket(): Observable<any> {
    return this.apiService.delete('client/books/basket', null, null);
  }

  removeBitFromBasket(bitId: string): Observable<any> {
    return this.apiService.delete('client/bits/{bitId}', {bitId}, null);
  }

  cutToClipboard(bits: Array<{
    id: string
  } | {
    bitmark: string
  } | BaseBit>, queryParams?: any): Observable<any> {
    let qry = '';
    if (queryParams) {
      qry = `?${ApiService.buildQuery([queryParams], true)}`;
    }
    return this.apiService.post(`client/bits/cut${qry}`, null, {bits});
  }

  copyToClipboard(bits: Array<{
    id: string
  } | {
    bitmark: string
  } | BaseBit>, queryParams?: any): Observable<any> {
    let qry = '';
    if (queryParams) {
      qry = `?${ApiService.buildQuery([queryParams], true)}`;
    }
    return this.apiService.post(`client/bits/copy${qry}`, null, {bits});
  }

  pasteFromClipboard(bookExternalId: string, afterBitId?: string, queryParams?: any): Observable<{
    count: number,
    results: Array<BitApiWrapper>
  }> {
    let qry = '';
    if (queryParams) {
      qry = `?${ApiService.buildQuery([queryParams], true)}`;
    }
    return this.apiService.post(`client/books/{bookExternalId}/paste${qry}`, {bookExternalId}, afterBitId ? {afterBitId} : null);
  }

  getClipboardBits(queryParams?: any): Observable<any> {
    let qry = '';
    if (queryParams) {
      qry = `?${ApiService.buildQuery([queryParams], true)}`;
    }
    return this.apiService.post(`client/bits/paste${qry}`, null, null);
  }

  insertBitAfterBit(bookExternalId: string, bit: BaseBit | any, afterBitId?: string, courseId?: string, queryParams?: any): Observable<{
    bits: Array<BitApiWrapper>,
    toc: any
  }> {
    let query = '';
    if (courseId || queryParams) {
      const params = [];
      if (courseId) {
        params.push({courseId: courseId});
      }
      if (queryParams) {
        params.push(queryParams);
      }
      query = `?${ApiService.buildQuery(params, true)}`;
    }

    const url = afterBitId
      ? `client/books/{bookExternalId}/bits/content/{bitId}${query}`
      : `client/books/{bookExternalId}/bits/content${query}`;

    return this.apiService.post(url, {
      bookExternalId,
      bitId: afterBitId,
    }, {bit});
  }

  insertBitsAfterBit(bookExternalId: string, bits: Array<BaseBit>, afterBitId?: string, courseId?: string, queryParams?: any): Observable<{
    bits: Array<BitApiWrapper>,
    toc: any
  }> {
    let query = '';
    if (courseId || queryParams) {
      const params = [];
      if (courseId) {
        params.push({courseId: courseId});
      }
      if (queryParams) {
        params.push(queryParams);
      }
      query = `?${ApiService.buildQuery(params, true)}`;
    }

    const url = afterBitId
      ? `client/books/{bookExternalId}/bits/content/{bitId}${query}`
      : `client/books/{bookExternalId}/bits/content${query}`;

    return this.apiService.post(url, {
      bookExternalId,
      bitId: afterBitId,
    }, {bit: bits.map(b => ({bit: b}))});
  }

  addBitAnnotation(parentBitId: string, bit: BaseBit | any, type: string, handInId?: number): Observable<{
    createdAnnotations: Array<BitApiAnnotation>
  }> {
    return this.apiService.post('client/annotations/bits/{bitId}', {bitId: parentBitId}, {type, data: bit, handInId});
  }

  updateBitAnnotation(bitAnnotationId: number, bit: BaseBit | any, handInId?: number) {
    return this.apiService.put('client/annotations/{bitId}', {bitId: bitAnnotationId}, {data: bit, handInId});
  }

  deleteBitAnnotation(bitAnnotationId: number, handInId?: number) {
    return this.apiService.delete('client/annotations/{bitId}', {bitId: bitAnnotationId, handInId});
  }

  addBitmarkBitAfterBit(bookExternalId: string, bitmark: string, bitId: string, courseId?: string, queryParams?: any): Observable<{
    bits: Array<BitApiWrapper>,
    toc: any,
    tocEntry?: TocItem
  }> {
    let query = '';
    if (courseId || queryParams) {
      const params = [];
      if (courseId) {
        params.push({courseId: courseId});
      }
      if (queryParams) {
        params.push(queryParams);
      }
      query = `?${ApiService.buildQuery(params, true)}`;
    }

    return this.apiService.post(bitId
      ? `client/books/{bookExternalId}/bits/content/{bitId}${query}`
      : `client/books/{bookExternalId}/bits/content${query}`, {
      bookExternalId,
      bitId,
    }, {bitmark});
  }

  editBitFromBitmark(bitmark: string, bitId: string, courseId?: string, queryParams?: any): Observable<any> {
    let query = '';
    if (courseId || queryParams) {
      const params = [];
      if (courseId) {
        params.push({courseId: courseId});
      }
      if (queryParams) {
        params.push(queryParams);
      }
      query = `?${ApiService.buildQuery(params, true)}`;
    }

    return this.apiService.put(`client/bits/{bitId}/content${query}`, {bitId}, bitmark, {headers: ['content-type: application/json']});
  }

  editBitJson(json: any, bitId: string, tags?: Array<string>, courseId?: string, queryParams?: any): Observable<any> {
    let query = '';
    if (courseId || queryParams) {
      const params = [];
      if (courseId) {
        params.push({courseId: courseId});
      }
      if (queryParams) {
        params.push(queryParams);
      }
      query = `?${ApiService.buildQuery(params, true)}`;
    }

    const payload: any = {
      bit: json,
      bitmark: ''
    };
    if (tags?.length) {
      payload.tags = tags;
    }

    return this.apiService.put(`client/bits/{bitId}${query}`, {bitId}, payload, {headers: ['content-type: application/json']});
  }

  editBitFromProsemirror(json: any, bitId: string, bitType?: BitType, queryParams?: any): Observable<{
    bit: BitApiWrapper,
    tocEntry: TocItem
  }> {
    let query = '';
    if (queryParams) {
      query = `?${ApiService.buildQuery([queryParams], true)}`;
    }
    return this.apiService.put(`client/bits/{bitId}${query}`, {bitId}, {
      bit: {
        format: 'prosemirror',
        type: bitType || 'note',
        content: json,
      },
    }, {headers: ['content-type: application/json']});
  }

  updateBit(bit: BaseBit | ChapterBit | QuoteBit | ProsemirrorBit, queryParams?: any): Observable<any> {
    let query = '';
    if (queryParams) {
      query = `?${ApiService.buildQuery([queryParams], true)}`;
    }
    return this.apiService.put(`client/bits/{bitId}/partial${query}`, {bitId: bit.id}, bit);
  }

  cloneBits(bitIds: Array<string | number | BaseBit>, targetUserIds: Array<number | string>): Observable<Array<BitApiWrapper>> {
    return this.apiService.post('client/bits/clone', null, {bitIds, targetUserIds});
  }

  cloneBook(bookExternalId: any, incrementTitleCounter = false): Observable<any> {
    return this.apiService.post('client/books/{bookExternalId}/clone', {bookExternalId}, incrementTitleCounter ? {incrementTitleCounter} : false);
  }

  getSearchSuggestions(filter: string, params: {
    location: 'academy' | 'workspace' | 'my-library'
  }): Observable<Array<any>> {
    const appliedFilters = [params].concat([this.bitmarkConfig.space ? {space: this.bitmarkConfig.space} as any : null]);
    const qry = ApiService.buildQuery(appliedFilters, true);
    return this.apiService.get(`client/bits/suggest?q=${filter}${qry}`, null);
  }

  getBookSearchSuggestionsApi(filter: string, assortmentIds: Array<string>): Observable<Array<any>> {
    const qry = ApiService.buildQuery([this.bitmarkConfig.space ? {space: this.bitmarkConfig.space} : null, assortmentIds?.length ? {assortmentIds: assortmentIds} : null], true);
    return this.apiService.get(`client/private/books/suggest?q=${filter}${qry}`, null);
  }

  getBookSearchResultsApi(filters: {
    type?: string,
    q?: string,
    assortmentIds?: Array<string>,
    from?: number,
    size?: number
  }): Observable<Array<any>> {
    const appliedFilters = [filters].concat([this.bitmarkConfig.space && this.bitmarkConfig.space !== 'personal' ? {space: this.bitmarkConfig.space} as any : null]);
    const qry = ApiService.buildQuery(appliedFilters, true);
    return this.apiService.get(`client/private/books?${qry}`, {filters});
  }

  saveAnswer(bitId: string, bit: BaseBit, bitInstanceId?: number): Observable<{
    bit: BaseBit,
    id: number,
    bitId: any
  }> {
    return bitInstanceId
      ? this.apiService.put('client/bits/instances/{instanceId}', {instanceId: bitInstanceId}, {bit})
      : this.apiService.post('client/bits/{bitId}/instances', {bitId}, {bit});
  }

  getFeedback(bitId: string, bit: BaseBit, bitInstanceId?: number): Observable<BitFeedbackRes> {
    return bitInstanceId
      ? this.apiService.put('client/bits/instances/{instanceId}/feedback', {instanceId: bitInstanceId}, {bit})
      : this.apiService.post('client/bits/{bitId}/instances/feedback', {bitId}, {bit});
  }

  resetAnswer(bitId: string): Observable<BitApiWrapper> {
    return this.apiService.delete('client/bits/{bitId}/instances', {bitId});
  }

  private mockPatchBits(content: Array<BitApiWrapper>): Array<BitApiWrapper> {
    return content.map(b => {
      if (!b.meta) {
        b.meta = {};
      }
      if (!b.meta.originBook) {
        b.meta.originBook = {};
      }
      b.meta.originBook.type = b?.meta?.originBook?.type || BookType.Book;

      if (b?.bit?.format === BitmarkFormat.Prosemirror) {
        b.bit.type = BitType.Note;
        b.bit.format = BitmarkFormat.PP;
        (b.bit as any).body = (b.bit as any)?.bit || (b.bit as any)?.content || (b.bit as any)?.body;
      }

      return b;
    });
  }

  uploadResource(file: File, bitId?: string) {
    let options = null;
    if (bitId) {
      options = {headers: new HttpHeaders({'x-bit-id': `${bitId}`})};
    }
    return this.apiService.upload('client/resource', null, file, 'file', null, options);
  }

  uploadResourceWithProgress(file: File, bitId?: string) {
    let options = null;
    if (bitId) {
      options = {headers: new HttpHeaders({'x-bit-id': `${bitId}`})};
    }
    return this.apiService.uploadWithProgress('client/resource', null, file, 'file', null, options);
  }

  getUsersWhoBoughtCourse(trainingId: string, trainingSessionId: string) {
    return this.apiService.get('client/trainings/{trainingId}/purchase-status/{trainingSessionId}', {
      trainingId,
      trainingSessionId
    }, null);
  }

  getUsersWhoBoughtProduct(productId: string) {
    return this.apiService.get('client/{productId}/purchase-status', {
      productId,
    }, null);
  }

  getTrainingSessions(loggedInSpace: string, trainingId: string) {
    let spaceId;
    if (loggedInSpace !== 'personal') {
      spaceId = loggedInSpace;
    }
    const qry = ApiService.buildQuery([{spaceId, includeRelatedTraining: true}]);
    return this.apiService.get(`client/sessions/trainings/{trainingId}?${qry}`, {trainingId});
  }

  getSessionDetails(loggedInSpace: string, sessionId: string) {
    let spaceId;
    if (loggedInSpace !== 'personal') {
      spaceId = loggedInSpace;
    }
    const qry = ApiService.buildQuery([{spaceId, includeRelatedTraining: true, relatedSessions: true}]);
    return this.apiService.get(`client/sessions/{sessionId}?${qry}`, {sessionId});
  }

  getModulesForTraining(loggedInSpace: string, trainingId: string) {
    let spaceId;
    if (loggedInSpace !== 'personal') {
      spaceId = loggedInSpace;
    }
    const qry = ApiService.buildQuery([{spaceId, includeRelatedTraining: true}]);
    return this.apiService.get(`client/courses/trainings/{trainingId}?${qry}`, {trainingId});
  }

  updateSessionsForTraining(loggedInSpace: string, trainingId: string, operations: Array<{
    operation?: string,
    index?: number,
    data?: any
  }>) {
    let spaceId;
    if (loggedInSpace !== 'personal') {
      spaceId = loggedInSpace;
    }
    const qry = ApiService.buildQuery([{spaceId}]);
    return this.apiService.put(`client/sessions/trainings/{trainingId}/changes?${qry}`, {trainingId}, operations);
  }

  updateCoursesForTraining(loggedInSpace: string, trainingId: string, operations: Array<{
    operation?: string,
    index?: number,
    data?: any
  }>) {
    let spaceId;
    if (loggedInSpace !== 'personal') {
      spaceId = loggedInSpace;
    }
    const qry = ApiService.buildQuery([{spaceId}]);
    return this.apiService.put(`client/courses/trainings/{trainingId}/changes?${qry}`, {trainingId}, operations);
  }

  updateSessionsForLE(loggedInSpace: string, leSessionId: string, operations: Array<{
    operation?: string,
    index?: number,
    data?: any
  }>) {
    let spaceId;
    if (loggedInSpace !== 'personal') {
      spaceId = loggedInSpace;
    }
    const qry = ApiService.buildQuery([{spaceId}]);
    return this.apiService.put(`client/sessions/{leSessionId}/changes?${qry}`, {leSessionId}, operations);
  }

  getTrainingById(loggedInSpace: string, trainingId: string) {
    let spaceId;
    if (loggedInSpace !== 'personal') {
      spaceId = loggedInSpace;
    }
    const qry = ApiService.buildQuery([{spaceId, includeRelatedTraining: true}]);
    return this.apiService.get(`client/trainings/{trainingId}?${qry}`, {trainingId});
  }

  updateTraining(trainingId: string, updates: any) {
    return this.apiService.put(`client/trainings/{trainingId}`, {trainingId}, updates);
  }

  getBitChapterContent(bitId: string) {
    return this.apiService.get('client/bits/{bitId}/chapter', {bitId}, null);
  }

  generateAIBitmarkContentFromPrompt(prompt: string): Observable<{
    keyword: string
  }> {
    return this.apiService.post('openai/generate-notebook-bitmark', null, {prompt});
  }

  getAIResourceJobStatus(id: string): Observable<{
    id: string,
    details: {
      ETA: number
    },
    status: string,
    bitmark: string,
    title: string
  }> {
    return this.apiService.post('openai/poll-ai-notebook-job', null, {id});
  }

  async generateAIQuizzesFromBitmarkStream(text: string, language?: string): Promise<any> {
    return this.streamRequest(this.bitmarkConfig.openAiApiUrl + '/openai/quizzes-from-bitmark-stream', {
      text,
      language
    });
  }

  async generateBookSummaryFromBitmarkStream(text: string, language?: string): Promise<any> {
    return this.streamRequest(this.bitmarkConfig.openAiApiUrl + '/openai/summary-from-bitmark-stream', {
      text,
      language
    });
  }

  transcribeMediaBitStream(mediaUrl: string): Observable<{
    text: string
  }> {
    return this.apiService.post('openai/transcribe-media-file', null, {mediaUrl});
  }

  getSupportedLanguages(displayLanguage: string): Observable<Array<SupportedTranslationLanguage>> {
    return this.apiService.get('openai/translate/languages/{displayLanguage}', {displayLanguage});
  }

  //this takes an array of source languages as fallback in case google cannot detect the source language
  translateContent(bitWrapper: BitApiWrapper, language?: string, sourceLanguages?: Array<string>): Observable<{
    bitmark: string
  }> {
    return this.apiService.post('openai/translate-content', null, {bitWrapper, language, sourceLanguages});
  }

  private async streamRequest(url: string, data: any, options?: { method?: string }) {
    return await fetch(url, {
      method: options?.method || 'POST',
      headers: {
        'Authorization': `Bearer ${window.localStorage.getItem('gmb-cosmic_token')}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
    });
  }

  private async streamRequestJson(url: string) {
    return fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${window.localStorage.getItem('gmb-cosmic_token')}`,
        'Accept': 'application/json'
      }
    });
  }

  updateSessionDetails(loggedInSpace: string, sessionId: string, updates: any) {
    let spaceId;
    if (loggedInSpace !== 'personal') {
      spaceId = loggedInSpace;
    }
    const qry = ApiService.buildQuery([{spaceId}]);
    return this.apiService.put(`client/sessions/{sessionId}?${qry}`, {sessionId}, updates);
  }

  createBookRelease(loggedInSpace: string, bookExternalId: string, payload: any) {
    let spaceId;
    if (loggedInSpace !== 'personal') {
      spaceId = loggedInSpace;
    }
    const qry = ApiService.buildQuery([{spaceId}]);
    return this.apiService.post(`client/books/{bookExternalId}/releases?${qry}`, {bookExternalId}, payload);
  }

  createBook(loggedInSpace: string, bookExternalId: string, payload: any) {
    let spaceId;
    if (loggedInSpace !== 'personal') {
      spaceId = loggedInSpace;
    }
    const qry = ApiService.buildQuery([{spaceId}]);
    return this.apiService.post(`client/books/{bookExternalId}?${qry}`, {bookExternalId}, payload);
  }

  updateBookDetails(loggedInSpace: string, bookExternalId: string, payload: any) {
    let spaceId;
    if (loggedInSpace !== 'personal') {
      spaceId = loggedInSpace;
    }
    const qry = ApiService.buildQuery([{spaceId}]);
    return this.apiService.put(`client/books/{bookExternalId}?${qry}`, {bookExternalId}, payload);
  }

  updateBookReleaseVersion(loggedInSpace: string, releaseId: string, payload: any) {
    let spaceId;
    if (loggedInSpace !== 'personal') {
      spaceId = loggedInSpace;
    }
    const qry = ApiService.buildQuery([{spaceId}]);
    return this.apiService.put(`client/books/releases/{releaseId}?${qry}`, {releaseId}, payload);
  }

  deleteBookReleaseVersion(loggedInSpace: string, releaseId: string) {
    let spaceId;
    if (loggedInSpace !== 'personal') {
      spaceId = loggedInSpace;
    }
    const qry = ApiService.buildQuery([{spaceId}]);
    return this.apiService.delete(`client/books/releases/{releaseId}?${qry}`, {releaseId});
  }

  scheduleBookReleasePublishingDate(loggedInSpace: string, releaseId: string, payload: {
    scheduleDateTime: Date
  }) {
    let spaceId;
    if (loggedInSpace !== 'personal') {
      spaceId = loggedInSpace;
    }
    const qry = ApiService.buildQuery([{spaceId}]);
    return this.apiService.put(`client/books/releases/{releaseId}/scheduled-publish?${qry}`, {releaseId}, payload);
  }

  deleteBookReleasePublishingDate(loggedInSpace: string, releaseId: string) {
    let spaceId;
    if (loggedInSpace !== 'personal') {
      spaceId = loggedInSpace;
    }
    const qry = ApiService.buildQuery([{spaceId}]);
    return this.apiService.delete(`client/books/releases/{releaseId}/scheduled-publish?${qry}`, {releaseId});
  }

  publishNowBookRelease(loggedInSpace: string, releaseId: string) {
    let spaceId;
    if (loggedInSpace !== 'personal') {
      spaceId = loggedInSpace;
    }
    const qry = ApiService.buildQuery([{spaceId}]);
    return this.apiService.put(`client/books/releases/{releaseId}/publish?${qry}`, {releaseId}, null);
  }

  persistReleaseToGitHub(loggedInSpace: string, bookExternalId: string) {
    let spaceId;
    if (loggedInSpace !== 'personal') {
      spaceId = loggedInSpace;
    }
    const qry = ApiService.buildQuery([{spaceId}]);
    return this.apiService.put(`client/books/{bookExternalId}/persist-content?${qry}`, {bookExternalId}, null);
  }

  getLatestBookVersion(loggedInSpace: string, bookExternalId: string): Observable<{
    sourceBook: BookEntity,
    release: any,
    revision: {
      openCodeUrl: string,
      changes: {
        lastUpdatedAt: string,
        updatedBy: Array<string> | Array<{
          email?: string
        }>
      } & BookEntity
    },
    targetBookId: string,
    releasedAt: string,
    hasAccess: boolean,
    isAlias: boolean
  }> {
    let spaceId;
    if (loggedInSpace !== 'personal') {
      spaceId = loggedInSpace;
    }
    const qry = ApiService.buildQuery([{spaceId}, {unreleased: true}]);
    return this.apiService.get(`client/books/{bookExternalId}/releases/latest?${qry}`, {bookExternalId});
  }

  getUserIsSpaceAdmin(loggedInSpace: string, userId: number) {
    let spaceId;
    if (loggedInSpace !== 'personal') {
      spaceId = loggedInSpace;
    }
    const qry = ApiService.buildQuery([{spaceId}]);
    return this.apiService.get(`client/users/{userId}/space-admin?${qry}`, {userId});
  }

  getIsBookXProductMarketingPage(loggedInSpace: string, bookExternalId: string){
    let spaceId;
    if (loggedInSpace !== 'personal') {
      spaceId = loggedInSpace;
    }
    const qry = ApiService.buildQuery([{spaceId}]);
    return this.apiService.get(`client/modules/marketing-page/{bookExternalId}?${qry}`, {bookExternalId});
  }

  getFlashcards(bookIds: Array<string>): Observable<{
    books: Array<{
      book: BookEntity,
      content: Array<BitApiWrapper>
    }>,
    take: number,
    total: number
  }> {
    return this.apiService.get('client/books/content/flashcards', null, {bookIds});
  }

  translateBook(bookBits: Array<BitApiWrapper>, loggedInSpace: string, bookExternalId: string, language: string, sourceLanguages: Array<string>, targetNotebookId: string, targetNotebookTitle: string, senderId: any) {
    let spaceId;
    if (loggedInSpace !== 'personal') {
      spaceId = loggedInSpace;
    }
    return this.apiService.post(`openai/translate-book`, {bookExternalId}, {
      bookBits,
      language,
      sourceLanguages,
      spaceId,
      targetNotebookId,
      targetNotebookTitle,
      senderId
    });
  }

  translateText(text: string, loggedInSpace: string, language: string, sourceLanguages: Array<string>) {
    return this.apiService.post(`openai/translate-short-text`, null, {text, language, sourceLanguages});
  }

  detectLanguage(text: string) {
    return this.apiService.post(`openai/detect-language-content`, null, {text});
  }

  async generateAiFeedback(language: string, correctAnswers: number, totalAnswers: number): Promise<Response> {
    return this.streamRequest(this.bitmarkConfig.openAiApiUrl + '/openai/feedback/generate', {language, correctAnswers, totalAnswers});
  }

  markBookAsRead(loggedInSpace: string, bookExternalId: string, rating: number) {
    let spaceId;
    if (loggedInSpace !== 'personal') {
      spaceId = loggedInSpace;
    }
    const qry = ApiService.buildQuery([{spaceId}]);
    return this.apiService.put(`client/books/{bookExternalId}/progress/done?${qry}`, {bookExternalId}, {rating});
  }

  resetBookRating(loggedInSpace: string, bookExternalId: string) {
    let spaceId;
    if (loggedInSpace !== 'personal') {
      spaceId = loggedInSpace;
    }
    const qry = ApiService.buildQuery([{spaceId}]);
    return this.apiService.delete(`client/books/{bookExternalId}/progress?${qry}`, {bookExternalId}, {
      progress: 0,
      rating: 0
    });
  }

  getBookProgress(loggedInSpace: string, bookExternalId: string): Observable<{
    rating: number,
    progress: number,
    bookId: number,
    userId: number
  }> {
    let spaceId;
    if (loggedInSpace !== 'personal') {
      spaceId = loggedInSpace;
    }
    const qry = ApiService.buildQuery([{spaceId}]);
    return this.apiService.get(`client/books/{bookExternalId}/progress?${qry}`, {bookExternalId}, null);
  }

  getProgressForBooks(productIds: Array<string>): Observable<Array<any>> {
    const qry = productIds?.join(',');
    return this.apiService.get(`client/books/progress?bookIds=${qry}`, null, null);
  }

  //Begin Hand-ins

  getExpertHandIns(loggedInSpace: string) {
    let spaceId;
    if (loggedInSpace !== 'personal') {
      spaceId = loggedInSpace;
    }
    const qry = ApiService.buildQuery([{spaceId}]);
    return this.apiService.get(`client/hand-ins/expert?${qry}`, null, null);
  }

  getStudentHandIns(loggedInSpace: string) {
    let spaceId;
    if (loggedInSpace !== 'personal') {
      spaceId = loggedInSpace;
    }
    const qry = ApiService.buildQuery([{spaceId}]);
    return this.apiService.get(`client/hand-ins/student?${qry}`, null, null);
  }

  getHandInById(loggedInSpace: string, handInId: number) {
    let spaceId;
    if (loggedInSpace !== 'personal') {
      spaceId = loggedInSpace;
    }
    const qry = ApiService.buildQuery([{spaceId}]);
    return this.apiService.get(`client/hand-ins/{handInId}?${qry}`, {handInId}, null);
  }

  approveHandIn(handInId: number) {
    const qry = '';
    return this.apiService.put(`client/hand-ins/{handInId}/approve?${qry}`, {handInId}, null);
  }

  rejectHandIn(handInId: number) {
    const qry = '';
    return this.apiService.put(`client/hand-ins/{handInId}/reject?${qry}`, {handInId}, null);
  }

  archiveHandIn(handInId: number) {
    return this.apiService.put(`client/hand-ins/{handInId}/archive`, {handInId}, null);
  }

  unarchiveHandin(handInId: number) {
    return this.apiService.put(`client/hand-ins/{handInId}/unarchive`, {handInId}, null);
  }

  markHandInAsVisited(handInId: number) {
    return this.apiService.put(`client/hand-ins/{handInId}/visit`, {handInId}, null);
  }

  createHandIn(loggedInSpace: string, bookExternalId: string) {
    let spaceId;
    if (loggedInSpace !== 'personal') {
      spaceId = loggedInSpace;
    }
    const qry = ApiService.buildQuery([{spaceId}]);
    return this.apiService.post(`client/hand-ins/books/{bookExternalId}?${qry}`, {bookExternalId}, null);
  }

  assignHandIn(loggedInSpace: string, handInId: number, expertEmail: string) {
    let spaceId;
    if (loggedInSpace !== 'personal') {
      spaceId = loggedInSpace;
    }
    const qry = ApiService.buildQuery([{spaceId}]);
    return this.apiService.put(`client/hand-ins/{handInId}/assign?${qry}`, {handInId}, {expertEmail});
  }

  reassignHandIn(loggedInSpace: string, handInId: number, expertEmail: string) {
    let spaceId;
    if (loggedInSpace !== 'personal') {
      spaceId = loggedInSpace;
    }
    const qry = ApiService.buildQuery([{spaceId}]);
    return this.apiService.put(`client/hand-ins/{handInId}?${qry}`, {handInId}, {expertEmail});
  }

  unassignHandIn(loggedInSpace: string, handInId: number) {
    let spaceId;
    if (loggedInSpace !== 'personal') {
      spaceId = loggedInSpace;
    }
    const qry = ApiService.buildQuery([{spaceId}]);
    return this.apiService.put(`client/hand-ins/{handInId}/unassign?${qry}`, {handInId}, null);
  }

  getXModulesBelongingToBook(loggedInSpace: string, bookExternalId: string) {
    let spaceId;
    if (loggedInSpace !== 'personal') {
      spaceId = loggedInSpace;
    }
    const qry = ApiService.buildQuery([{spaceId}]);
    return this.apiService.get(`client/modules/for-book/{bookExternalId}?${qry}`, {bookExternalId}, null);
  }

  //End Hand-ins
}
