import {
  AfterViewInit,
  Component,
  EventEmitter, HostListener,
  Inject,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {ClipboardService} from 'ngx-clipboard';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DeviceDetectorService} from 'ngx-device-detector';
import {DebounceService, DropdownItemModel, ScrollDirective, SubSink} from '../../../../shared';
import {BitType} from '../../../../bits/bits.models';
import {BitmarkFormat, ProductFamily} from '../../../../shared/models/bitmark.models';
import {BitbookApiService} from '../../../bitbook-api.service';
import {ReaderContentService} from '../reader-content.service';
import {BitbookMqService} from '../../../bitbook-mq.service';
import {ReaderTocService} from '../../../reader-toc.service';
import {ReaderBasketService} from '../../../reader-basket.service';
import {ReaderClipboardService} from '../../../reader-clipboard.service';
import {BitmarkConfig} from '../../../../bitmark.module';
import {ReaderContentLearningPathCommonComponent} from '../reader-content-learning-path-common.component';
import {ParserApiService} from '../../../parser-api.service';
import {BrandingRenderService} from '../../../branding-render.service';
import {ReaderTipTapTapService} from '../../../tiptap/reader-tiptap.service';
import {DomUtilsService} from '../../../../shared/dom/dom-utils.service';
import {FileUtilsService} from '../../../../shared/utils/file-utils.service';
import {DomObserverService} from '../../../../shared/dom/dom-observer.service';
import {AnalyticsService} from "../../../../shared/analytics/analytics.service";
import {RolesApiService, TaughtClass} from "../../../roles-api.service";
import StringCaseService from '../../../../shared/utils/string-case.service';
import {ArrayService} from '../../../../shared/utils/array.service';

@Component({
  selector: 'bitmark-reader-content-learning-paths',
  templateUrl: './reader-content-learning-paths.component.html',
  styleUrls: ['../../../reader-common.scss']
})
export class ReaderContentLearningPathsComponent extends ReaderContentLearningPathCommonComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() navigateToProduct = new EventEmitter<{ productId: string, family?: ProductFamily }>();

  BitType = BitType;
  BitmarkFormat = BitmarkFormat;

  bitActions: Array<DropdownItemModel> = [];
  bitEditorActions: Array<DropdownItemModel> = [];
  private sub = new SubSink();

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.debounceService.debounce(500, () => {
      this.readerContentService.computeAndNotifyBitsVisibility(() => this.bitBookContent);
    });
  }

  constructor(@Inject('BitmarkConfig') bitmarkConfig: BitmarkConfig,
              translate: TranslateService,
              bitBookApiService: BitbookApiService,
              readerContentService: ReaderContentService,
              bitbookMqService: BitbookMqService,
              readerTocService: ReaderTocService,
              readerBasketService: ReaderBasketService,
              readerClipboard: ReaderClipboardService,
              clipboardService: ClipboardService,
              basketSvc: ReaderBasketService,
              parserApiService: ParserApiService,
              router: Router,
              private route: ActivatedRoute,
              location: Location,
              brandingRenderService: BrandingRenderService,
              readerTipTapService: ReaderTipTapTapService,
              ngbModal: NgbModal,
              scrollDirective: ScrollDirective,
              domUtilsService: DomUtilsService,
              domObserverService: DomObserverService,
              fileUtilsService: FileUtilsService,
              deviceDetectorService: DeviceDetectorService,
              analyticsService: AnalyticsService,
              rolesApiService: RolesApiService,
              stringCaseService: StringCaseService,
              arrayService: ArrayService,
              private debounceService: DebounceService) {
    super(bitmarkConfig,
      bitBookApiService,
      readerContentService,
      bitbookMqService,
      readerTocService,
      readerBasketService,
      readerClipboard,
      clipboardService,
      basketSvc,
      translate,
      parserApiService,
      router,
      location,
      brandingRenderService,
      readerTipTapService,
      ngbModal,
      scrollDirective,
      domUtilsService,
      domObserverService,
      fileUtilsService,
      deviceDetectorService,
      analyticsService,
      rolesApiService,
      stringCaseService,
      arrayService);
  }

  ngOnInit() {
    const menuActions= [{
      label: this.translate.instant('Reader.Actions.CopyAllBitsToBasket'),
      handler: this.copyAllBitsToBasket.bind(this)
    }];
    this.bitbookMqService.notifyReaderMenuReady(this.bitmarkConfig.isProUser
      ? [{
        label: this.translate.instant('Edit'),
        handler: this.openEditBookEditor.bind(this),
      }, ...menuActions]
      : menuActions);

    this.rolesApiService.getTaughtClasses()
      .subscribe((classes: Array<TaughtClass>) => {
        this.bitActions = [{
          label: this.translate.instant('Reader.Basket.AddToBasket'),
          handler: this.addToBasket.bind(this)
        }, {
          label: this.translate.instant('Reader.Actions.SendContinue'),
          handler: this.sendBit.bind(this)
        }, classes?.length ? {
          label: this.translate.instant('Reader.Actions.SendToClass'),
          handler: this.sendBitToClass.bind(this)
        } : null, {
          label: this.translate.instant('Reader.Actions.SaveToAnotherNotebook'),
          handler: this.saveBit.bind(this)
        }, {
          id: 'edit-bit-data-separator',
          isSeparator: true,
          isHidden: !this.bitmarkConfig.isProUser
        }, {
          isHidden: !this.bitmarkConfig.isProUser,
          label: 'Reader.Actions.EditBitmarkType',
          handler: this.openEditBitEditor.bind(this),
        }, {
          id: 'show-hidden-fields',
          label: this.translate.instant('Shared.ShowHiddenFields'),
          handler: this.showHiddenFields.bind(this)
        }, {
          id: 'hide-hidden-fields',
          label: this.translate.instant('Shared.HideHiddenFields'),
          handler: this.hideHiddenFields.bind(this)
        }, {
          label: this.translate.instant('Reader.Actions.CopyBitmarkJSON'),
          isHidden: !this.bitmarkConfig.isProUser,
          handler: this.copyBitmarkJsonToClipboard.bind(this)
        }, {
          label: 'Reader.Actions.BrandingPublisherTheme',
          isHidden: !this.bitmarkConfig.isProUser,
          isDisabled: true
        }, {
          isSeparator: true
        }, {
          label: this.translate.instant('Shared.Copy'),
          handler: this.copyBitToClipboard.bind(this)
        }, {
          label: this.translate.instant('Shared.Cut'),
          handler: this.cutBitToClipboard.bind(this)
        }, {
          label: this.translate.instant('Reader.Actions.CopyLinkToBit'),
          handler: this.copyBitLink.bind(this)
        }, {
          id: 'find-in-book',
          label: this.translate.instant('Reader.Actions.FindInBook'),
          handler: this.findBitInBook.bind(this)
        }, {isSeparator: true}, {
          label: this.translate.instant('Shared.Delete'),
          handler: this.deleteCourseBit.bind(this)
        }].filter(x => !!x);
      });

    this.bitEditorActions = [{
      label: this.translate.instant('Reader.Actions.AddChapter'),
      handler: this.createLPChapterAfterBit.bind(this)
    }, {
      label: this.translate.instant('Reader.Actions.AddBit'),
      isHidden: !this.bitmarkConfig.isProUser,
      handler: this.openBitEditor.bind(this)
    }, {
      id: 'branding-placeholder',
      isHidden: true
    }, {
      isSeparator: true
    }, {
      label: this.translate.instant('Shared.Paste'),
      handler: this.pasteFromClipboard.bind(this)
    }, {
      label: this.translate.instant('Reader.Actions.InsertBasket'),
      handler: this.insertCourseBasket.bind(this)
    }];

    this.sub.sink = this.bitbookMqService.onReaderBitSelected()
      .subscribe(this.handleBitSelected.bind(this));
    this.sub.sink = this.bitbookMqService.onReaderRefreshNotebook()
      .subscribe(this.refreshNotebookIfNeeded.bind(this));
    this.sub.sink = this.bitbookMqService.onBitsVisibility()
      .subscribe(this.handleBitsVisibilityChanged.bind(this));

    if (this.bitmarkConfig?.editorMenusFromTheme?.enabled) {
      this.populateAddBitsMenu(this.courseId);
    }

    this.readerContentQueryType = 'learning-path-untracked';
    this.renderPage(this.route.snapshot.fragment);
  }

  ngAfterViewInit() {
    this.initializeUserCopyProtection();
  }

  ngOnDestroy() {
    if (this.scrollEndUnlisten) {
      this.scrollEndUnlisten();
    }
    this.sub.unsubscribe();
  }
}
