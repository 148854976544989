export const translation = {
  'ToC': {
    'cover': 'Anfang',
    'final': 'Ende'
  },
  'Auth': {
    'LogOut': 'Ausloggen',
    'Login': 'Einloggen',
    'Register': 'Konto erstellen',
    'Settings': 'Einstellungen',
    'AlreadyHaveAnAccount': 'Konto bereits vorhanden?',
    'Email': 'E-Mail',
    'Password': 'Passwort',
    'ShowPassword': 'Passwort anzeigen',
    'ResetPassword': 'Passwort zurücksetzen',
    'HidePassword': 'Passwort ausblenden',
    'LostPassword': 'Passwort vergessen',
    'SignInWith': 'Einloggen mit {{socialMedia}}',
    'GoToLogin': 'Erneut anmelden...',
    'UseAnotherAccount': 'Anderes Konto verwenden...'
  },
  'Meta': {
    'ContentNotAvailable': 'Dieser Inhalt ist aktuell nicht verfügbar',
    'NewVersionAvailable': 'Neue Version verfügbar',
    'PleaseUpgrade': 'Bitte auf Version {{newVersion}} aktualisieren'
  },
  'Menu': {
    'Notebooks': 'Workspace',
    'Books': 'My Library',
    'BookShop': 'Academy'
  },
  'Contact': {
    'ContactUs': 'Kontakt',
    'Support': 'Support',
    'UpgradeAndLogout': 'Aktualisieren und neu anmelden',
    'ClearCache': 'Cache leeren'
  },
  'Search': {
    'ShowOnlyFreeTitles': 'Nur kostenlose Bücher',
    'SearchAllBooks': 'Alle Bücher',
    'SearchBooks': 'Bücher suchen',
    'ContentForAllBooks': 'Inhalt aller Bücher',
    'SearchAllBits': 'Alle Inhalte',
    'SearchNotebook': 'Notizbuch-Suche',
    'SearchBook': 'Buch suchen',
    'SearchChapter': 'Kapitel suchen',
    'BooksFor': 'Bücher nach «{{searchedText}}» durchsucht',
    'ResultsByPublisher': 'Suchresultate für Verlag«{{searchedText}}»',
    'ResultsByAuthor': 'Suchresultate für AutorIn «{{searchedText}}»',
    'BitsFor': 'Bits für «{{searchedText}}»',
    'PaginationOutOf': '{{begin}} - {{end}} von {{total}}',
    'Previous': 'Zurück',
    'Next': 'Weiter',
    'LastSearched': 'Letzte Suche',
    'LastViewed': 'Zuletzt angesehen',
    'NoBooksFound': 'Keine Bücher gefunden',
    'NoBitsFound': 'Keine Bits gefunden',
    'BooksNotFound': 'Bücher nicht gefunden',
    'BitsNotFound': 'Bits nicht gefunden'
  },
  'Profile': {
    'Title': 'Profil',
    'FirstName': 'Vorname',
    'LastName': 'Nachname',
    'Country': 'Land',
    'Language': 'Sprache',
    'PrivateEmail': 'E-Mail Privat',
    'MobilePhone': 'Mobiltelefon',
    'DoubleChecking': 'Nur um sicher zu gehen!',
    'ChangePassword': 'Passwort ändern',
    'ResetPassword': 'Passwort zurücksetzen',
    'YourOldPassword': 'Altes Passwort',
    'NewPassword': 'Neues Passwort',
    'ChangePasswordSuccess': 'Das Passwort wurde erfolgreich geändert!',
    'ChangePasswordFail': 'Das Passwort konnte nicht geändert werden!',
    'AddPhoto': 'Foto hinzufügen',
    'RemovePhoto': 'Foto entfernen',
    'SavePhoto': 'Foto speichern',
    'ChoosePhoto': 'Foto auswählen oder drauf ziehen.',
    'UpdatePhotoFail': 'Foto konnte nicht aktualisiert werden.',
    'EmailEmptyError': 'E-Mail ist ein Pflichtfeld!',
    'PasswordEmptyError': 'Das neue Passwort darf nicht leer sein!',
    'ImageTooLarge': 'Bild zu gross. Max. 3MB',
    'ValidationMessage': 'Eine Validierungs-E-Mail wurde erfolgreich an die angegebene Adresse verschickt.',
    'YourEmailAddress': 'Ihre E-Mail Adresse',
    'HasBeenVerified': 'wurde erfolgreich validiert.',
    'ResendVerificationMailTo': 'Validierungsmail erneut senden an',
    'EmailNotVerified': 'Deine E-Mail ist nicht verifiziert worden'
  },
  'Timeline': {
    'Available': 'Verfügbar',
    'NotAvailable': 'Nicht verfügbar',
    'Reply': 'Antworten',
    'Today': 'Heute',
    'ConnectTo': 'Verbinden mit ...',
    'EmailPeer': 'E-Mails (eines existiernden Get More Brain users)',
    'UserNotFound': 'User nicht gefunden!',
    'NewMessages': 'Neue Nachricht',
    'NewMessage': 'Neue Nachricht',
    'TypeAMessage': 'Nachricht erfassen...',
    'ConnectMessage': 'Hallo, danke für\'s Verbinden',
    'MarkAllAsRead': 'Alle Nachrichten als gelesen markieren',
    'DeleteChat': 'Chat löschen',
    'DeleteChatConfirm': 'Wollen Sie diesen Chat wirklich löschen?',
    'BlockUser': 'User blockieren',
    'BlockUserConfirm': 'Möchten Sie diesen User wirklich blockieren?',
    'UnblockUser': 'User freigeben',
    'UnblockUserConfirm': 'Möchten Sie diesen User wirklich freigeben?',
    'ConnectionRequest': 'Verbindungsanfrage',
    'CreateEntry': {
      'AddMultiQuizContinue': 'MultiQuiz hinzufügen...',
      'AddBlankQuiz': 'Leeres Quiz hinzufügen',
      'OpenLinkInBrowser': 'Link im Browser öffnen',
      'CopyLink': 'Link kopieren',
      'AddHintContinue': 'Hinweis erstellen...',
      'EditLinkContinue': 'Link bearbeiten...',
      'EditHintContinue': 'Hinweis bearbeiten...',
      'RemoveHint': 'Hinweis entfernen',
      'Unlink': 'Link entfernen',
      'Video': 'Video',
      'TakePicture': 'Bild- oder Foto-Bibliothek',
      'PhotoLibrary': 'Foto-Bibliothek',
      'Audio': 'Audio',
      'Document': 'Dokument',
      'Emoji': 'Emoji'
    },
    'Actions': {
      'YouDeletedThisMessage': 'Nachricht gelöscht',
      'ThisMessageWasDeleted': 'Gelöschte Nachricht',
      'ThisMessageIsHidden': 'Ausgeblendete Nachricht',
      'HideSolution': 'Lösung ausblenden',
      'RevealSolution': 'Lösung anzeigen',
      'Verify': 'Überprüfen',
      'Repeat': 'Wiederholen',
      'TextContinue': 'Text...',
      'HrefContinue': 'Href...',
      'HintContinue': 'Hint...',
      'PasteVideoLink': 'Video Link einfügen',
      'BrowserDoesNotSupportAudio': 'Dieser Browser unterstützt keine Audiodateien.',
      'BrowserDoesNotSupportVideo': 'Dieser Browser unterstützt kein Video.',
      'ChooseDocument': 'Dokument auswählen oder rein ziehen',
      'ChooseAudio': 'Audiodatei auswählen oder rein ziehen',
      'ErrorConnecting': 'Fehler beim Verbinden mit {{email}}',
      'UserNotFound': 'Benutzer {{email}} nicht gefunden',
      'PasteAndSendBit': 'Bit einfügen und senden',
      'SendBasket': 'Basket senden',
      'ErrorUploadingPdf': 'We could not convert your pdf. The problem was logged. We are working on a solution to convert more complex pdf\'s in the future.'
    },
    'Quiz': {
      'CannotSend': 'de-You cannot send the quiz now.',
      'CannotSendWithoutGap': 'de-You cannot send a quiz without any gap.'
    },
    'SearchAll': 'Alle Benutzer'
  },
  'Notebooks': {
    'UntitledNotebook': 'Unbenanntes Notizbuch',
    'UntitledDraftBook': 'Unbenanntes buch',
    'UntitledFlashcards': 'Unbenanntes Lernkarten',
    'Title': 'Workspace',
    'PersonalNotebooks': 'Persönliche Notizbücher',
    'Homework': 'Hausaufgaben',
    'SmartNotebooks': 'Smarte Notizbücher',
    'DeleteConfirm': 'Notizbuch «{{notebook}}» wirklich löschen? Rückgängig machen ist nicht möglich!',
    'Notebook': 'Notizbuch',
    'Worksheet': 'Arbeitsblatt',
    'Flashcards': 'Lernkarten',
    'RecentQuizzes': 'Letzte Quizzes',
    'RecentVideos': 'Letzte Videos',
    'LearningPath': 'Lernpfad',
    'DraftBook': 'Buchentwurf',
    'DropFileHere': 'Datei hier ablegen',
    'DropFileAllowedTypes': 'Sie dürfen nur PDFs oder bitmark-Dateien ablegen'
  },
  'HandIn': {
    'Archive': 'Archivieren',
    'Unarchive': 'Dearchivieren',
    'Today': 'heute',
    'Yesterday': 'gestern',
    'Assign': 'Zuweisen...',
    'Unassign': 'Zuweisung entfernen',
    'AssignToMe': 'Mir zuweisen',
    'YourHandInApproved': 'Ihre Einreichung wurde genehmigt',
    'YourHandInRejected':'Ihre Einreichung wurde abgelehnt',
    'PleaseReadComments': 'Bitte lesen Sie die Kommentare sorgfältig durch und versuchen Sie es erneut.',
    'BookWasHandedIn': 'Buch wurde eingereicht',
    'Locked': 'Gesperrt',
    'By': 'von',
    'YouHaveApprovedThis': 'Sie haben dies genehmigt',
    'For':'für',
    'YouHaveRejectedThis': 'Sie haben dies abgelehnt',
    'PleaseHandInTheBookReady':'Bitte reichen Sie das Buch ein, wenn Sie bereit sind.',
    'PleaseHandInTheBookUntil': 'Bitte reichen Sie das Buch bis zum {Annahmeschluss}.',
    'YourBookWillBeLocked': 'Ihr Buch wird gesperrt (nur Ansicht) 🔒 nach dem Einreichen.',
    'BookWasHandedInSuccessfully': 'Das Buch wurde erfolgreich eingereicht.',
    'YourWorkOnThisBookWasApproved': 'Ihre Arbeit wurde genehmigt!',
    'ThereWasAProblemHandingIn': 'Es gab ein Problem beim Einreichen des Buches',
    'ThereWasAnErrorAccepting': 'Es gab einen Fehler beim Annehmen der Einreichung',
    'ThereWasAnErrorAssigning': 'Es gab einen Fehler bei der Zuweisung der Einreichung',
    'ErrorNoExperts':'Es gibt keine zuzuordnenden Experten',
    'NoNewHandIns':'Keine neuen Einreichungen',
    'NoAssignedHandIns': 'Keine zugewiesenen Einreichungen',
    'NoHandIns':'Keine Einreichungen',
    'NewHandIns':'Nicht zugewiesene',
    'AssignedToMe':'Meine Bewertungen',
    'All':'Genehmigt'
  },
  'Books': {
    'MyBooks': 'Bücher',
    'Books': 'Inhalte',
    'Courses': 'Kurse',
    'MyClasses': 'Meine Klassen',
    'RecentlyUsed': 'Kürzlich genutzt',
    'SelfLearning': 'Selbstbestimmtes Lernen',
    'Purchased': 'Gekauft',
    'All': 'Alle',
    'Unbuy': 'Rückgängig',
    'UnbuyTraining': 'Kurs abbrechen',
    'ChatWithBook': 'Mit Buch chatten',
    'Publisher': 'Verlag',
    'Authors': 'Autor',
    'Published': 'Publiziert',
    'Updated': 'Aktualisiert',
    'Language': 'Sprache',
    'ISBN': 'ISBN',
    'GetFreeBooksFromShop': 'Kostenlose und kostenpflichtige Bücher im Shop',
    'CostsCoveredBy': 'Kosten durch {{company}} gedeckt',
    'Pages': 'Seiten',
    'AlsoAvailableIn': 'Ebenfalls erhältlich auf',
    'Series': 'Serie «{{seriesName}}»',
    'CurrentSeries': 'CurrentSeries',
    'OnlyPaper': 'Buch nur auf Papier verfügbar.',
    'VoteMakeBookAvailable': 'Stimme für eine digitale Version',
    'DigitalMaterial': 'Aber wir haben tolle, digitale Inhalte zu diesem Buch.',
    'OneVoteToMakeAvailable': '{{numberOfVotes}} Stimme für eine digitale, interaktive Version.',
    'VotesToMakeAvailable': '{{numberOfVotes}} Stimmen für eine digitale, interaktive Version.',
    'Types': {
      'Flashcards': 'Lernkarten',
      'PPT': 'PPT',
      'Word': 'Word',
      'Excel': 'Excel',
      'PDF': 'PDF',
      'Script': 'Skript',
      'Quizzes': 'Quiz',
      'Video': 'Video',
      'VideoLecture': 'Video Vortrag',
      'LTIContainer': 'LTI Container',
      'WBT': 'WBT',
      'Audio': 'Audio',
      'LiveBook': 'Live Book',
      'Homework': 'Hausaufgaben'
    }
  },
  'Shared': {
    'BroadcastSubtitle': 'Broadcast an alle Studenten',
    'Join': 'Teilnehmen',
    'Edit': 'Bearbeiten',
    'Edited': 'Bearbeitet',
    'EditContinue': 'Bearbeiten...',
    'SetContinue': 'Setzen...',
    'Remove': 'Entfernen',
    'Cancel': 'Abbrechen',
    'OK': 'OK',
    'Search': 'Suche',
    'SearchStudents': 'Suchen Sie nach Studenten',
    'Open': 'Öffnen',
    'OpenContinue': 'Öffnen...',
    'Download': 'Download',
    'Share': 'Teilen',
    'Send': 'Senden',
    'Show': 'Anzeigen',
    'ShowHiddenFields': 'Alle Eingabefelder anzeigen...',
    'HideHiddenFields': 'Alle Eingabefelder ausblenden',
    'SendContinue': 'Senden...',
    'Sending': 'Senden',
    'SendingContinue': 'Senden...',
    'Rename': 'Umbenennen',
    'Replace': 'Ersetzen',
    'ReplaceContinue': 'Ersetzen...',
    'Delete': 'Löschen',
    'DeleteContinue': 'Löschen...',
    'DeletePermanentlyContinue': 'Dauerhaft löschen...',
    'DeleteImmediately': 'Sofort löschen',
    'Save': 'Speichern',
    'Sell': 'Verkaufen',
    'For': 'für',
    'Hide': 'Ausblenden',
    'Free': 'Kostenlos',
    'Buy': 'Kaufen',
    'Unbuy': 'Entkaufen',
    'Here': 'Hier',
    'Continue': 'Weiter',
    'Name': 'Name',
    'Favorites': 'Favorites',
    'Copy': 'Kopieren',
    'Paste': 'Einfügen',
    'PasteSpecial': 'Einfügen spezial...',
    'Cut': 'Ausschneiden',
    'Undo': 'Wiederrufen',
    'Accept': 'Akzeptieren',
    'Reject': 'Ablehnen',
    'Revoke': 'Widerrufen',
    'SentOn': 'Gesendet am',
    'RejectedOn': 'Abgelehnt am',
    'RevokedOn': 'Widerrufen am',
    'UploadPDF': 'PDF hochladen',
    'Duplicate': 'Duplizieren',
    'Page': 'Seite',
    'MarkAsDone': 'Als erledigt markieren',
    'RateAndMarkAsDone': 'Rate and Mark as Done ✅',
    'HandIn': 'Einreichen',
    'Choose': 'Auswählen',
    'ChooseContinue': 'Auswählen...',
    'CloseConfirm': 'de-Are you sure to want to close?',
    'CloseModalConfirm': 'de-Are you sure to want to close the modal?',
    'Retry': 'Retry',
    'True': 'Richtig',
    'False': 'Falsch',
    'Start': 'Starten',
    'ChooseBook': 'Buch wählen',
    'ChooseChapter': 'Kapitel wählen',
    'Book': 'Buch',
    'Chapter': 'Kapitel',
    'Video': 'Video',
    'CameraContinue': 'Kamera...',
    'UploadContinue': 'Hochladen...',
    'EmbedVideoContinue': 'Einbetten (Youtube, Vimeo)...',
    'Audio': 'Audio',
    'Voice': 'Sprachnachricht',
    'Image': 'Bild',
    'UrlContinue': 'URL...',
    'Documents': 'Dokumente',
    'LinkContinue': 'Link...',
    'Website': 'Website',
    'ManageContinue': 'Organize...',
    'Caption': 'Untertitel',
    'Attempts': 'Versuche',
    'Close': 'Schließen',
    'AddToCalendar': 'Zum Kalender hinzufügen',
    'ReplaceImageContinue': 'Bild Ersetzen...'
  },
  'Shop': {
    'Price': 'Preis',
    'TotalPrice': 'Gesamtpreis',
    'YouAreBuyingFor': 'Sie kaufen für',
    'Shop': 'Academy',
    'PurchaseSuccessful': 'Kauf erfolgreich!',
    'AddedSuccessfully': 'Erfolgreich hinzugefügt!',
    'PurchaseFailed': 'Kauf fehlgeschlagen!',
    'BookNotFound': 'Buch nicht gefunden.',
    'AllBooksNext': 'Alle Bücher »',
    'SubCategories': 'Unter-Kategorien',
    'MoreInfo': 'Details...',
    'MoreInfoContinue': 'Mehr info...',
    'ThisIsAdditional': 'Dies ist ein zusätzlicher Inhalt für',
    'SupportingMaterial': 'Supporting Material',
    'ThanksForJoining': 'Vielen Dank für Ihre Teilnahme!',
    'Publish': 'Veröffentlichen',
    'EditContinue': 'Editieren...',
    'EditLP': 'Learning Path...',
    'InvitedBy': 'Sie sind von {{user}} eingeladen worden',
    'InvitationFor': 'Einladung für',
    'GetAnotherCopy': 'Eine weitere Kopie anlegen',
    'Editor': {
      'CopyEmailAddress': 'Email kopieren',
      'Chat': 'Message senden',
      'SendEmail': 'Email senden...',
      'AddFlag': 'Flag setzen',
      'CopyAll': 'Alle kopieren',
      'ReplaceAll': 'Alle ersetzen...',
      'AddSeveral': 'Weitere hinzufügen...',
      'ResendInvitation': 'Einladung erneut senden ✉️',
      'ByInvitationOnly': 'Dieser Kurs ist nur auf Einladung zugänglich (er kann nicht gekauft oder beigetreten werden)',
      'ClosedCourse': 'Geschlossener Kurs - Teilnehmende werden durch Organizer verwaltet',
      'Content': 'Inhalt',
      'ShopPage': 'Shop Seite',
      'CustomShopPage': 'Individuelle Shop Seite',
      'NewRelease': 'Neuer Release',
      'InviteUsersToBuy': 'User zum Kauf einladen...',
      'InviteUsersToJoin': 'User zum Beitreten einladen...',
      'BuyForUsers': 'Für Users kaufen......',
      'JoinForUsers': 'User als Teilnehmer hinzufügen...',
      'BuyForUser': 'Für User kaufen......',
      'JoinForUser': 'Benutzer beitreten...',
      'DeleteAll': 'Alle löschen',
      'SortByEmail': 'nach Email sortiert',
      'SortByFirstName': 'nach Vorname sortiert',
      'SortByLastName': 'nach Nachname sortiert',
      'SendReleaseNotes': 'Versionshinweise senden'
    },
    'Course': {
      "Description": "Beschreibung",
      'Content': 'Inhalt',
      'UpcomingDates': 'Kurse',
      'CourseContent': 'Kurs Inhalt',
      'CourseLessons': 'Lektionen',
      'CourseStartDate': 'Start',
      'CourseBooks': 'Kurs Inhalte',
      'TheCurriculum': 'Curriculum',
      'BuyForAll': 'Für Teilnehmer kaufen',
      'UpdateInvitations': 'Einladungen aktualisieren',
      'Now': 'Aktuell',
      'Next': 'Demnächst',
      'Done': 'Erledigt',
      'Participants': 'Teilnehmende',
      'Organizers': 'Organisatoren',
      'Coaches': 'Coaches',
      'Editors': 'Herausgeber',
      'InvitationsUpdated': 'Die Einladungen wurden für alle Teilnehmer aktualisiert!',
      'BoughtForParticipants': 'Alle Teilnehmer sind dem Kurs beigetreten.',
      'CopyLink': 'Link kopieren',
      'Duration': 'Dauer',
      'Effort': 'Aufwand',
      'Format': 'Format',
      'ClassSize': 'Klassengrösse',
      'Career': 'Karriere',
      'Tuition': 'Studiengebühren',
      'MoveUp': 'Move up',
      'MoveDown': 'Move down',
      'HandInFolder': 'Hand-in-Mappe für Expertenfeedback',
      'Experts': 'Experten',
      'Modules': 'The Modules',
      'Starts': 'Starts',
      'Ends': 'Ends',
      'ViewDetails': 'View Details'
    }
  },
  'Bits': {
    'With': 'An',
    'SuccessfullySent': 'Erfolgreich versendet',
    'ToUser': 'an {{userName}}',
    'Recent': 'Letzte',
    'ModuleDone': 'Modul erledigt',
    'HadRead': 'gelesen',
    'SendAt': 'Senden am',
    'Item': 'Nummer',
    'Lead': 'Lead',
    'Location': 'Ort',
    'Instruction': 'Anweisung',
    'Action': 'Aufgabe',
    'Duration': 'Dauer',
    'From': 'Von',
    'To': 'Bis',
    'Quiz': 'de-Quiz',
    'PlaceholderBody': '...',
    'NoResourceSelected': 'Keine Ressource ausgewählt',
    'GetFeedback': 'Auflösen',
    'DoItAgain': 'Noch einmal machen',
    'FinalFeedback': 'Abschließendes Feedback',
    'FeedbackRetryOrSubmitMessage': 'Du hast {{correct}} von {{total}} Antworten richtig beantwortet.',
    'Generate': 'Generieren'
  },
  'Reader': {
    'ShowAll': 'Alle anzeigen',
    'CreateNewNotebook': 'Neues «{{untitledNotebook}}» erstellen',
    'Search': {
      'Search': 'Suchen...',
      'LastSearched': 'Letzte Suche',
      'NoResultsFound': 'Nichts gefunden.'
    },
    'Editor': {
      'NewChapter': 'Neues Kapitel',
      'Subtitle': 'Untertitel',
      'Title': 'Titel',
      'Text': 'Text',
      'Code': 'Code',
      'Math': 'Mathe',
      'Link': 'Link',
      'Quote': 'Zitat',
      'ScientificQuote': 'Wissenschaftliches Zitat',
      'HistoricQuote': 'Historisches Zitat',
      'List': 'Liste',
      'NumberList': 'Nummerierte Liste',
      'LetterList': 'Alphabetische Liste',
      'RomanList': 'Römische Liste',
      'TaskList': 'Aufgabenliste',
      'NoBullets': 'Keine Bullets',
      'AddNote': 'Notiz hinzufügen',
      'AddHandwritten': 'Scribble hinzufügen',
      'AddQuickNote': 'Kurznotiz hinzufügen',
      'TypeSomething': 'Text eingeben ...',
      'InvalidBitmark': 'bitmark ungültig',
      'ImageLeftAligned': 'Linksbündig',
      'ImageRightAligned': 'Rechtsbündig',
      'ImageCenterAligned': 'Zentriert'
    },
    'Handwritten': {
      'WaitingForImage': 'Warten auf Bild',
      'ErrorLoadingImage': 'Fehler beim Laden des Bilds',
      'LoadingImage': 'Vorschau wird erstellt',
      'PreparingImage': 'Bild wird geladen',
      'ColorPalette': 'Farbpalette',
      'ExtraSmall': 'Extraklein',
      'Small': 'Klein',
      'MediumSmall': 'Mittelklein',
      'Medium': 'Mittel',
      'MediumLarge': 'Mittelgroß',
      'Large': 'Groß',
      'ExtraLarge': 'Extragroß',
    },
    'Filters': {
      'Quiz': 'Inhalte',
      'Resources': 'Ressourcen',
      'Grade': 'Klasse',
      'Complexity': 'Komplexität',
      'Tags': 'Hashtag',
      'Skills': 'Fähigkeiten'
    },
    'Actions': {
      'Send': 'Senden',
      'SendContinue': 'Senden...',
      'SendToClass': 'Broadcast an alle Studenten...',
      'Broadcast': 'Broadcast',
      'SendBook': 'Buch senden',
      'SendAll': 'Alle senden',
      'DeleteBit': 'Bit löschen',
      'DeleteAll': 'Alle löschen',
      'SaveToNotebook': 'In Notizbuch speichern...',
      'SaveToAnotherNotebook': 'In einem anderen Notizbuch speichern...',
      'ResetBookRating': 'Reset Book Rating',
      'SaveAllToNotebook': 'Alles in einem Notizbuch speichern',
      'FindInBook': 'Im Buch anzeigen',
      'SuccessfullyStored': 'Speichern erfolgreich!',
      'SavingTo': 'Speichern in «{{notebookName}}»',
      'SuccessfullyStoredToNotebook': 'Erfolgreich im «{{notebookName}}» gespeichert',
      'ErrorOcurredStoring': 'Es ist ein Fehler aufgetreten beim Speichern in «{{notebookName}}»',
      'CopyBitmarkType': 'bitmark {{bitmarkType}} kopieren',
      'EditBitmarkType': 'bitmark {{bitmarkType}} bearbeiten',
      'BrandingPublisherTheme': 'Herausgeber {{publisherName}} {{newline}} {{publisherId}}/{{theme}}',
      'ExtractText': 'Copy Extracted Text',
      'CopyBitmarkJSON': 'bitmark JSON kopieren',
      'CopyLinkToBit': 'Link zu diesem Bit kopieren',
      'CopyAllBitsToBasket': 'Alle Bits in den Basket kopieren',
      'ResetAnswer': 'Antwort zurücksetzen',
      'ResetHighlights': 'Hervorhebung entfernen',
      'JoinCall': 'Anruf beitreten',
      'Add': 'Hinzufügen',
      'AddChapter': 'Kapitel hinzufügen',
      'AddCard': 'Karte hinzufügen',
      'UploadCards': 'Karten hochladen...',
      'PasteQuizletCards': 'Lernkarten aus Quizlet einfügen (TSV)',
      'PasteStepsScribePro': 'Schritte aus Scribe Pro einfügen (Markdown)',
      'AddBit': 'Bit hinzufügen',
      'AddLearningPathLTI': 'Lernpfad LTI hinzufügen',
      'AddLearningPath': 'Lernpfadschritt hinzufügen',
      'EditLearningPathContinue': 'Lernpfad bearbeiten...',
      'LearningPathGoal': 'Lernziele',
      'LearningPathReadBook': 'Buch lesen',
      'LearningPathStep': 'Lernschritt',
      'LearningPathVideoCall': 'Videoanruf',
      'LearningPathExternalLink': 'Externer Link',
      'LearningPathLTI': 'LTI-Ressource',
      'LearningPathClassroomEvent': 'Unterrichtsveranstaltung',
      'LearningPathClosing': 'Abschluss',
      'AddBotMessage': 'Bot-Nachricht hinzufügen',
      'AddBotAction': 'Bot-Aktion hinzufügen',
      'BotActionSend': 'Nachricht senden',
      'AddNote': 'Notiz hinzufügen',
      'AddHandwritten': 'Scribble hinzufügen',
      'AddBookmark': 'Bookmark hinzufügen',
      'AddFavorite': 'Favorit hinzufügen',
      'AddArticle': 'Text hinzufügen',
      'AddQuote': 'Zitat hinzufügen',
      'ExampleNotes': 'Beispiel Notiz',
      'InsertBasket': 'Basket einfügen',
      'SetCover': 'Individuelle Cover hochladen...',
      'RemoveCover': 'Individuelle Cover entfernen',
      'CoverStyle': 'Cover-Stil',
      'DefaultCover': 'Bild auf dem Cover',
      'FullCover': 'Bild füllt das Cover',
      'CustomCover': 'Individuelle Cover',
      'ErrorSetCover': 'Dieses Bild ist zu klein. Bitte stellen Sie ein Bild bereit, das mindestens 700 Pixel breit oder 1000 Pixel hoch ist.',
      'AddAuthorNote': 'Autorennotiz hinzufügen',
      'AddReviewerNote': 'Rezensentennotiz hinzufügen',
      'AddInfo': 'Informationen hinzufügen',
      'AddSideNote': 'Randnotiz hinzufügen',
      'AddRemark': 'Anmerkung hinzufügen',
      'AddExample': 'Beispiel hinzufügen'
    },
    'Basket': {
      'Basket': 'Basket',
      'EmptyBasket': 'Basket leeren',
      'AddToBasket': 'In den Basket legen',
      'RemoveFromBasket': 'Aus dem Basket entfernen',
      'SendAll': 'Alles senden',
      'SaveAll': 'Alles speichern'
    },
    'Bin': {
      'RestoreAll': 'Alles zurückgeben',
      'EmptyBin': 'Leeren Sie den Behälter',
      'Restore': 'Zurücklegen',
      'MoveToBin': 'In den Papierkorb verschieben',
      'DeleteBitConfirmation': 'Sind Sie sicher, dass Sie dieses Bit löschen möchten?'
    },
    'Bits': {
      'Level': 'Level'
    },
    'Translate': {
      'Translate': 'Übersetzen',
      'TranslateTitle': 'Übersetzen...',
      'ChooseLanguage': 'Sprache wählen',
      'SearchLanguages': 'Suchsprachen',
      'RecentLanguages': 'Aktuelle Sprachen',
      'AllLanguages': 'Alle Sprachen'
    },
    'LearningEvents': {
      'ThisWeek': 'Diese Woche',
      'Next30Days': 'Nächste 30 Tage',
      'AllUpcoming': 'Alle kommenden',
      'Past': 'Vergangen'
    }
  },
  'System': {
    'SystemTitle': 'System',
    'Bin': 'Papierkorb'
  },
  'Cockpit': {
    'AverageCompletedActivities': 'Durchschnittlich abgeschlossene Aktivitäten',
    'AverageCorrectAnswers': 'Durchschnittlich richtige Antworten',
    'NoDataYet': 'Noch keine Daten'
  }
};

