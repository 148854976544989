// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-box-peer__label {
  flex-grow: 1;
  overflow: hidden;
  max-height: 50px;
  width: 100%;
}
.user-box-peer__photo {
  flex-shrink: 0;
}

.sm .user-box-peer__photo {
  height: 25px;
  width: 25px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
