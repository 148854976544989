// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bit-checkbox input[type=checkbox] {
  opacity: 0;
  position: absolute;
}
.bit-checkbox input[type=checkbox] + label::after {
  content: none;
}
.bit-checkbox input[type=checkbox]:checked + label::after {
  content: var(--bitmark-learning-event-checkbox-checked-content-string, var(--bitmark-checkbox-checked-content-string)) !important;
}
.bit-checkbox label {
  margin: 0;
  padding: 0;
  position: relative;
  word-break: break-word;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}
.bit-checkbox label bitmark-text {
  padding-left: 10px;
  max-width: calc(100% - var(--bitmark-learning-event-checkbox-size-width, 30px));
}
.bit-checkbox label span {
  padding-left: 10px;
}
.bit-checkbox label::before {
  position: static;
  content: "";
  align-self: flex-start;
  width: var(--bitmark-learning-event-checkbox-size-width, var(--bitmark-checkbox-size-width, inherit));
  height: var(--bitmark-learning-event-checkbox-size-height, var(--bitmark-checkbox-size-height, inherit));
  padding: var(--bitmark-learning-event-checkbox-size-padding, var(--bitmark-checkbox-size-padding, 0));
  margin: var(--bitmark-learning-event-checkbox-size-margin, var(--bitmark-checkbox-size-margin, 0));
  border-top: var(--bitmark-learning-event-checkbox-border-top-width, var(--bitmark-checkbox-border-top-width)) var(--bitmark-learning-event-checkbox-border-style, var(--bitmark-checkbox-border-style)) var(--bitmark-learning-event-checkbox-border-color, var(--bitmark-checkbox-border-color));
  border-right: var(--bitmark-learning-event-checkbox-border-right-width, var(--bitmark-checkbox-border-right-width)) var(--bitmark-learning-event-checkbox-border-style, var(--bitmark-checkbox-border-style)) var(--bitmark-learning-event-checkbox-border-color, var(--bitmark-checkbox-border-color));
  border-bottom: var(--bitmark-learning-event-checkbox-border-bottom-width, var(--bitmark-checkbox-border-bottom-width)) var(--bitmark-learning-event-checkbox-border-style, var(--bitmark-checkbox-border-style)) var(--bitmark-learning-event-checkbox-border-color, var(--bitmark-checkbox-border-color));
  border-left: var(--bitmark-learning-event-checkbox-border-left-width, var(--bitmark-checkbox-border-left-width)) var(--bitmark-learning-event-checkbox-border-style, var(--bitmark-checkbox-border-style)) var(--bitmark-learning-event-checkbox-border-color, var(--bitmark-checkbox-border-color));
  border-radius: var(--bitmark-learning-event-checkbox-border-radius, var(--bitmark-checkbox-border-radius, y));
  background-color: var(--bitmark-learning-event-checkbox-background-color, var(--bitmark-checkbox-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-learning-event-checkbox-background-image, var(--bitmark-checkbox-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-learning-event-checkbox-background-size, var(--bitmark-checkbox-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-learning-event-checkbox-background-position, var(--bitmark-checkbox-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-learning-event-checkbox-background-repeat, var(--bitmark-checkbox-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-learning-event-checkbox-background-transform, var(--bitmark-checkbox-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-learning-event-checkbox-background-animation-name) var(--bitmark-learning-event-checkbox-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-learning-event-checkbox-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-learning-event-checkbox-background-backdrop-filter);
}
.bit-checkbox label::after {
  position: absolute;
  content: "";
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  color: var(--bitmark-learning-event-checkbox-checked-color, var(--bitmark-checkbox-checked-color));
  width: var(--bitmark-learning-event-checkbox-size-width, var(--bitmark-checkbox-size-width, inherit));
  height: var(--bitmark-learning-event-checkbox-size-height, var(--bitmark-checkbox-size-height, inherit));
  padding: var(--bitmark-learning-event-checkbox-size-padding, var(--bitmark-checkbox-size-padding, 0));
  margin: var(--bitmark-learning-event-checkbox-size-margin, var(--bitmark-checkbox-size-margin, 0));
  border-top: var(--bitmark-learning-event-checkbox-checked-border-top-width, var(--bitmark-checkbox-checked-border-top-width)) var(--bitmark-learning-event-checkbox-checked-border-style, var(--bitmark-checkbox-checked-border-style)) var(--bitmark-learning-event-checkbox-checked-border-color, var(--bitmark-checkbox-checked-border-color));
  border-right: var(--bitmark-learning-event-checkbox-checked-border-right-width, var(--bitmark-checkbox-checked-border-right-width)) var(--bitmark-learning-event-checkbox-checked-border-style, var(--bitmark-checkbox-checked-border-style)) var(--bitmark-learning-event-checkbox-checked-border-color, var(--bitmark-checkbox-checked-border-color));
  border-bottom: var(--bitmark-learning-event-checkbox-checked-border-bottom-width, var(--bitmark-checkbox-checked-border-bottom-width)) var(--bitmark-learning-event-checkbox-checked-border-style, var(--bitmark-checkbox-checked-border-style)) var(--bitmark-learning-event-checkbox-checked-border-color, var(--bitmark-checkbox-checked-border-color));
  border-left: var(--bitmark-learning-event-checkbox-checked-border-left-width, var(--bitmark-checkbox-checked-border-left-width)) var(--bitmark-learning-event-checkbox-checked-border-style, var(--bitmark-checkbox-checked-border-style)) var(--bitmark-learning-event-checkbox-checked-border-color, var(--bitmark-checkbox-checked-border-color));
  border-radius: var(--bitmark-learning-event-checkbox-checked-border-radius, var(--bitmark-checkbox-checked-border-radius, y));
  background-color: var(--bitmark-learning-event-checkbox-checked-background-color, var(--bitmark-checkbox-checked-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-learning-event-checkbox-checked-background-image, var(--bitmark-checkbox-checked-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-learning-event-checkbox-checked-background-size, var(--bitmark-checkbox-checked-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-learning-event-checkbox-checked-background-position, var(--bitmark-checkbox-checked-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-learning-event-checkbox-checked-background-repeat, var(--bitmark-checkbox-checked-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-learning-event-checkbox-checked-background-transform, var(--bitmark-checkbox-checked-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-learning-event-checkbox-checked-background-animation-name) var(--bitmark-learning-event-checkbox-checked-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-learning-event-checkbox-checked-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-learning-event-checkbox-checked-background-backdrop-filter);
  font-family: var(--bitmark-learning-event-checkbox-checked-content-font-family, var(--bitmark-checkbox-checked-content-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-learning-event-checkbox-checked-content-font-size, var(--bitmark-checkbox-checked-content-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-learning-event-checkbox-checked-content-font-weight, var(--bitmark-checkbox-checked-content-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-learning-event-checkbox-checked-content-font-style, var(--bitmark-checkbox-checked-content-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-learning-event-checkbox-checked-content-font-text-decoration, var(--bitmark-checkbox-checked-content-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-learning-event-checkbox-checked-content-font-text-decoration, var(--bitmark-checkbox-checked-content-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-learning-event-checkbox-checked-content-font-line-height, var(--bitmark-checkbox-checked-content-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-learning-event-checkbox-checked-content-font-color, var(--bitmark-checkbox-checked-content-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-learning-event-checkbox-checked-content-font-align, var(--bitmark-checkbox-checked-content-font-align, var(--bitmark-checkbox-checked-content-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-learning-event-checkbox-checked-content-font-align, var(--bitmark-checkbox-checked-content-font-align, var(--bitmark-checkbox-checked-content-font-align, var(--bitmark-main-font-alignt, left))));
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
