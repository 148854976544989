import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {MatchMatrixBit, MatchMatrixCell, MatchMatrixRow} from './match-matrix.models';
import {BitResource} from '../bits.models';
import BitUtilsService from '../../shared/utils/bit-utils.service';
import {BitEvalResult} from '../bit-eval.service';
import {BitmarkTextContentService} from '../../shared';
import {BitmarkFormat} from '../../shared/models/bitmark.models';

@Component({
  selector: 'bitmark-match-matrix',
  templateUrl: './match-matrix.component.html',
  styleUrls: ['./match-matrix.component.scss', '../bits.scss']
})
export class MatchMatrixComponent implements OnInit, OnChanges {
  @Input() bit?: MatchMatrixBit;
  @Input() readOnly = false;
  @Input() hasFeedback = false;
  @Output() openResource = new EventEmitter<BitResource>();
  @Output() changed = new EventEmitter<any>();

  answersCss = {};
  columnsFillerArray: Array<any>;
  columnsGridTemplateStyle: string;
  rowInputSize: number;

  constructor(private bitmarkTextContentService: BitmarkTextContentService) {
  }

  ngOnInit() {
    let columnsCount = 0;
    this.bit?.matrix?.forEach(row => {
      if (row?.cells?.length > columnsCount) {
        columnsCount = row.cells.length;
      }
    });

    if (columnsCount) {
      this.columnsFillerArray = new Array<any>(columnsCount);
      this.columnsGridTemplateStyle = '1fr';
      const gridColumnPercentage = Math.floor(100 / (columnsCount + 1));
      for (let i = 0; i < columnsCount; i++) {
        this.columnsGridTemplateStyle += ` fit-content(${gridColumnPercentage}%)`;
      }
    }

    this.computeRowSize();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['hasFeedback']) {
      this.bit.matrix?.forEach((r: MatchMatrixRow, ridx: number) => {
        r.cells?.forEach((c: MatchMatrixCell, cidx: number) => {
          const isAnswerCorrect = c.values
            .map(x => BitUtilsService.convertBitSolutionAnswer(x, c.isCaseSensitive))
            .includes(BitUtilsService.convertBitSolutionAnswer(c.answer?.text, c.isCaseSensitive));
          this.answersCss[ridx * 10 + cidx] = c.isExample || (isAnswerCorrect && this.hasFeedback)
            ? 'correct'
            : this.hasFeedback
              ? 'incorrect'
              : '';
        });
      });
    }
  }

  change(cell: MatchMatrixCell, evt: Event) {
    cell.answer = {text: (evt.target as any)?.value};
    this.computeRowSize();
    this.changed.emit();
  }

  private computeRowSize() {
    let newRowInputSize = 0;
    this.bit?.matrix?.forEach(row => {
      row?.cells?.forEach(cell => {
        const cellInputLength = cell.isExample
          ? (this.bitmarkTextContentService.getTextFromJson(cell.example, BitmarkFormat.MM)?.length || cell.values[0]?.length)
          : cell?.answer?.text?.length;
        if (cellInputLength > newRowInputSize) {
          newRowInputSize = cellInputLength;
        }
      });
    });

    if (!newRowInputSize) {
      this.rowInputSize = 0;
    } else {
      this.rowInputSize = newRowInputSize > 2 ? newRowInputSize : 2;
    }
  }

  static evalAnswer(bit: MatchMatrixBit): BitEvalResult {
    const ret = {
      totalAnswers: 0,
      answeredAnswers: 0,
      notAnsweredAnswers: 0,
      correctAnswers: 0,
      incorrectAnswers: 0
    } as BitEvalResult;

    bit.matrix.forEach((row: MatchMatrixRow) => {
      ret.totalAnswers += row.cells.length;
      ret.answeredAnswers += row.cells.filter((c: MatchMatrixCell) => !!c.answer?.text).length;
      ret.notAnsweredAnswers += row.cells.filter((c: MatchMatrixCell) => !c.answer?.text).length;
      ret.correctAnswers += row.cells.filter(
        (c: MatchMatrixCell) => !!c.answer?.text && c.values
          .map(x => BitUtilsService.convertBitSolutionAnswer(x, c.isCaseSensitive))
          .includes(BitUtilsService.convertBitSolutionAnswer(c.answer.text, c.isCaseSensitive)))
        .length;
      ret.incorrectAnswers += row.cells.filter(
        (c: MatchMatrixCell) => (!!c.answer?.text && !c.values
              .map(x => BitUtilsService.convertBitSolutionAnswer(x, c.isCaseSensitive))
              .includes(BitUtilsService.convertBitSolutionAnswer(c.answer.text, c.isCaseSensitive))
          )
          || !c.answer?.text).length;
    });

    return ret;
  }
}
