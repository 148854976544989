export const translation = {
  'ToC': {
    'cover': 'Avvio',
    'final': 'Fine'
  },
  'Auth': {
    'LogOut': 'Disconnettersi',
    'Login': 'Accesso',
    'Register': 'Registrazione',
    'Settings': 'Impostazioni',
    'AlreadyHaveAnAccount': 'Avete già un conto?',
    'Email': 'E-mail',
    'Password': 'Password',
    'ShowPassword': 'Mostra password',
    'ResetPassword': 'Reimpostare la password',
    'HidePassword': 'Nascondere la password',
    'LostPassword': 'Password dimenticata',
    'SignInWith': 'Accedere con {{socialMedia}}',
    'GoToLogin': 'Effettuare nuovamente il login...',
    'UseAnotherAccount': 'Utilizzare un altro account...'
  },
  'Meta': {
    'ContentNotAvailable': 'Il contenuto non è disponibile ora',
    'NewVersionAvailable': 'Nuova versione disponibile',
    'PleaseUpgrade': 'Aggiornare alla versione {{newVersion}}'
  },
  'Menu': {
    'Notebooks': 'Workspace',
    'Books': 'My Library',
    'BookShop': 'Academy'
  },
  'Contact': {
    'ContactUs': 'Contatto',
    'Support': 'Supporto',
    'UpgradeAndLogout': 'Aggiornare e rifare il login',
    'ClearCache': 'Cancella la cache'
  },
  'Search': {
    'ShowOnlyFreeTitles': 'Mostra solo libri gratuiti',
    'SearchAllBooks': 'Cerca tutti i libri',
    'SearchBooks': 'Cerca libri',
    'ContentForAllBooks': 'Contenuto di tutti i libri',
    'SearchAllBits': 'Cerca tutti i bit',
    'SearchNotebook': 'Sfogliare Notebook',
    'SearchBook': 'Cerca libro',
    'SearchChapter': 'Cerca capitolo',
    'BooksFor': 'Libri per «{{searchedText}}»',
    'ResultsByPublisher': 'Mostra i risultati per Editore «{{searchedText}}»',
    'ResultsByAuthor': 'Mostra i risultati per l\'autore «{{searchedText}}»',
    'BitsFor': 'Bit per «{{searchedText}}»',
    'PaginationOutOf': '{{begin}} - {{end}} da {{total}}',
    'Previous': 'Precedente',
    'Next': 'Avanti',
    'LastSearched': 'Ultima ricerca',
    'LastViewed': 'Ultimo visto',
    'NoBooksFound': 'Nessun libro trovato',
    'NoBitsFound': 'Nessun Bit trovato',
    'BooksNotFound': 'Libri non trovati',
    'BitsNotFound': 'Bits non trovati'
  },
  'Profile': {
    'Title': 'Profilo',
    'FirstName': 'Nome',
    'LastName': 'Cognome',
    'Country': 'Paese',
    'Language': 'Lingua',
    'PrivateEmail': 'Email privata',
    'MobilePhone': 'Numero di cellulare',
    'DoubleChecking': 'Volevo solo assicurarmi che fossi tu!',
    'ChangePassword': 'Modifica della password',
    'ResetPassword': 'Reimpostare la password',
    'YourOldPassword': 'Vecchia password',
    'NewPassword': 'Nuova password',
    'ChangePasswordSuccess': 'La password è stata modificata con successo!',
    'ChangePasswordFail': 'Il tentativo di modificare la password non è andato a buon fine!',
    'AddPhoto': 'Aggiungi foto',
    'RemovePhoto': 'Rimuovi foto',
    'SavePhoto': 'Salva foto',
    'ChoosePhoto': 'Selezionare una foto o trascinarla.',
    'UpdatePhotoFail': 'Il tentativo di aggiornare le foto non è andato a buon fine.',
    'EmailEmptyError': 'L\'e-mail non deve essere vuota!',
    'PasswordEmptyError': 'La nuova password non deve essere vuota!',
    'ImageTooLarge': 'L\'immagine è troppo grande. Deve essere più piccolo di 3MB',
    'ValidationMessage': 'È stata inviata un\'e-mail di conferma al nuovo indirizzo.',
    'YourEmailAddress': 'Il vostro indirizzo e-mail',
    'HasBeenVerified': 'è stato verificato con successo.',
    'ResendVerificationMailTo': 'Inviare nuovamente la mail di verifica a',
    'EmailNotVerified': 'La tua email non è stata verificata'
  },
  'Timeline': {
    'Available': 'Disponibile',
    'NotAvailable': 'Non disponibile',
    'Reply': 'Risposta',
    'Today': 'Oggi',
    'ConnectTo': 'Collegati con ...',
    'EmailPeer': 'Emails (di account Get More Brain esistente)',
    'UserNotFound': 'Utente non trovato!!',
    'NewMessages': 'Nuovo messaggio',
    'NewMessage': 'Nuovo messaggio',
    'TypeAMessage': 'Inserire il messaggio...',
    'ConnectMessage': 'Ciao, piacere di conoscerti.',
    'MarkAllAsRead': 'Segnare tutti i messaggi come letti',
    'DeleteChat': 'Cancellare la chat',
    'DeleteChatConfirm': 'Vuoi davvero cancellare questa chat?',
    'BlockUser': 'Bloccare l\'utente',
    'BlockUserConfirm': 'Volete davvero bloccare questo utente?',
    'UnblockUser': 'Sbloccare l\'utente',
    'UnblockUserConfirm': 'Volete davvero sbloccare questo utente?',
    'ConnectionRequest': 'Richiesta di connessione',
    'CreateEntry': {
      'AddMultiQuizContinue': 'Aggiungi Quiz multipli...',
      'AddBlankQuiz': 'Aggiungi quiz vuoto',
      'OpenLinkInBrowser': 'Aprire il link nel browser',
      'CopyLink': 'Copiare il link',
      'AddHintContinue': 'Aggiungere una nota...',
      'EditLinkContinue': 'Modifica il link...',
      'EditHintContinue': 'Modifica nota...',
      'RemoveHint': 'Rimuovere il suggerimento',
      'Unlink': 'Unlink',
      'Video': 'Video',
      'TakePicture': 'Scattare foto o libreria fotografica',
      'PhotoLibrary': 'Libreria fotografica',
      'Audio': 'Audio',
      'Document': 'Documento',
      'Emoji': 'Emoji'
    },
    'Actions': {
      'YouDeletedThisMessage': 'Hai cancellato questo messaggio',
      'ThisMessageWasDeleted': 'Questo messaggio è stato cancellato',
      'ThisMessageIsHidden': 'Questo messaggio è nascosto',
      'HideSolution': 'Nascondere la soluzione',
      'RevealSolution': 'Mostra la soluzione',
      'Verify': 'Valutare',
      'Repeat': 'Ripetere',
      'TextContinue': 'Testo...',
      'HrefContinue': 'Href...',
      'HintContinue': 'Nota...',
      'PasteVideoLink': 'Inserire il link del video qui sotto',
      'BrowserDoesNotSupportAudio': 'Il vostro browser non supporta l\'elemento audio.',
      'BrowserDoesNotSupportVideo': 'Il vostro browser non supporta l\'elemento video.',
      'ChooseDocument': 'Selezionare un documento o trascinarlo.',
      'ChooseAudio': 'Selezionare un audio o trascinarlo.',
      'ErrorConnecting': 'Errore durante la connessione a {{email}}',
      'UserNotFound': 'Utente {{email}} non è stato trovato',
      'PasteAndSendBit': 'Incolla e invia Bit',
      'SendBasket': 'Invia carrello',
      'ErrorUploadingPdf': 'Non siamo riusciti a convertire il vostro PDF. Il problema è stato registrato. Stiamo lavorando a una soluzione per convertire PDF più complessi in futuro.'
    },
    'Quiz': {
      'CannotSend': 'Non è possibile inviare il quiz ora.',
      'CannotSendWithoutGap': 'Non è possibile inviare un quiz senza una lacuna.'
    },
    'SearchAll': 'Tutti gli utenti'
  },
  'Notebooks': {
    'UntitledNotebook': 'Notebook senza titolo',
    'UntitledDraftBook': 'Libro senza titolo',
    'UntitledFlashcards': 'Flashcards senza titolo',
    'Title': 'Workspace',
    'PersonalNotebooks': 'Notebooks personali',
    'Homework': 'Compiti a casa',
    'SmartNotebooks': 'Smart Notebooks',
    'DeleteConfirm': 'Volete davvero cancellare il vostro notebook «{{notebook}}»? Questo non può essere invertito!',
    'Notebook': 'it-Notebook',
    'Worksheet': 'it-Worksheet',
    'Flashcards': 'it-Flashcard',
    'RecentQuizzes': 'Ultimi quiz',
    'RecentVideos': 'Ultimi video',
    'LearningPath': 'Apprendimento',
    'DraftBook': 'Progetto di libro',
    'DropFileHere': 'Rilascia il file qui',
    'DropFileAllowedTypes': 'Puoi rilasciare solo file PDF o bitmark'
  },
  'HandIn': {
    'Archive': 'Archivia',
    'Unarchive': 'Disarchivia',
    'Assign': 'Assegnare...',
    'Unassign': 'Rimuovi assegnazione',
    'AssignToMe': 'Assegnare a me',
    'Locked': 'Bloccato',
    'Today': 'oggi',
    'Yesterday': 'ieri',
    'YourHandInApproved': 'La consegna è stata approvata',
    'YourHandInRejected': 'Il tuo messaggio è stato rifiutato',
    'PleaseReadComments': 'Leggere attentamente i commenti e riprovare.',
    'BookWasHandedIn': 'Il libro è stato consegnato',
    'By': 'da',
    'YouHaveApprovedThis': 'Hai approvato questo',
    'For': 'per',
    'YouHaveRejectedThis': 'Hai rifiutato questo',
    'PleaseHandInTheBookReady': 'Consegnare il libro quando è pronto.',
    'PleaseHandInTheBookUntil': 'Consegnare il libro fino a',
    'YourBookWillBeLocked': 'Il libro sarà bloccato 🔒 (solo per la visualizzazione) dopo la consegna.',
    'BookWasHandedInSuccessfully': 'Il libro è stato consegnato con successo.',
    'YourWorkOnThisBookWasApproved': 'Il tuo lavoro su questo libro è stato approvato!',
    'ThereWasAProblemHandingIn': 'Si è verificato un problema nella consegna del libro',
    'ThereWasAnErrorAccepting': 'Si è verificato un errore nell\'accettazione della consegna',
    'ThereWasAnErrorAssigning': 'Si è verificato un errore nell\'assegnazione della consegna',
    'ErrorNoExperts': 'Non ci sono esperti da assegnare',
    'NoNewHandIns': 'Nessun nuovo hand-in',
    'NoAssignedHandIns': 'Nessun hand-in assegnato',
    'NoHandIns': 'Nessun hand-in',
    'NewHandIns': 'Non assegnato',
    'AssignedToMe': 'Le mie revisioni',
    'All': 'Approvato'
  },
  'Books': {
    'MyBooks': 'Libri',
    'Books': 'libri',
    'Courses': 'Corsi',
    'MyClasses': 'Le mie Classi',
    'RecentlyUsed': 'Ultimo utilizzo',
    'SelfLearning': 'Apprentissage autodéterminé',
    'Purchased': 'Acquistato',
    'All': 'Tutti',
    'Unbuy': 'Unbuy',
    'UnbuyTraining': 'Cancellare la formazione',
    'ChatWithBook': 'Chattare con libro',
    'Publisher': 'Editore',
    'Authors': 'Autore',
    'Published': 'Pubblicato',
    'Updated': 'Aggiornato',
    'Language': 'Lingua',
    'ISBN': 'ISBN',
    'GetFreeBooksFromShop': 'Ottenere libri gratuiti e a pagamento dal',
    'CostsCoveredBy': 'Costi coperti da {{company}}',
    'Pages': 'Pagine',
    'AlsoAvailableIn': 'Disponibile anche in',
    'Series': 'Serie «{{seriesName}}»',
    'CurrentSeries': 'Serie attuale',
    'OnlyPaper': 'iIl libro è disponibile solo in formato cartaceo.',
    'VoteMakeBookAvailable': 'it-Vote to make it available online',
    'DigitalMaterial': 'it-But we have some nice, digital material for this book.',
    'OneVoteToMakeAvailable': 'it-{{numberOfVotes}} vote to make it available online',
    'VotesToMakeAvailable': 'it-{{numberOfVotes}} votes to make it available online',
    'Types': {
      'Flashcards': 'Flashcards',
      'PPT': 'PPT',
      'Word': 'Word',
      'Excel': 'Excel',
      'PDF': 'PDF',
      'Script': 'Scrittura',
      'Quizzes': 'Quiz',
      'Video': 'Video',
      'VideoLecture': 'Lezione video',
      'LTIContainer': 'LTI Container',
      'WBT': 'WBT',
      'Audio': 'Audio',
      'LiveBook': 'Live Book',
      'Homework': 'Compiti a casa'
    }
  },
  'Shared': {
    'BroadcastSubtitle': 'Trasmesso a tutti gli studenti',
    'Join': 'Unirsi',
    'Edit': 'Modifica',
    'Edited': 'Edited',
    'EditContinue': 'Modifica...',
    'SetContinue': 'Impostare...',
    'Remove': 'Rimuovere',
    'Cancel': 'Annullare',
    'OK': 'OK',
    'Search': 'Ricerca',
    'SearchStudents': 'Cerca Studenti',
    'Open': 'Aprire',
    'OpenContinue': 'Aprire...',
    'Download': 'Scaricare',
    'Share': 'Condividi',
    'Send': 'Inviare',
    'Show': 'Mostra',
    'ShowHiddenFields': 'Mostra Campi Nascosti...',
    'HideHiddenFields': 'Nascondi Campi Nascosti',
    'SendContinue': 'Inviare...',
    'Sending': 'All\'invio',
    'SendingContinue': 'All\'invio...',
    'Rename': 'Rinominare',
    'Replace': 'Sostituire',
    'ReplaceContinue': 'Sostituire...',
    'Delete': 'Cancellare',
    'DeleteContinue': 'Cancellare...',
    'DeletePermanentlyContinue': 'Elimina definitivamente...',
    'DeleteImmediately': 'Elimina immediatamente',
    'Save': 'Salvare',
    'Sell': 'Vendere',
    'For': 'Per',
    'Hide': 'Nascondere',
    'Free': 'Gratuito',
    'Buy': 'Acquista',
    'Unbuy': 'Annullamento dell \'acquisto\'',
    'Here': 'Qui',
    'Continue': 'Ulteriori',
    'Name': 'Nome',
    'Favorites': 'Preferiti',
    'Copy': 'Copia',
    'Paste': 'Inserire',
    'PasteSpecial': 'Inserire Special...',
    'Cut': 'Ritagliare',
    'Undo': 'Annullamento',
    'Accept': 'Accettare',
    'Reject': 'Rifiutare',
    'Revoke': 'Revocare',
    'SentOn': 'Inviato il',
    'RejectedOn': 'Rifiutato il',
    'RevokedOn': 'Revocato il',
    'UploadPDF': 'Carica PDF',
    'Duplicate': 'Duplicare',
    'Page': 'Pagina',
    'MarkAsDone': 'Segnare come fatto',
    'RateAndMarkAsDone': 'Rate and Mark as Done ✅',
    'HandIn': 'Consegnare',
    'Choose': 'Selezionare',
    'ChooseContinue': 'Selezionare...',
    'CloseConfirm': 'È sicuro di voler chiudere?',
    'CloseModalConfirm': 'È sicuro di voler chiudere la finestra?',
    'Retry': 'Riprova',
    'True': 'Corretto',
    'False': 'Non corretto',
    'Start': 'Lanciare',
    'ChooseBook': 'Seleziona il libro',
    'ChooseChapter': 'Selezionare il capitolo',
    'Book': 'Libro',
    'Chapter': 'Capitolo',
    'Video': 'Video',
    'CameraContinue': 'Fotocamera...',
    'UploadContinue': 'Carica...',
    'EmbedVideoContinue': 'Incorporare (Youtube, Vimeo)...',
    'Audio': 'Audio',
    'Voice': 'Messaggio vocale',
    'Image': 'Immagine',
    'UrlContinue': 'URL...',
    'Documents': 'Documenti',
    'LinkContinue': 'Link...',
    'Website': 'Sito web',
    'ManageContinue': 'Organize...',
    'Caption': 'Didascalia',
    'Attempts': 'Tentative',
    'Close': 'Vicino',
    'AddToCalendar': 'Aggiungi al calendario',
    'ReplaceImageContinue': 'Sostituisci Immagine...'
  },
  'Shop': {
    'Price': 'Prezzo',
    'TotalPrice': 'Prezzo totale',
    'YouAreBuyingFor': 'Stai comprando per',
    'Shop': 'Academy',
    'PurchaseSuccessful': 'Acquisto riuscito',
    'AddedSuccessfully': 'Aggiunto con successo',
    'PurchaseFailed': 'Acquisto non riuscito',
    'BookNotFound': 'Libro non trovato.',
    'AllBooksNext': 'Tutti i libri »',
    'SubCategories': 'Sottocategories',
    'MoreInfo': 'Dettagli...',
    'MoreInfoContinue': 'Più info...',
    'ThisIsAdditional': 'Si tratta di materiale aggiuntivo per',
    'SupportingMaterial': 'Materiale di supporto',
    'ThanksForJoining': 'Grazie per la partecipazione!',
    'Publish': 'Publish',
    'EditContinue': 'Edit...',
    'EditLP': 'Learning Path...',
    'InvitedBy': 'Siete stati invitati da {{user}}',
    'InvitationFor': 'Invito per',
    'GetAnotherCopy': 'Ottenere un\'altra copia',
    'Editor': {
      'CopyEmailAddress': 'Copia dell\'indirizzo e-mail',
      'Chat': 'Chat',
      'SendEmail': 'Invia e-mail...',
      'AddFlag': 'Imposta bandiera',
      'CopyAll': 'Copia tutto',
      'ReplaceAll': 'Sostituire tutti...',
      'AddSeveral': 'Aggiungi altro......',
      'ResendInvitation': 'Resend Invitation ✉️',
      'ByInvitationOnly': 'This class is by invitation only (it cannot be bought or joined)',
      'ClosedCourse': 'Corso chiuso - I partecipanti sono gestiti solo dall\'organizzatore. 🔒',
      'Content': 'Content',
      'ShopPage': 'Shop Pagina',
      'CustomShopPage': 'Personalizzata Shop Pagina',
      'NewRelease': 'Nuova versione',
      'InviteUsersToBuy': 'Invitare gli utenti ad acquistare...',
      'InviteUsersToJoin': 'Invitare gli utenti ad aggiungere...',
      'BuyForUsers': 'Invitare gli utenti ad acquistare...',
      'JoinForUsers': 'Aggiungere un utente come partecipante...',
      'BuyForUser': 'Buy For User...',
      'JoinForUser': 'Join User...',
      'DeleteAll': 'Cancellare tutti',
      'SortByEmail': 'Ordina per e-mail',
      'SortByFirstName': 'Ordina per cognome',
      'SortByLastName': 'Ordina per nome',
      'SendReleaseNotes': 'Send Release Notes'
    },
    'Course': {
      'Description': 'Description',
      'Content': 'Content',
      'UpcomingDates': 'Classi',
      'CourseContent': 'Contenuto del corso',
      'CourseLessons': 'Lezioni',
      'CourseStartDate': 'Inizio',
      'CourseBooks': 'Libri del corso',
      'TheCurriculum': 'Il curriculum',
      'BuyForAll': 'Acquisto per i partecipanti',
      'UpdateInvitations': 'Aggiornamento degli inviti',
      'Now': 'Attuale',
      'Next': 'Prossimamente',
      'Done': 'Fatto',
      'Participants': 'Partecipante',
      'Organizers': 'Organizzatori',
      'Coaches': 'Allenatori',
      'Editors': 'Editori',
      'InvitationsUpdated': 'Gli inviti sono stati aggiornati per tutti i partecipanti!',
      'BoughtForParticipants': 'Tutti i partecipanti hanno aderito al corso.',
      'CopyLink': 'Copia Link',
      'Duration': 'Durata',
      'Effort': 'Sforzo',
      'Format': 'Formato',
      'ClassSize': 'Dimensione della classe',
      'Career': 'Carriera',
      'Tuition': 'Tasse scolastiche',
      'MoveUp': 'Move up',
      'MoveDown': 'Move down',
      'HandInFolder': 'Cartella a mano per il feedback degli esperti',
      'Experts': 'Esperti',
      'Modules': 'The Modules',
      'Starts': 'Starts',
      'Ends': 'Ends',
      'ViewDetails': 'View Details'
    }
  },
  'Bits': {
    'With': 'Con',
    'SuccessfullySent': 'Invio riuscito',
    'ToUser': 'a {{userName}}',
    'Recent': 'Ultimo',
    'ModuleDone': 'Modulo completato',
    'HadRead': 'leggere',
    'SendAt': 'Inviare a',
    'Item': 'Numero',
    'Lead': 'Lead',
    'Location': 'Posizione',
    'Instruction': 'Istruzioni',
    'Action': 'Attività',
    'Duration': 'Durata',
    'From': 'Dalle',
    'To': 'Alle',
    'Quiz': 'Quiz',
    'PlaceholderBody': '...',
    'NoResourceSelected': 'Nessuna risorsa selezionata',
    'GetFeedback': 'Soluzione',
    'DoItAgain': 'Fallo di nuovo',
    'FinalFeedback': 'Feedback finale',
    'FeedbackRetryOrSubmitMessage': 'Hai ottenuto {{correct}} risposte corrette su {{total}}.',
    'Generate': 'Creare'
  },
  'Reader': {
    'ShowAll': 'Mostra tutti',
    'CreateNewNotebook': 'Creare un nuovo «{{untitledNotebook}}»',
    'Search': {
      'Search': 'Ricerca...',
      'LastSearched': 'Ultima ricerca',
      'NoResultsFound': 'Nessun risultato trovato.'
    },
    'Editor': {
      'NewChapter': 'Nuovo Capitolo',
      'Title': 'Titolo',
      'Subtitle': 'Sottotitolo',
      'Text': 'Testo',
      'Code': 'Codice',
      'Math': 'Matematica',
      'Link': 'Collegamento',
      'Quote': 'Citazione',
      'ScientificQuote': 'Citazione scientifica',
      'HistoricQuote': 'Citazione storica',
      'List': 'Elenco',
      'NumberList': 'Elenco numerata',
      'LetterList': 'Elenco lettere',
      'RomanList': 'Elenco romano',
      'TaskList': 'Elenco dei compiti',
      'NoBullets': 'Nessun proiettile',
      'AddNote': 'Aggiungere una nota',
      'AddHandwritten': 'Aggiungi "scribble"',
      'AddQuickNote': 'Aggiungere una breve nota',
      'TypeSomething': 'Inserisci qualcosa...',
      'InvalidBitmark': 'bitmark non valido',
      'ImageLeftAligned': 'Allineato a sinistra',
      'ImageRightAligned': 'Allineato a destra',
      'ImageCenterAligned': 'Centrato'
    },
    'Handwritten': {
      'WaitingForImage': 'In attesa di immagine',
      'ErrorLoadingImage': 'Errore durante il caricamento dell\'immagine',
      'LoadingImage': 'Immagine in caricamento',
      'PreparingImage': 'Preparazione dell\'immagine',
      'ColorPalette': 'Palette dei colori',
      'ExtraSmall': 'Piccolissimo',
      'Small': 'Piccolo',
      'MediumSmall': 'Medio piccolo',
      'Medium': 'Medio',
      'MediumLarge': 'Medio grande',
      'Large': 'Grande',
      'ExtraLarge': 'Extra grande',
    },
    'Filters': {
      'Quiz': 'Contenuto',
      'Resources': 'Risorse',
      'Grade': 'Nota',
      'Complexity': 'Complessità',
      'Tags': 'Tags',
      'Skills': 'Skills'
    },
    'Actions': {
      'Send': 'Inviare',
      'SendContinue': 'Inviare...',
      'SendToClass': 'Trasmesso a tutti gli studenti...',
      'Broadcast': 'Trasmesso',
      'SendBook': 'Invia il libro',
      'SendAll': 'Invia tutti',
      'DeleteBit': 'Cancella il bit',
      'DeleteAll': 'Cancellare tutti',
      'SaveToNotebook': 'Salvare nel Notebook...',
      'SaveToAnotherNotebook': 'Salvare in un altro Notebook...',
      'SaveAllToNotebook': 'Salvare tutto nel Notebook',
      'ResetBookRating': 'Reset Book Rating',
      'FindInBook': 'Mostra nel libro',
      'SuccessfullyStored': 'Salvato con successo!',
      'SavingTo': 'Salva in «{{notebookName}}»',
      'SuccessfullyStoredToNotebook': 'Salvati con successo nel Notebook «{{notebookName}}»',
      'ErrorOcurredStoring': 'Si è verificato un errore durante il salvataggio in «{{notebookName}}»',
      'CopyBitmarkType': 'Copia del bitmark {{bitmarkType}}',
      'EditBitmarkType': 'Modifica del bitmark {{bitmarkType}}',
      'BrandingPublisherTheme': 'Editore {{publisherName}} {{newline}} {{publisherId}}/{{theme}}',
      'ExtractText': 'Copiare il testo',
      'CopyBitmarkJSON': 'Copia del bitmark JSON',
      'CopyLinkToBit': 'Copiare il link a questo bit',
      'CopyAllBitsToBasket': 'Copiare tutti i bit nel "Basket".',
      'ResetAnswer': 'Reimposta la risposta',
      'ResetHighlights': 'Rimuovere l’evidenziazione',
      'JoinCall': 'Unisciti alla chiamata',
      'Add': 'Aggiungi',
      'AddChapter': 'Aggiungi capitolo',
      'AddCard': 'Aggiungi carta',
      'UploadCards': 'Carica carte...',
      'PasteQuizletCards': 'Incolla Flashcard da Quizlet (TSV)',
      'PasteStepsScribePro': 'Incolla passaggi da Scribe Pro (Markdown)',
      'AddBit': 'Aggiungi bit',
      'AddLearningPathLTI': 'Aggiungere una fase LTI',
      'AddLearningPath': 'Aggiungere una fase',
      'EditLearningPathContinue': 'Modifica del percorso di apprendimento...',
      'LearningPathGoal': 'obiettivi di apprendimento',
      'LearningPathReadBook': 'leggi il libro',
      'LearningPathStep': 'passo di apprendimento',
      'LearningPathVideoCall': 'videochiamata',
      'LearningPathExternalLink': 'link esterno',
      'LearningPathLTI': 'Risorsa LTI',
      'LearningPathClassroomEvent': 'evento in classe',
      'LearningPathClosing': 'completamento',
      'AddBotMessage': 'aggiungere un messaggio bot',
      'AddBotAction': 'aggiungi l\'azione del bot',
      'BotActionSend': 'inviare il messaggio',
      'AddNote': 'Aggiungi nota',
      'AddHandwritten': 'Aggiungi "scribble"',
      'AddBookmark': 'Aggiungi segnalibro',
      'AddFavorite': 'Aggiungi preferito',
      'AddArticle': 'Aggiungere un blocco di testo',
      'AddQuote': 'Add Quote',
      'ExampleNotes': 'Example Notes',
      'InsertBasket': 'Inserire il Basket',
      'SetCover': 'Carica Copertina Personalizzata...',
      'RemoveCover': 'Rimuovere Copertina Personalizzata',
      'CoverStyle': 'Style della Copertina',
      'DefaultCover': 'Immagine in copertina',
      'FullCover': 'L\'immagine riempie la copertina',
      'CustomCover': 'Copertina Personalizzata',
      'ErrorSetCover': 'Questa immagine è troppo piccola. Fornisci un\'immagine larga almeno 700 px o alta 1000 px.',
      'AddAuthorNote': 'Aggiungi nota dell\'autore',
      'AddReviewerNote': 'Aggiungi nota del revisore',
      'AddInfo': 'Aggiungi informazioni',
      'AddSideNote': 'Aggiungi nota a margine',
      'AddRemark': 'Aggiungi commento',
      'AddExample': 'Aggiungi esempio'
    },
    'Basket': {
      'Basket': 'Basket',
      'EmptyBasket': 'Svuotare il Basket',
      'AddToBasket': 'Posizionare nel Basket',
      'RemoveFromBasket': 'Rimuovere dal Basket',
      'SendAll': 'Inviare tutti',
      'SaveAll': 'Salva tutto'
    },
    'Bin': {
      'RestoreAll': 'Rimetti tutto a posto',
      'EmptyBin': 'Cestino vuoto',
      'Restore': 'Rimettere a posto',
      'MoveToBin': 'Sposta nel cestino',
      'DeleteBitConfirmation': 'Sei sicuro di voler eliminare questo bit?'
    },
    'Bits': {
      'Level': 'Livello'
    },
    'Translate': {
      'Translate': 'Tradurre',
      'TranslateTitle': 'Tradurre...',
      'ChooseLanguage': 'Scegli la lingua',
      'SearchLanguages': 'Cerca lingue',
      'RecentLanguages': 'Lingue recenti',
      'AllLanguages': 'Tutte le lingue'
    },
    'LearningEvents': {
      'ThisWeek': 'Questa settimana',
      'Next30Days': 'Prossimi 30 giorni',
      'AllUpcoming': 'Tutti i prossimi',
      'Past': 'Passato'
    }
  },
  'System': {
    'SystemTitle': 'System',
    'Bin': 'Cestino'
  },
  'Cockpit': {
    'AverageCompletedActivities': 'Attività completate medie',
    'AverageCorrectAnswers': 'Risposte corrette medie',
    'NoDataYet': 'Nessun dato ancora'
  }
};
