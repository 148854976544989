import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ImageBit} from './image.models';
import {BitApiWrapper, BitResource, BitResourceImage} from '../bits.models';
import {BrandingRenderService} from '../../reader/branding-render.service';
import {SubSink} from '../../shared';
import {BitbookMqService} from '../../reader/bitbook-mq.service';
import {ResourceComponent} from '../resource/resource.component';

@Component({
  selector: 'bitmark-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss', '../bits.scss']
})
export class ImageComponent implements OnInit, OnDestroy {
  @Input() bit?: ImageBit;
  @Input() bitWrapper?: BitApiWrapper;
  @Input() canBeEdited = false;
  @Output() openResource = new EventEmitter<BitResource>();
  @Output() saveInlineBit = new EventEmitter<ImageBit>();

  @ViewChild('resourceComponent') resourceComponent: ResourceComponent;

  defaultImageResource: BitResourceImage = {
    src: '/assets/images/resources/image-placeholder.png',
    alt: 'Default Image Placeholder'
  };
  defaultResource: BitResource;

  private sub = new SubSink();

  constructor(private brandingRenderService: BrandingRenderService,
              private bitbookMqService: BitbookMqService) {
  }

  ngOnInit() {
    this.sub.sink = this.bitbookMqService.onBitReplaceImage(this.bitWrapper?.id).subscribe(() => {
      this.resourceComponent.fileImageInput.nativeElement.click();
    });

    if (!this.bit?.resource?.image?.src) {
      if (this.bit?.imagePlaceholder) {
        this.defaultImageResource.src = this.bit.imagePlaceholder;

        this.defaultResource = {
          image: this.defaultImageResource,
          ...this.bit.resource
        };
      } else {
        this.sub.sink = this.brandingRenderService.getBitThemeBranding(this.bitWrapper).subscribe(data => {
          if (!data?.themeBranding && !data?.themeId) {
            return;
          }

          const defaultBrandingImage = data.themeBranding?.[this.bit.type]?.placeholderImageSrc;

          if (defaultBrandingImage) {
            this.defaultImageResource.src = defaultBrandingImage;
          }

          this.defaultResource = {
            image: this.defaultImageResource,
            ...this.bit.resource
          };
        });
      }
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  updateBitResource(data: { resource: BitResource | undefined, instruction: string, body: string }) {
    this.bit.resource = data.resource;

    this.saveInlineBit.emit(this.bit);
  }
}
