import {Injectable} from '@angular/core';
import {BaseBit, BitType} from '../../bits/bits.models';
import {ChapterBit} from '../../bits/chapter/chapter.models';
import {BitmarkFormat} from '../models/bitmark.models';
import {BitmarkTextContentService} from '../bitmark-text-content/bitmark-text-content.service';
import {ObjectService} from './object.service';
import {Flashcard1Bit} from '../../bits/flashcard-1/flashcard-1.models';
import {AppFlashcardsBit} from '../../bits/app/app-flashcards/app-flashcards.models';
import {PageProductListBit} from '../../bits/page-product-list/page-product-list.models';
import {PageBuyButtonBit} from '../../bits/page-buy-button/page-buy-button.models';
import {PageBuyButtonPromotionBit} from '../../bits/page-buy-button-promotion/page-buy-button-promotion.models';
import {PageOpenBookBit} from '../../bits/page-open-book/page-open-book.models';
import {BookLinkBit} from '../../bits/book-link/book-link.models';
import {PageProductBit} from '../../bits/page-product/page-product.models';
import {PageProductVideoBit} from '../../bits/page-product-video/page-product-video.models';
import {PageProductVideoListBit} from '../../bits/page-product-video-list/page-product-video-list.models';
import {IFramelyEmbedBit} from '../../bits/vendor/iframely/iframely-embed/iframely-embed.models';
import {SurveyRatingBit} from '../../bits/rating/survey-rating/survey-rating.models';
import {InterviewBit} from '../../bits/interview/interview.models';

@Injectable()
export default class BitUtilsService {
  constructor(private bitmarkTextContentService: BitmarkTextContentService,
              private objectService: ObjectService) {
  }

  isBitEmpty(bit: BaseBit, bitBody: any): boolean {
    if (bit?.type === BitType.Flashcard1) {
      return (bit as Flashcard1Bit)?.cards?.length === 0;
    }
    if (bit?.type === BitType.AppFlashcards) {
      return (bit as AppFlashcardsBit)?.flashcardSet?.length === 0;
    }
    if (bit?.type === BitType.IFramelyEmbed) {
      if (!!(bit as IFramelyEmbedBit)?.vendorUrl) {
        return true; // can have body but no vendor url
      }
    }
    if (bit?.type === BitType.Interview) {
      if ((bit as InterviewBit)?.questions?.length) {
        return false; // can have questions but no body
      }
    }

    return bit?.type === BitType.Chapter
      ? this.bitmarkTextContentService.isElementEmpty((bit as ChapterBit).title, BitmarkFormat.MM)
      : this.bitmarkTextContentService.isElementEmpty(bit?.item, BitmarkFormat.MM)
      && this.bitmarkTextContentService.isElementEmpty(bit?.lead, BitmarkFormat.MM)
      && this.bitmarkTextContentService.isElementEmpty(bit?.instruction, BitmarkFormat.MM)
      && this.bitmarkTextContentService.isElementEmpty(bitBody, bit?.format)
      && !this.hasBitResource(bit)
      && !this.hasBitAnswer(bit)
      && !this.hasBitSpecialContent(bit);
  }

  hasBitAnswer(bit: BaseBit): boolean {
    return this.objectService.hasNonNullValue(bit, 'answer', ['text', 'choice', 'responses', 'hasOpened', 'hasJoined', 'isDone', 'hasBeenShown', 'image', 'audio', 'video', 'normal', 'leitner', 'level', 'hasSelected', 'index', 'position', 'usedSolutions']) ||
      this.objectService.hasNonNullValue(bit, 'ingredients', ['answer']);
  }

  hasBitResource(bit: BaseBit): boolean {
    if (!bit?.resource) {
      return false;
    }

    return !!(bit?.resource?.search
      || (bit?.resource?.preview?.image?.src || bit?.resource?.preview?.image?.src1x || bit?.resource?.preview?.title || bit?.resource?.preview?.description)
      || bit?.resource?.private
      || bit?.resource?.image?.src
      || bit?.resource?.image?.src1x
      || bit?.resource?.image?.src2x
      || bit?.resource?.image?.src3x
      || bit?.resource?.imageLink?.url
      || bit?.resource?.imageOnline?.url
      || bit?.resource?.imageLandscape?.src
      || bit?.resource?.imageLandscape?.src1x
      || bit?.resource?.imageLandscape?.src2x
      || bit?.resource?.imageLandscape?.src3x
      || bit?.resource?.imagePortrait?.src
      || bit?.resource?.imagePortrait?.src1x
      || bit?.resource?.imagePortrait?.src2x
      || bit?.resource?.imagePortrait?.src3x
      || bit?.resource?.audio?.src
      || bit?.resource?.audioLink?.url
      || bit?.resource?.video?.src
      || bit?.resource?.videoEmbed?.url
      || bit?.resource?.videoLink?.url
      || bit?.resource?.videoOnline?.url
      || bit?.resource?.article?.body
      || bit?.resource?.articleLink?.url
      || bit?.resource?.document?.url
      || bit?.resource?.websiteLink?.url
      || bit?.resource?.website?.url);
  }

  hasBitSpecialContent(bit: BaseBit): boolean {
    if (bit?.type === BitType.BookLink) {
      return !!(bit as BookLinkBit)?.book;
    }
    if (bit?.type === BitType.PageOpenBook) {
      return !!(bit as PageOpenBookBit)?.book;
    }
    if (bit?.type === BitType.PageProduct) {
      return !!(bit as PageProductBit)?.product;
    }
    if (bit?.type === BitType.PageProductList) {
      return !!(bit as PageProductListBit)?.product;
    }
    if (bit?.type === BitType.PageProductVideo) {
      return !!(bit as PageProductVideoBit)?.productVideo || !!(bit as PageProductVideoBit)?._product?.length;
    }
    if (bit?.type === BitType.PageProductVideoList) {
      return !!(bit as PageProductVideoListBit)?.productVideo?.length;
    }
    if (bit?.type === BitType.PageBuyButton) {
      return !!(bit as PageBuyButtonBit)?.content2Buy;
    }
    if (bit?.type === BitType.PageBuyButtonPromotion) {
      return !!(bit as PageBuyButtonPromotionBit)?.content2Buy;
    }
    if ([BitType.SurveyRating, BitType.SurveyRatingDisplay, BitType.SurveyRatingOnce].includes(bit?.type)) {
      const surveyBit = bit as SurveyRatingBit;
      return !!(surveyBit?.ratingLevelStart && surveyBit?.ratingLevelEnd);
    }

    return false;
  }

  static convertBitSolutionAnswer(value: string, isCaseSensitive = true): string {
    if (!value) {
      return value;
    }
    const newValue = value.normalize().trim().replace(/\s\s+/g, ' ');
    return isCaseSensitive ? newValue : newValue.toLowerCase();
  }
}
