import {LearningEventCommon, toggleAnimations} from '../learning-event.common';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LearningEventCompletionBit} from './learning-event-completion.models';
import {ReaderTipTapTapService} from '../../../reader/tiptap/reader-tiptap.service';
import {BitmarkTextContentService} from '../../../shared';
import {BitmarkFormat} from '../../../shared/models/bitmark.models';
import {ReaderLeContentService} from '../../../reader/reader-book/reader-content/reader-le-content.service';

@Component({
  selector: 'bitmark-learning-event-completion',
  templateUrl: './learning-event-completion.component.html',
  styleUrls: ['./learning-event-completion.component.scss', '../../bits.scss'],
  animations: toggleAnimations
})
export class LearningEventCompletionComponent extends LearningEventCommon implements OnInit {
  @Input() bit?: LearningEventCompletionBit;
  @Output() changed = new EventEmitter<any>();

  isLeadEmpty = false;
  isItemEmpty = false;

  constructor(readerTipTapService: ReaderTipTapTapService,
              bitmarkTextContentService: BitmarkTextContentService,
              readerLeContentService: ReaderLeContentService) {
    super(readerTipTapService, bitmarkTextContentService, readerLeContentService);
  }

  ngOnInit() {
    this.isLeadEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.lead, BitmarkFormat.MM);
    this.isItemEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.item, BitmarkFormat.MM);

    this.initExpandedBit(this.bit.id);
  }
}
