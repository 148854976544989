import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {MatchPair} from '../../match/match.models';
import {EvtUtilsService} from '../../../shared';
import BitUtilsService from '../../../shared/utils/bit-utils.service';
import {BitmarkFormat} from '../../../shared/models/bitmark.models';

@Component({
  selector: 'bitmark-match-select-placeholder',
  templateUrl: './match-select-placeholder.component.html',
  styleUrls: ['./match-select-placeholder.component.scss', '../../bits.scss']
})
export class MatchSelectPlaceholderComponent implements OnChanges, OnInit {
  @Input() pair: MatchPair;
  @Input() format?: BitmarkFormat;
  @Input() options?: Array<string>;
  @Input() readOnly?: boolean;
  @Input() hasFeedback = false;
  @Output() changed = new EventEmitter<any>();

  answerStyleWidth = '0';
  answerCssClass;
  selectPlaceholderBitActions = [];
  selectedAnswer: string = null;

  constructor(private evtUtilsService: EvtUtilsService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['hasFeedback']) {
      if (this.hasFeedback) {
        const isAnswerCorrect = this.pair.isExample
          || this.pair.values
            .map(x => BitUtilsService.convertBitSolutionAnswer(x))
            .includes(BitUtilsService.convertBitSolutionAnswer(this.pair.answer?.text));
        this.answerCssClass = {
          correct: isAnswerCorrect,
          incorrect: !isAnswerCorrect
        };
      } else {
        this.answerCssClass = {
          correct: this.pair.example
        };
      }
    }
  }

  ngOnInit() {
    this.answerStyleWidth = `${this.getMaxSolutionLength() + 5}ch`;
    this.options?.forEach(value => {
      this.selectPlaceholderBitActions.push({
        label: value,
        handler: () => this.setAnswer(value)
      });
    });
  }

  setAnswer(value: string) {
    this.selectedAnswer = value;
    this.pair.answer = {text: value};
    this.changed.emit();
  }

  private getMaxSolutionLength(): number {
    if (!this.options) {
      return 0;
    }
    return this.options.reduce((a, b) => a.length > b.length ? a : b, '')?.length || 0;
  }

  openDropdown($event) {
    if (this.readOnly) {
      this.evtUtilsService.cancelEvt($event);
      return;
    }
  }
}
