export const translation = {
  'ToC': {
    'cover': 'début',
    'final': 'fin',
  },
  'Auth': {
    'LogOut': 'Se déconnecter',
    'Login': 'Se connecter',
    'Register': 'S\'enregistrer',
    'Settings': 'Paramètres',
    'AlreadyHaveAnAccount': 'Déjà un compte?',
    'Email': 'Adresse email',
    'Password': 'Mot de passe',
    'ShowPassword': 'Afficher mot de passe',
    'ResetPassword': 'Réinitialiser le mot de passe',
    'HidePassword': 'Masquer le mot de passe',
    'LostPassword': 'Mot de passe perdu',
    'SignInWith': 'S\'identifier avec {{socialMedia}}',
    'GoToLogin': 'Se connecter à nouveau..',
    'UseAnotherAccount': 'Utilisez un autre compte...',
  },
  'Meta': {
    'ContentNotAvailable': 'Contenu non disponible pour le moment',
    'NewVersionAvailable': 'Nouvelle version disponible',
    'PleaseUpgrade': 'Merci de passer à la version {{newVersion}}',
  },
  'Menu': {
    'Notebooks': 'Workspace',
    'Books': 'My Library',
    'BookShop': 'Academy',
  },
  'Contact': {
    'ContactUs': 'Nous contacter',
    'Support': 'Support',
    'UpgradeAndLogout': 'Mettre à jour et se reconnecter',
    'ClearCache': 'Effacer le cache',
  },
  'Search': {
    'ShowOnlyFreeTitles': 'Afficher uniquement livres gratuits',
    'SearchAllBooks': 'Chercher tous les livres',
    'SearchBooks': 'Recherche de livres',
    'ContentForAllBooks': 'Contenu de tous les livres',
    'SearchAllBits': 'Chercher tous les Bits',
    'SearchNotebook': 'Chercher Notebook',
    'SearchBook': 'Chercher un livre',
    'SearchChapter': 'Chercher un chapitre',
    'BooksFor': 'Livres pour «{{searchedText}}»',
    'ResultsByPublisher': 'Résultats par éditeur «{{searchedText}}»',
    'ResultsByAuthor': 'Résultats par auteur«{{searchedText}}»',
    'BitsFor': 'Bits pour «{{searchedText}}»',
    'PaginationOutOf': '{{begin}} - {{end}} de {{total}}',
    'Previous': 'Précédent',
    'Next': 'Suivant',
    'LastSearched': 'Dernière recherche',
    'LastViewed': 'Dernière vue',
    'NoBooksFound': 'Aucun livre trouvé',
    'NoBitsFound': 'Aucun Bit trouvé',
    'BooksNotFound': 'Livres non trouvés',
    'BitsNotFound': 'Bits non trouvés',
  },
  'Profile': {
    'Title': 'Profil',
    'FirstName': 'Prénom',
    'LastName': 'Nom de famille',
    'Country': 'Pays',
    'Language': 'Langue',
    'PrivateEmail': 'Email privé',
    'MobilePhone': 'Téléphone mobile',
    'DoubleChecking': 'Juste pour vérifier que c\'est bien vous!',
    'ChangePassword': 'Modifier le mot de passe',
    'ResetPassword': 'Rétablir le mot de passe',
    'YourOldPassword': 'Ancien mot de passe',
    'NewPassword': 'Nouveau mot de passe',
    'ChangePasswordSuccess': 'Le mot de passe a été modifié avec succès!',
    'ChangePasswordFail': 'La tentative de changement de mot de passe n\'a pas réussi!',
    'AddPhoto': 'Ajouter photo',
    'RemovePhoto': 'Supprimer photo',
    'SavePhoto': 'Garder photo',
    'ChoosePhoto': 'Choisir une photo ou en glisser-déposer une.',
    'UpdatePhotoFail': 'La tentative de mise à jour de la photo n\'a pas abouti.',
    'EmailEmptyError': 'Email ne peut pas être vide !',
    'PasswordEmptyError': 'Nouveau mot de passe ne peut pas être vide!',
    'ImageTooLarge': 'L\'image est trop grande. Elle doit être inférieure à 3MB',
    'ValidationMessage': 'Un email de validation a été envoyé à votre nouvelle adresse.',
    'YourEmailAddress': 'Adresse email',
    'HasBeenVerified': 'a été vérifié avec succès.',
    'ResendVerificationMailTo': 'Renvoyer l\'email de vérification à',
    'EmailNotVerified': 'Votre email n\'a pas été vérifié'
  },
  'Timeline': {
    'Available': 'Disponible',
    'NotAvailable': 'Non disponible',
    'Reply': 'Répondre',
    'Today': 'Aujourd\'hui',
    'ConnectTo': 'Se connecter à ...',
    'EmailPeer': 'Emails (d\'es compte Get More Brain existant)',
    'UserNotFound': 'L\'utilisateur n\'a pas été trouvé!',
    'NewMessages': 'Nouveaux messages',
    'NewMessage': 'Nouveau message',
    'TypeAMessage': 'Taper un message...',
    'ConnectMessage': 'Bonjour, ravi d\'être connecté.',
    'MarkAllAsRead': 'Marquer tous les messages comme lus',
    'DeleteChat': 'Supprimer la conversation',
    'DeleteChatConfirm': 'Voulez-vous vraiment supprimer cette conversation?',
    'BlockUser': 'Bloquer l\'utilisateur',
    'BlockUserConfirm': 'Voulez-vous vraiment bloquer cet utilisateur ?',
    'UnblockUser': 'Débloquer l\'utilisateur',
    'UnblockUserConfirm': 'Voulez-vous vraiment débloquer cet utilisateur?',
    'ConnectionRequest': 'Demande de connexion',
    'CreateEntry': {
      'AddMultiQuizContinue': 'Ajouter multi quiz...',
      'AddBlankQuiz': 'Ajouter vide quiz...',
      'OpenLinkInBrowser': 'Ouvrir lien dans browser',
      'CopyLink': 'Copier lien',
      'AddHintContinue': 'Ajouter indice...',
      'EditLinkContinue': 'Modifier lien...',
      'EditHintContinue': 'Modifier indice...',
      'RemoveHint': 'Supprimer indice',
      'Unlink': 'Supprimer lien',
      'Video': 'Vidéo',
      'TakePicture': 'Caméra ou Bibliothèque Photo',
      'PhotoLibrary': 'Bibliothèque Photo',
      'Audio': 'Audio',
      'Document': 'Document',
      'Emoji': 'Emoji',
    },
    'Actions': {
      'YouDeletedThisMessage': 'Vous avez supprimé ce message',
      'ThisMessageWasDeleted': 'Le message a été supprimé',
      'ThisMessageIsHidden': 'Ce message est masqué',
      'HideSolution': 'Masquer la solution',
      'RevealSolution': 'Révéler la solution',
      'Verify': 'Vérifier',
      'Repeat': 'Répéter',
      'TextContinue': 'Texte...',
      'HrefContinue': 'Href...',
      'HintContinue': 'Truc...',
      'PasteVideoLink': 'Coller un lien vidéo ci-dessous',
      'BrowserDoesNotSupportAudio': 'Browser ne supporte pas l\'élément audio.',
      'BrowserDoesNotSupportVideo': 'Browser ne supporte pas l\'élément video.',
      'ChooseDocument': 'Choisir un document ou le faire glisser-déposer.',
      'ChooseAudio': 'Choisir un audio ou le faire glisser-déposer.',
      'ErrorConnecting': 'Erreur de connexion avec {{email}}',
      'UserNotFound': 'Utilisateur {{email}} was not found',
      'PasteAndSendBit': 'Coller et envoyer Bit',
      'SendBasket': 'Envoyer le Panier',
      'ErrorUploadingPdf': 'We could not convert your pdf. The problem was logged. We are working on a solution to convert more complex pdf\'s in the future.',
    },
    'Quiz': {
      'CannotSend': 'fr-You cannot send the quiz now.',
      'CannotSendWithoutGap': 'fr-You cannot send a quiz without any gap.',
    },
    'SearchAll': 'Tous les utilisateurs',
  },
  'Notebooks': {
    'UntitledNotebook': 'Notebook sans titre',
    'UntitledDraftBook': 'Livre sans titre',
    'UntitledFlashcards': 'Cartes sans titre',
    'Title': 'Workspace',
    'PersonalNotebooks': 'Notebooks personnels',
    'Homework': 'Devoirs',
    'SmartNotebooks': 'Smart Notebooks',
    'DeleteConfirm': 'Vraiment supprimer votre Notebook «{{notebook}}»? Cela ne peut pas être annulée!',
    'Notebook': 'Notebook',
    'Worksheet': 'Feuille de travail',
    'Flashcards': 'Cartes',
    'RecentQuizzes': 'Quiz récents',
    'RecentVideos': 'Videos récents',
    'LearningPath': 'étape',
    'DraftBook': 'Brouillon',
    'DropFileHere': 'Déposez le fichier ici',
    'DropFileAllowedTypes': 'Vous êtes uniquement autorisé à supprimer des fichiers PDF ou Bitmark'
  },
  'HandIn': {
    'Archive': 'Archiver',
    'Unarchive': 'Désarchiver',
    'Assign': 'Attribuer...',
    'Unassign': 'Supprimer l\'assignation',
    'AssignToMe': 'Attribuer à moi',
    'Locked': 'Verrouillé',
    'Today': 'aujourd\'hui',
    'Yesterday': 'hier',
    'YourHandInApproved': 'Votre dépôt a été approuvé',
    'YourHandInRejected': 'Votre dépôt a été rejeté',
    'PleaseReadComments': 'Veuillez lire attentivement les commentaires et réessayer.',
    'BookWasHandedIn': 'Le livre a été remis',
    'By': 'par',
    'YouHaveApprovedThis': 'Vous avez approuvé ce',
    'For': 'pour',
    'YouHaveRejectedThis': 'Vous avez refusé ce livre',
    'PleaseHandInTheBookReady': 'Veuillez remettre le livre lorsqu\'il est prêt.',
    'PleaseHandInTheBookUntil': 'Veuillez remettre le livre avant',
    'YourBookWillBeLocked': 'Votre livre sera verrouillé 🔒 (vue seulement) après la soumission.',
    'BookWasHandedInSuccessfully': 'Le livre a été remis avec succès.',
    'YourWorkOnThisBookWasApproved': 'Votre travail sur ce livre a été approuvé !',
    'ThereWasAProblemHandingIn': 'Il y a eu un problème lors de la remise du livre',
    'ThereWasAnErrorAccepting': 'Une erreur s\'est produite lors de l\'acceptation de la remise',
    'ThereWasAnErrorAssigning': 'Une erreur s\'est produite lors de l\'attribution de l\'ouvrage',
    'ErrorNoExperts': 'Il n\'y a pas d\'experts à assigner',
    'NoNewHandIns': 'Il n\'y a pas de nouvelles remises',
    'NoAssignedHandIns': 'Il n\'y a pas de main courante attribuée',
    'NoHandIns': 'Aucune main courante',
    'NewHandIns': 'Non attribué',
    'AssignedToMe': 'Mes révisions',
    'All': 'Approuvé'
  },
  'Books': {
    'MyBooks': 'Livres',
    'Books': 'livres',
    'Courses': 'Courses',
    'MyClasses': 'Mes Classes',
    'RecentlyUsed': 'Utilisé récemment',
    'SelfLearning': 'Aprendizaje autodeterminado',
    'Purchased': 'Acheté',
    'All': 'Tous',
    'Unbuy': 'Désachat',
    'UnbuyTraining': 'Abandonner la formation',
    'ChatWithBook': 'Discuter avec le livre',
    'Publisher': 'Editeur',
    'Authors': 'Auteurs',
    'Published': 'Publié',
    'Updated': 'Actualisé',
    'Language': 'Langue',
    'ISBN': 'ISBN',
    'GetFreeBooksFromShop': 'Obtenir des livres gratuits et payants de la',
    'CostsCoveredBy': 'Frais couverts par {{company}}',
    'Pages': 'Pages',
    'AlsoAvailableIn': 'Disponible également en',
    'Series': 'série «{{seriesName}}»',
    'CurrentSeries': 'Série actuelle',
    'OnlyPaper': 'Livre disponible uniquement sur papier.',
    'VoteMakeBookAvailable': 'Voter pour le rendre disponible en ligne',
    'DigitalMaterial': 'Mais nous avons du matériel digital chouette pour ce livre.',
    'OneVoteToMakeAvailable': '{{numberOfVotes}} vote pour le rendre disponible en ligne',
    'VotesToMakeAvailable': '{{numberOfVotes}} votes pour le rendre disponible en ligne',
    'Types': {
      'Flashcards': 'Flashcards',
      'PPT': 'PPT',
      'Word': 'Word',
      'Excel': 'Excel',
      'PDF': 'PDF',
      'Script': 'Script',
      'Quizzes': 'Quizes',
      'Video': 'Video',
      'VideoLecture': 'Video Lecture',
      'LTIContainer': 'LTI Container',
      'WBT': 'WBT',
      'Audio': 'Audio',
      'LiveBook': 'Live Book',
      'Homework': 'Devoirs',
    },
  },
  'Shared': {
    'BroadcastSubtitle': 'Diffusé à tous les étudiants',
    'Join': 'Rejoignez',
    'Edit': 'Modifier',
    'Edited': 'Edited',
    'EditContinue': 'Modifier...',
    'SetContinue': 'Définir...',
    'Remove': 'Éliminer',
    'Cancel': 'Annuler',
    'OK': 'OK',
    'Search': 'Rechercher',
    'SearchStudents': 'Rechercher des étudiants',
    'Open': 'Ouvrir',
    'OpenContinue': 'Ouvrir...',
    'Download': 'Télécharger',
    'Share': 'Partager',
    'Send': 'Envoyer',
    'Show': 'Montrer',
    'ShowHiddenFields': 'Afficher les Champs...',
    'HideHiddenFields': 'Masquer les Champs',
    'SendContinue': 'Envoyer...',
    'Sending': 'Envoi',
    'SendingContinue': 'Envoi...',
    'Rename': 'Renommer',
    'Replace': 'Remplacer',
    'ReplaceContinue': 'Remplacer...',
    'Delete': 'Supprimer',
    'DeleteContinue': 'Supprimer...',
    'DeletePermanentlyContinue': 'Supprimer définitivement...',
    'DeleteImmediately': 'Supprimer immédiatement',
    'Save': 'Enregistrer',
    'Sell': 'Vendre',
    'For': 'pour',
    'Hide': 'Cacher',
    'Free': 'Gratuit',
    'Buy': 'Acheter',
    'Unbuy': 'Désachat',
    'Here': 'Ici',
    'Continue': 'Continuer',
    'Name': 'Nom',
    'Favorites': 'Favoris',
    'Copy': 'Copie',
    'Paste': 'Coller',
    'PasteSpecial': 'Special Coller...',
    'Cut': 'Couper',
    'Undo': 'fr-Undo',
    'Accept': 'Accepter',
    'Reject': 'Refuser',
    'Revoke': 'Révoquer',
    'SentOn': 'Envoyé le',
    'RejectedOn': 'Refusé le',
    'RevokedOn': 'Révoqué le',
    'UploadPDF': 'Télécharger PDF',
    'Duplicate': 'Dupliquer',
    'Page': 'Page',
    'MarkAsDone': 'Marquer comme fait',
    'RateAndMarkAsDone': 'Rate and Mark as Done ✅',
    'HandIn': 'Remettre',
    'Choose': 'Sélectionner',
    'ChooseContinue': 'Sélectionner...',
    'CloseConfirm': 'fr-Are you sure to want to close?',
    'CloseModalConfirm': 'fr-Are you sure to want to close the modal?',
    'Retry': 'fr-Retry',
    'True': 'fr-True',
    'False': 'fr-False',
    'Start': 'Démarrer',
    'ChooseBook': 'Choisir un livre',
    'ChooseChapter': 'Choisir un chapitre',
    'Book': 'Livre',
    'Chapter': 'Chapitre',
    'Video': 'Video',
    'CameraContinue': 'Caméra...',
    'UploadContinue': 'Télécharger...',
    'EmbedVideoContinue': 'Intégrer (Youtube, Vimeo)...',
    'Audio': 'Audio',
    'Voice': 'Message vocal',
    'Image': 'Image',
    'UrlContinue': 'URL...',
    'Documents': 'Documents',
    'LinkContinue': 'Lien...',
    'Website': 'Site web',
    'ManageContinue': 'Organize...',
    'Caption': 'Légende',
    'Attempts': 'Tentatives',
    'Close': 'Fermer',
    'AddToCalendar': 'Ajouter au calendrier',
    'ReplaceImageContinue': 'Remplacer l\'image...'
  },
  'Shop': {
    'Price': 'Prix',
    'TotalPrice': 'Prix total',
    'YouAreBuyingFor': 'Vous achetez pour',
    'Shop': 'Academy',
    'PurchaseSuccessful': 'Achat réussi',
    'AddedSuccessfully': 'Ajouté avec succès',
    'PurchaseFailed': 'Achat manqué',
    'BookNotFound': 'Livre non trouvé.',
    'AllBooksNext': 'Tous les livres »',
    'SubCategories': 'subcategories',
    'MoreInfo': 'Details...',
    'MoreInfoContinue': 'Plus d\'info...',
    'ThisIsAdditional': 'This is a Supporting Material for',
    'SupportingMaterial': 'Supporting Material',
    'ThanksForJoining': 'Merci de participer !',
    'Publish': 'Publish',
    'EditContinue': 'fr-Edit...',
    'EditLP': 'Learning Path...',
    'InvitedBy': 'Vous avez été invité par {{user}}',
    'InvitationFor': 'Invitation pour',
    'GetAnotherCopy': 'Obtenir une autre copie',
    'Editor': {
      'CopyEmailAddress': 'Copie de l\'adresse e-mail',
      'Chat': 'Chat',
      'SendEmail': 'Envoyer un e-mail...',
      'AddFlag': 'Définir le drapeau',
      'CopyAll': 'Copier tout',
      'ReplaceAll': 'Remplacer tous...',
      'AddSeveral': 'Ajouter d\'autres......',
      'ResendInvitation': 'Resend Invitation ✉️',
      'ByInvitationOnly': 'This class is by invitation only (it cannot be bought or joined)',
      'ClosedCourse': 'Cours fermé - Les participants sont gérés par les organisateurs uniquement. 🔒',
      'Content': 'Content',
      'ShopPage': 'Shop Page',
      'CustomShopPage': 'Shop Page personnalisée',
      'NewRelease': 'Nouvelle version',
      'InviteUsersToBuy': 'Inviter les Users à acheter...',
      'InviteUsersToJoin': 'Invite Users to Join...',
      'BuyForUsers': 'Acheter pour Users...',
      'JoinForUsers': 'Ajouter un utilisateur comme participant...',
      'BuyForUser': 'Acheter pour User...',
      'JoinForUser': 'Join User...',
      'DeleteAll': 'Supprimer tout',
      'SortByEmail': 'Trier par e-mail',
      'SortByFirstName': 'Trier par prénom',
      'SortByLastName': 'Trier par nom de famille',
      'SendReleaseNotes': 'Send Release Notes'
    },
    'Course': {
      'Description': 'Description',
      'Content': 'Contenu',
      'UpcomingDates': 'Cours',
      'CourseContent': 'Contenu du cours',
      'CourseLessons': 'Leçons',
      'CourseStartDate': 'Start',
      'CourseBooks': 'Course Books',
      'TheCurriculum': 'Le curriculum',
      'BuyForAll': 'Acheter pour les participants',
      'UpdateInvitations': 'Mise à jour des invitations',
      'Now': 'fr-Now',
      'Next': 'fr-Next',
      'Done': 'fr-Done',
      'Participants': 'Participants',
      'Organizers': 'Organisateurs',
      'Coaches': 'Entraîneurs',
      'Editors': 'Éditeurs',
      'InvitationsUpdated': 'Les invitations ont été mises à jour pour tous les participants!',
      'BoughtForParticipants': 'Tous les participants ont rejoint le cours.',
      'CopyLink': 'Copier Lien',
      'Duration': 'Durée',
      'Effort': 'Effort',
      'Format': 'Format',
      'ClassSize': 'Taille de la classe',
      'Career': 'Carrière',
      'Tuition': 'Frais d\'études',
      'MoveUp': 'Move up',
      'MoveDown': 'Move down',
      'HandInFolder': 'Dossier main dans la main pour le feedback des experts',
      'Experts': 'Experts',
      'Modules': 'The Modules',
      'Starts': 'Starts',
      'Ends': 'Ends',
      'ViewDetails': 'View Details'
    },
  },
  'Bits': {
    'With': 'Avec',
    'SuccessfullySent': 'Envoi réussi',
    'ToUser': 'à {{userName}}',
    'Recent': 'Récent',
    'ModuleDone': 'fr-Module done',
    'HadRead': 'fr-read',
    'SendAt': 'fr-Send at',
    'Item': 'Numéro',
    'Lead': 'Lead',
    'Location': 'Emplacement',
    'Instruction': 'Instruction',
    'Action': 'Exercice',
    'Duration': 'Durée',
    'From': 'De',
    'To': 'À',
    'Quiz': 'fr-Quiz',
    'PlaceholderBody': '...',
    'NoResourceSelected': 'Pas de ressource sélectionnée',
    'GetFeedback': 'Obtenir des commentaires',
    'DoItAgain': 'Fais-le encore',
    'FinalFeedback': 'Commentaires finaux',
    'FeedbackRetryOrSubmitMessage': 'Vous avez obtenu {{correct}} réponses correctes sur {{total}}.',
    'Generate': 'Générer'
  },
  'Reader': {
    'ShowAll': 'Afficher tout',
    'CreateNewNotebook': 'Créer un nouveau «{{untitledNotebook}}»',
    'Search': {
      'Search': 'Rechercher...',
      'LastSearched': 'Dernière recherche',
      'NoResultsFound': 'Aucun résultat trouvé.',
    },
    'Editor': {
      'NewChapter': 'Chapitre Nouveau',
      'Title': 'Title',
      'Subtitle': 'Subtitle',
      'Text': 'Text',
      'Code': 'Code',
      'Math': 'Math',
      'Link': 'Link',
      'Quote': 'Quote',
      'ScientificQuote': 'Scientific Quote',
      'HistoricQuote': 'Historic Quote',
      'List': 'List',
      'NumberList': 'Numbered List',
      'LetterList': 'Letter List',
      'RomanList': 'Liste Romaine',
      'TaskList': 'Task List',
      'NoBullets': 'No Bullets',
      'AddNote': 'Add Note',
      'AddHandwritten': 'Ajouter un "scribble"',
      'AddQuickNote': 'Add Quick Note',
      'TypeSomething': 'Type Something ...',
      'InvalidBitmark': 'bitmark invalide',
      'ImageLeftAligned': 'Aligné à gauche',
      'ImageRightAligned': 'Aligné à droite',
      'ImageCenterAligned': 'Centré'
    },
    'Handwritten': {
      'WaitingForImage': 'Attente de l\'image',
      'ErrorLoadingImage': 'Erreur de chargement de l\'image',
      'LoadingImage': 'Chargement de l\'image',
      'PreparingImage': 'Préparer l\'image',
      'ColorPalette': 'Palette de couleurs',
      'ExtraSmall': 'Extra petit',
      'Small': 'Petit',
      'MediumSmall': 'Moyen petit',
      'Medium': 'Moyen',
      'MediumLarge': 'Moyen grand',
      'Large': 'Grand',
      'ExtraLarge': 'Extra grand',
    },
    'Filters': {
      'Quiz': 'Contenu',
      'Resources': 'Resources',
      'Grade': 'Grade',
      'Complexity': 'Complexité',
      'Tags': 'Tags',
      'Skills': 'Compétences',
    },
    'Actions': {
      'Send': 'Envoyer',
      'SendContinue': 'Envoyer...',
      'SendToClass': 'Diffusé à tous les étudiants...',
      'Broadcast': 'Diffusé',
      'SendBook': 'Envoyer livre',
      'SendAll': 'Envoyer tout',
      'DeleteBit': 'Supprimer Bit',
      'DeleteAll': 'Supprimer tout',
      'SaveToNotebook': 'Enregistrer dans Notebook...',
      'SaveToAnotherNotebook': 'Enregistrer dans autre Notebook...',
      'SaveAllToNotebook': 'Enregistrer tout dans Notebook',
      'ResetBookRating': 'Reset Book Rating',
      'FindInBook': 'Trouver dans livre"',
      'SuccessfullyStored': 'Enregistré avec succès !',
      'SavingTo': 'Enregistrer dans «{{notebookName}}»',
      'SuccessfullyStoredToNotebook': 'Enregistrement réussi dans le notebook «{{notebookName}}»',
      'ErrorOcurredStoring': 'Une erreur a été rencontrée lors de l\'enregistrement sur «{{notebookName}}»',
      'CopyBitmarkType': 'Copier  bitmark {{bitmarkType}}',
      'EditBitmarkType': 'Edit {{bitmarkType}} type',
      'BrandingPublisherTheme': 'Éditeur {{publisherName}} {{newline}} {{publisherId}}/{{theme}}',
      'ExtractText': 'Copy Extracted Text',
      'CopyBitmarkJSON': 'Copier bitmark JSON',
      'CopyLinkToBit': 'Copier le lien vers ce bit',
      'CopyAllBitsToBasket': 'Copier tous les éléments dans le "Basket"',
      'ResetAnswer': 'Réinitialiser la réponse',
      'ResetHighlights': 'Supprimer la surbrillance',
      'JoinCall': 'Rejoindre l\'appel',
      'Add': 'Add',
      'AddChapter': 'Add Chapter',
      'AddCard': 'Ajouter une carte',
      'UploadCards': 'Télécharger des cartes...',
      'PasteQuizletCards': 'Coller des Flashcards de Quizlet (TSV)',
      'PasteStepsScribePro': 'Coller les étapes de Scribe Pro (Markdown)',
      'AddBit': 'Add Bit',
      'AddLearningPathLTI': 'ajouter une étape LTI',
      'AddLearningPath': 'ajouter une étape',
      'EditLearningPathContinue': 'Modifier le parcours d\'apprentissage...',
      'LearningPathGoal': 'objectifs pédagogiques',
      'LearningPathReadBook': 'lisez le livre',
      'LearningPathStep': 'étape d\'apprentissage',
      'LearningPathVideoCall': 'appel vidéo',
      'LearningPathExternalLink': 'lien externe',
      'LearningPathLTI': 'Ressource LTI',
      'LearningPathClassroomEvent': 'événement en classe',
      'LearningPathClosing': 'achèvement',
      'AddBotMessage': 'ajouter le message du bot',
      'AddBotAction': 'ajouter une action de bot',
      'BotActionSend': 'envoyer un message',
      'AddNote': 'Ajouter une note',
      'AddHandwritten': 'Ajouter un "scribble"',
      'AddBookmark': 'Ajouter un signet',
      'AddFavorite': 'Ajouter un favori',
      'AddArticle': 'Ajouter un bloc de texte',
      'AddQuote': 'Add Quote',
      'ExampleNotes': 'Example Notes',
      'InsertBasket': 'Insérer le Basket',
      'SetCover': 'Télécharger une Couverture Personnalisée...',
      'RemoveCover': 'Supprimer la Couverture Personnalisée',
      'CoverStyle': 'Style de Couverture',
      'DefaultCover': 'Image sur la couverture',
      'FullCover': 'L\'image remplit la couverture',
      'CustomCover': 'Couverture Personnalisée',
      'ErrorSetCover': 'Cette image est trop petite. Veuillez fournir une image d\'au moins 700 pixels de large ou 1000 pixels de haut.',
      'AddAuthorNote': 'Ajouter une note d\'auteur',
      'AddReviewerNote': 'Ajouter une note au réviseur',
      'AddInfo': 'Ajouter des informations',
      'AddSideNote': 'Ajouter une note latérale',
      'AddRemark': 'Ajouter une remarque',
      'AddExample': 'Ajouter un exemple'
    },
    'Basket': {
      'Basket': 'Basket',
      'EmptyBasket': 'Vider Basket',
      'AddToBasket': 'Mettre dans le Basket',
      'RemoveFromBasket': 'Retirer du Basket',
      'SendAll': 'Envoyer tous',
      'SaveAll': 'Sauvez tout',
    },
    'Bin': {
      'RestoreAll': 'Tout remettre',
      'EmptyBin': 'Vider le bac',
      'Restore': 'Remettre',
      'MoveToBin': 'Déplacer vers la corbeille',
      'DeleteBitConfirmation': 'Voulez-vous vraiment supprimer ce bit?'
    },
    'Bits': {
      'Level': 'Niveau',
    },
    'Translate': {
      'Translate': 'Traduire',
      'TranslateTitle': 'Traduire...',
      'ChooseLanguage': 'Choisissez la langue',
      'SearchLanguages': 'Langues de recherche',
      'RecentLanguages': 'Langues récentes',
      'AllLanguages': 'Toutes les langues'
    },
    'LearningEvents': {
      'ThisWeek': 'Cette semaine',
      'Next30Days': 'Les 30 prochains jours',
      'AllUpcoming': 'Toutes les prochaines',
      'Past': 'Passé'
    }
  },
  'System': {
    'SystemTitle': 'System',
    'Bin': 'Bac'
  },
  'Cockpit': {
    'AverageCompletedActivities': 'Activités réalisées en moyenne',
    'AverageCorrectAnswers': 'Réponses correctes moyennes',
    'NoDataYet': 'Aucune donnée pour le moment'
  }
};
