import {Component, Input, OnInit} from '@angular/core';
import {createEvent, EventAttributes} from 'ics';
import {LearningEventVideoCallBit} from './learning-event-video-call.models';
import {LearningEventCommon, toggleAnimations} from '../learning-event.common';
import {FileUtilsService} from '../../../shared/utils/file-utils.service';
import {ReaderTipTapTapService} from '../../../reader/tiptap/reader-tiptap.service';
import {BitmarkTextContentService} from '../../../shared';
import UrlService from '../../../shared/utils/url.service';
import {BitmarkFormat} from '../../../shared/models/bitmark.models';
import {ReaderLeContentService} from '../../../reader/reader-book/reader-content/reader-le-content.service';

@Component({
  selector: 'bitmark-learning-event-video-call',
  templateUrl: './learning-event-video-call.component.html',
  styleUrls: ['./learning-event-video-call.component.scss', '../../bits.scss'],
  animations: toggleAnimations
})
export class LearningEventVideoCallComponent extends LearningEventCommon implements OnInit {
  @Input() bit?: LearningEventVideoCallBit;

  isLeadEmpty = false;
  isItemEmpty = false;
  isInPast = false;

  constructor(private fileUtilsService: FileUtilsService,
              private urlService: UrlService,
              readerLeContentService: ReaderLeContentService,
              readerTipTapService: ReaderTipTapTapService,
              bitmarkTextContentService: BitmarkTextContentService) {
    super(readerTipTapService, bitmarkTextContentService, readerLeContentService);
  }

  ngOnInit() {
    this.isLeadEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.lead, BitmarkFormat.MM);
    this.isItemEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.item, BitmarkFormat.MM);
    if (this.bit.dateEnd || this.bit.date) {
      this.isInPast = new Date(this.bit.dateEnd || this.bit.date) < new Date();
    }
    this.toggleStateIsDone = this.isInPast ? 'collapsed' : 'expanded';

    this.initExpandedBit(this.bit.id, this.isInPast);
  }

  join() {
    if (this.bit.videoCallLink) {
      window.open(this.bit.videoCallLink, '_blank');
    }
  }

  downloadAddToCalendar() {
    if (!this.bit.date) {
      return;
    }

    const startDate = new Date(this.bit.date);
    const endDate = this.bit.dateEnd ? new Date(this.bit.dateEnd) : null;

    let body = this.bitmarkTextContentService.getTextFromJson(this.bit.body, this.bit.format);

    const event: EventAttributes = {
      start: [startDate.getUTCFullYear(), startDate.getUTCMonth() + 1, startDate.getUTCDate(), startDate.getUTCHours(), startDate.getUTCMinutes()],
      startInputType: 'utc',
      startOutputType: 'local',
      end: endDate ? [endDate.getUTCFullYear(), endDate.getUTCMonth() + 1, endDate.getUTCDate(), endDate.getUTCHours(), endDate.getUTCMinutes()] : undefined,
      endInputType: 'utc',
      endOutputType: 'local',
      title: this.bit.instruction || ' ',
      description: body,
      url: this.urlService.isValid(this.bit.videoCallLink) ? this.bit.videoCallLink : undefined,
      busyStatus: 'BUSY'
    };

    createEvent(event, (error, value) => {
      if (error) {
        console.error(error);
        return;
      }
      this.fileUtilsService.download('meeting.ics', value);
    });
  }
}
