import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BrowserStorageService} from '../../../shared/browser-storage/browser-storage.service';

@Injectable({providedIn: 'root'})
export class ReaderLeContentService {
  private readonly READER_COLLAPSED_DATES_PREFIX = 'reader-le-collapsed-dates-';
  private readonly READER_EXPANDED_BITS_PREFIX = 'reader-expanded-bits-';
  private readonly READER_LAST_FILTER_PREFIX = 'reader-le-last-filter-';

  private readersCollapsedDateToggles: { [key: string]: Array<string> } = {};
  private readersExpandedBits: { [key: string]: Array<string> } = {};

  constructor(private browserStorageService: BrowserStorageService) {
  }

  loadCollapsedDateToggles(bookId: string) {
    if (!bookId) {
      return;
    }

    this.browserStorageService.idb.get(`${this.READER_COLLAPSED_DATES_PREFIX}${bookId}`).subscribe(data => {
      this.readersCollapsedDateToggles[bookId] = data || [];
    });
  }

  loadExpandedBits(bookId: string) {
    if (!bookId) {
      return;
    }

    this.browserStorageService.idb.get(`${this.READER_EXPANDED_BITS_PREFIX}${bookId}`).subscribe(data => {
      this.readersExpandedBits[bookId] = data || [];
    });
  }

  storeLastFilter(bookId: string, lastFilter: string) {
    if (!bookId) {
      return;
    }

    this.browserStorageService.idb.store(`${this.READER_LAST_FILTER_PREFIX}${bookId}`, lastFilter).subscribe();
  }

  getLastFilter(bookId: string): Observable<string> {
    if (!bookId) {
      return;
    }

    return this.browserStorageService.idb.get(`${this.READER_LAST_FILTER_PREFIX}${bookId}`);
  }

  isDateToggleCollapsed(bookId: string, date: Date): boolean {
    return this.readersCollapsedDateToggles[bookId]?.includes(date.toLocaleDateString('en-US'));
  }

  isBitExpanded(bookId: string, bitId: string): boolean {
    return this.readersExpandedBits[bookId]?.includes(bitId);
  }

  addCollapsedDateToggle(bookId: string, date: Date) {
    if (!bookId) {
      return;
    }

    if (!this.readersCollapsedDateToggles[bookId]) {
      this.readersCollapsedDateToggles[bookId] = [];
    }
    this.readersCollapsedDateToggles[bookId].push(date.toLocaleDateString('en-US'));

    this.browserStorageService.idb.store(`${this.READER_COLLAPSED_DATES_PREFIX}${bookId}`, this.readersCollapsedDateToggles[bookId]).subscribe();
  }

  removeCollapsedDateToggle(bookId: string, date: Date) {
    if (!bookId) {
      return;
    }

    if (!this.readersCollapsedDateToggles[bookId]) {
      this.readersCollapsedDateToggles[bookId] = [];
      this.browserStorageService.idb.clear(`${this.READER_COLLAPSED_DATES_PREFIX}${bookId}`);
    } else {
      this.readersCollapsedDateToggles[bookId] = this.readersCollapsedDateToggles[bookId].filter(x => x !== date.toLocaleDateString('en-US'));
    }

    this.browserStorageService.idb.store(`${this.READER_COLLAPSED_DATES_PREFIX}${bookId}`, this.readersCollapsedDateToggles[bookId]).subscribe();
  }

  addExpandedBits(bookId: string, bitId: string) {
    if (!bookId) {
      return;
    }

    if (!this.readersExpandedBits[bookId]) {
      this.readersExpandedBits[bookId] = [];
    }
    this.readersExpandedBits[bookId].push(bitId);

    this.browserStorageService.idb.store(`${this.READER_EXPANDED_BITS_PREFIX}${bookId}`, this.readersExpandedBits[bookId]).subscribe();
  }

  removeExpandedBit(bookId: string, bitId: string) {
    if (!bookId) {
      return;
    }

    if (!this.readersExpandedBits[bookId]) {
      this.readersExpandedBits[bookId] = [];
      this.browserStorageService.idb.clear(`${this.READER_EXPANDED_BITS_PREFIX}${bookId}`);
    } else {
      this.readersExpandedBits[bookId] = this.readersExpandedBits[bookId].filter(x => x !== bitId);
    }

    this.browserStorageService.idb.store(`${this.READER_EXPANDED_BITS_PREFIX}${bookId}`, this.readersExpandedBits[bookId]).subscribe();
  }
}
